import * as React from 'react'
import { Form, Input, Button, Select, Modal, message, Radio } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { acctSave, acctOne } from './../../service/account'
import { roleList } from '../../service/role'
import { compList, organList } from '../../service/compOrgan'
import { studentList } from '../../service/student'
import CompInput from './../widget/CompInput'
import OrganInput from './../widget/OrganInput'
import * as _ from 'lodash'

export type IAccountAddProps = {
  onSuccess: () => void
  accountId?: any
  buttonTitle: string
  icon: string
} & FormComponentProps

type IAccountAddStates = {
  groupId: any
  studentType: number
  roleList: any[]
  editDialogVisible: boolean
  compName: string
  organName: string
  compId: any
  organId: any
  originCompName: any
  originCompId: any
  originOrganName: any
  originOrganId: any
}

const { Option } = Select

class AccountAdd extends React.Component<IAccountAddProps, IAccountAddStates> {
  constructor(props: any) {
    super(props)
    this.handleOk = this.handleOk.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.state = {
      groupId: '',
      studentType: 1,
      roleList: [],
      editDialogVisible: false,
      compName: '',
      organName: '',
      compId: 0,
      organId: 0,
      originCompName: '',
      originCompId: '',
      originOrganName: '',
      originOrganId: '',
    }
  }

  getAccount = async () => {
    if (this.props.accountId) {
      const res = await acctOne(this.props.accountId)
      if (res.success) {
        const acc = res.data[0]
        if (acc) {
          let accountType: number = 2
          if (acc.organAccJson && acc.organAccJson.length > 0) {
            accountType = 2
            const organ = acc.organAccJson[0]
            acc.organId = organ.organId
            acc.organName = organ.organName
          }
          if (acc.compAccJson && acc.compAccJson.length > 0) {
            accountType = 3
            const comp = acc.compAccJson[0]
            acc.compId = comp.compId
            acc.compName = comp.compName
          }
          acc.accountType = accountType
          setTimeout(() => {
            this.setState({
              ...acc,
              originCompId: acc.compId,
              originCompName: acc.compName,
              originOrganName: acc.organName,
              originOrganId: acc.organId,
            })
          }, 0)
          this.props.form.setFieldsValue(
            _.pick(acc, [
              'compName',
              'organName',
              'accountType',
              'loginName',
              'linkman',
              'phone',
              'cardID',
              'email',
              'roleId',
            ])
          )
        }
      }
    }
  }

  getRoleList = async () => {
    const res = await roleList({})
    if (res.success) {
      this.setState({
        roleList: res.data,
      })
    }
  }

  searchComp = async (str: any) => {
    const params = {
      compName: str,
    }
    const res = await compList(params)
    if (res.success) {
      const result = res.data.map((item: any) => {
        return {
          text: item.compName,
          value: item.compId,
        }
      })
      return result
    }
    return []
  }
  searchOrgan = async (str: any) => {
    const params = {
      organName: str,
    }
    const res = await organList(params)
    if (res.success) {
      const result = res.data.map((item: any) => {
        return {
          text: item.organName,
          value: item.organId,
        }
      })
      return result
    }
    return []
  }
  searchStudent = async (str: any) => {
    const params = {
      accountName: str,
    }
    const res = await studentList(params)
    if (res.success) {
      const result = res.data.map((item: any) => {
        return {
          text: item.accountName,
          value: item.accountId,
        }
      })
      return result
    }
    return []
  }

  handleOk = (e: any) => {
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        if (values.accountType == 3 && !this.state.compId) {
          message.error('企业必须从搜索列表中选择')
          return
        }
        if (values.accountType == 2 && !this.state.organId) {
          message.error('机构必须从搜索列表中选择')
          return
        }
        const params = {
          ...values,
          accountId: this.props.accountId,
          organId: values.accountType == 2 ? this.state.organId : null,
          compId: values.accountType == 3 ? this.state.compId : null,
        }
        const res = await acctSave(params)
        if (res.success) {
          message.success('保存成功')
          this.setState({
            editDialogVisible: false,
          })
          if (this.props.onSuccess) {
            this.props.onSuccess()
          }
        } else {
          message.error('保存失败')
        }
      }
    })
  }

  handleCancel = (e: any) => {
    console.log(e)
    this.setState({
      editDialogVisible: false,
    })
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const form = this.props.form!
    const accountType = this.props.form.getFieldValue('accountType')
    return (
      <div>
        <Modal
          width="40%"
          title={this.props.accountId ? '编辑账户' : '添加账户'}
          visible={this.state.editDialogVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form layout="horizontal" style={{ marginBottom: 16 }}>
            <Form.Item label="账户名称">
              {getFieldDecorator('loginName', {
                rules: [{ required: true, message: '请输入账户名称!' }],
              })(<Input placeholder="账户名称" />)}
            </Form.Item>
            <Form.Item label="联系人姓名">
              {getFieldDecorator('linkman', {
                rules: [{ required: true, message: '请输入联系人姓名!' }],
              })(<Input placeholder="联系人姓名" />)}
            </Form.Item>
            <Form.Item label="角色">
              {getFieldDecorator('roleId', {
                rules: [{ required: true, message: '请选择角色!' }],
              })(
                <Select style={{ width: 120 }}>
                  {this.state.roleList.map((role: any) => {
                    return (
                      <Option key={role.roleId} value={role.roleId}>
                        {role.roleName}
                      </Option>
                    )
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="是否是机构账号">
              {getFieldDecorator('accountType', {
                rules: [{ required: true, message: '请选择账号类型!' }],
                initialValue: 2,
              })(
                <Radio.Group>
                  <Radio value={2}>机构账号</Radio>
                  <Radio value={3}>企业账号</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            {accountType == 2 && (
              <OrganInput
                form={form}
                name={this.state.originOrganName}
                id={this.state.originOrganId}
                onChange={(values: any) => {
                  this.setState({
                    ...values,
                  })
                }}
              />
            )}
            {accountType == 3 && (
              <CompInput
                form={form}
                name={this.state.originCompName}
                id={this.state.originCompId}
                onChange={(values: any) => {
                  setTimeout(() => {
                    this.setState({
                      ...values,
                    })
                  }, 0)
                }}
              />
            )}
            <Form.Item label="手机号">
              {getFieldDecorator('phone')(<Input placeholder="手机号" />)}
            </Form.Item>
            <Form.Item label="身份证号">
              {getFieldDecorator('cardID')(<Input placeholder="身份证号" />)}
            </Form.Item>
            <Form.Item label="邮箱">
              {getFieldDecorator('email')(<Input placeholder="邮箱" />)}
            </Form.Item>
          </Form>
        </Modal>
        <Button
          icon={this.props.icon}
          type="primary"
          onClick={() => {
            setTimeout(async () => {
              this.setState({
                editDialogVisible: true,
              })
              this.getRoleList()
              await this.getAccount()
            }, 0)
          }}
        >
          {this.props.buttonTitle}
        </Button>
      </div>
    )
  }
}

export default Form.create<IAccountAddProps>()(AccountAdd)

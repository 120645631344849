import * as React from 'react'
import { Form, Select } from 'antd'
import { compList } from '../../service/compOrgan'
const { Option } = Select
let timeout: any = null
export interface ICompInputProps {
  form: any
  onChange: any
  name: any
  id: any
  style?: any
  defaultData?: any
}

type ICompInputStates = {
  data: any
  value: any
}

export default class CompInput extends React.Component<ICompInputProps, ICompInputStates> {
  constructor(props: any) {
    super(props)

    if (props && props.id) {
      this.state = {
        data: [{ value: props.name, text: props.id }],
        value: props.name,
      }
    } else {
      this.state = {
        data: [],
        value: undefined,
      }
    }
  }

  componentDidMount() {
    if (this.props.name) {
      setTimeout(() => {
        this.props.form.setFieldsValue({
          compName: this.props.name,
        })
      }, 100)
    }
  }

  componentDidUpdate(props: any) {
    if (props.name && props.name != this.props.name) {
      debugger
      setTimeout(() => {
        this.props.form.setFieldsValue({
          compName: props.name,
        })
      }, 100)
    }
  }

  searchComp = async (str: any) => {
    const params = {
      compName: str,
    }
    const res = await compList(params)
    if (res.success) {
      const result = res.data.map((item: any) => {
        return {
          value: item.compName,
          text: item.compId,
        }
      })
      return result
    }
    return []
  }

  fetch = async (value: any, callback: any) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    const fake = async () => {
      const data = await this.searchComp(value)
      callback(data)
    }
    timeout = setTimeout(fake, 300)
  }

  handleSearch = (value: any) => {
    if (value) {
      this.fetch(value, (data: any) => this.setState({ data }))
    } else {
      this.setState({ data: [] })
    }
  }

  handleChange = (value: any, option: any) => {
    this.setState({ value })
    if (this.props.onChange) {
      const comp = this.state.data.find((item: any) => item.text == value)
      if (comp) {
        this.props.onChange({ compId: comp.text, compName: comp.value }, option.props.children)
      }
    }
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    return (
      <Form.Item label="企业">
        {getFieldDecorator('compName', {
          rules: [{ required: true, message: '请输入企业!' }],
          initialValue: this.props.name || '',
        })(
          <Select
            showSearch
            placeholder="请搜索企业"
            style={this.props.style}
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={false}
            onSearch={this.handleSearch}
            onChange={this.handleChange}
            notFoundContent={null}
          >
            {this.state.data &&
              this.state.data.map((d: any) => <Option key={d.text}>{d.value}</Option>)}
          </Select>
        )}
      </Form.Item>
    )
  }
}

import React from 'react'
import { Table, Form, Button, Pagination, Input, message, Popconfirm, Select } from 'antd'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import CompAdd from './CompAdd'
import { compList, compDel, organList } from '../../service/compOrgan'
import { FormComponentProps } from 'antd/lib/form'
import { PERM, hasPermits } from '../../utils/permits'
import RegionCascader from './../widget/RegionCascader'

const { Column } = Table
const { Option } = Select

export interface ICompProps extends FormComponentProps {}
interface ICompStates {
  dataSource?: any
  editCompId: any
  pageSize: number
  page: number
  total: number
  checkedList: any
  loading: boolean
  organList: any[]
  region: any
  regiProvinId: any
  regiCityId: any
  provinName: any
  cityName: any
  regiCountrId: any
  countrName: any
}

class Comp extends React.Component<ICompProps, ICompStates> {
  constructor(props: any) {
    super(props)
    this.getCompList = this.getCompList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.showTotal = this.showTotal.bind(this)
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this)
    this.onDeleteCancel = this.onDeleteCancel.bind(this)
    this.state = {
      editCompId: '',
      pageSize: 20,
      page: 1,
      dataSource: [],
      checkedList: [],
      organList: [],
      total: 0,
      loading: false,
      region: {},
      regiProvinId: '',
      regiCityId: '',
      provinName: '',
      cityName: '',
      regiCountrId: '',
      countrName: '',
    }
  }
  async componentDidMount() {
    await this.getOrganList()
    this.getCompList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  getCompList = async () => {
    const values = this.props.form!.getFieldsValue()
    this.setState({
      loading: true,
    })
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      ...values,
      regiProvinId: this.state.regiProvinId,
      regiCityId: this.state.regiCityId,
      regiCountrId: this.state.regiCountrId,
      region: [],
    }
    const res = await compList(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  handleSearch = () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getCompList()
    }, 0)
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getCompList()
    }, 0)
  }

  onDeleteConfirm = async (e: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请先选择企业')
      return
    }
    const res = await compDel(this.state.checkedList)
    if (res.success) {
      message.success('删除成功')
      this.getCompList()
    } else {
      message.error('删除失败')
    }
  }

  getOrganList = async () => {
    const res = await organList({})
    if (res.success) {
      this.setState({
        organList: res.data,
      })
    }
  }

  onDeleteCancel = (e: any) => {}

  public render() {
    const { getFieldDecorator } = this.props.form!
    const rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
        setTimeout(() => {
          this.setState({
            checkedList: selectedRowKeys,
          })
        }, 0)
      },
      getCheckboxProps: (record: any) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }

    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="系统设置" second="企业管理" />
        <Main
          contentTitle="所有企业"
          centerContent={
            <div>
              <Form layout="inline" style={{ marginBottom: 16 }}>
                {hasPermits(PERM.setting_comp_func_add) && (
                  <Form.Item>
                    <CompAdd
                      buttonTitle="新增企业"
                      icon="plus"
                      compId=""
                      onSuccess={() => {
                        this.onPageChange(1)
                      }}
                    />
                  </Form.Item>
                )}

                {hasPermits(PERM.setting_comp_func_del) && (
                  <Form.Item>
                    <Popconfirm
                      title="确定要删除企业吗"
                      onConfirm={this.onDeleteConfirm}
                      onCancel={this.onDeleteCancel}
                      okText="删除"
                      cancelText="取消"
                    >
                      <Button icon="delete" type="danger" ghost>
                        删除
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              </Form>
              {hasPermits(PERM.setting_comp_func_search) && (
                <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
                  <Form.Item label="企业名称">
                    {getFieldDecorator('compName')(<Input placeholder="企业名称" allowClear />)}
                  </Form.Item>
                  <Form.Item label="选择机构">
                    {getFieldDecorator('organId')(
                      <Select style={{ width: 300 }} allowClear>
                        {this.state.organList.map((organ: any) => {
                          return (
                            <Option key={organ.organId} value={organ.organId}>
                              {organ.organName}
                            </Option>
                          )
                        })}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item label="所属区域">
                    {getFieldDecorator('region')(
                      <RegionCascader
                        maxLevel={3}
                        regiProvinId={this.state.regiProvinId}
                        provinName={this.state.provinName}
                        regiCityId={this.state.regiCityId}
                        cityName={this.state.cityName}
                        regiCountrId={this.state.regiCountrId}
                        countrName={this.state.countrName}
                        onChange={(e: any) => {
                          this.setState({
                            regiProvinId: '',
                            provinName: '',
                            regiCityId: '',
                            cityName: '',
                            regiCountrId: '',
                            countrName: '',
                          })
                          if (e.length > 0) {
                            e.forEach((element: any) => {
                              setTimeout(() => {
                                if (element.level == 1) {
                                  this.setState({
                                    regiProvinId: element.id,
                                    provinName: element.name,
                                  })
                                } else if (element.level == 2) {
                                  this.setState({
                                    regiCityId: element.id,
                                    cityName: element.name,
                                  })
                                } else if (element.level == 3) {
                                  this.setState({
                                    regiCountrId: element.id,
                                    countrName: element.name,
                                  })
                                }
                              }, 0)
                            })
                          }
                        }}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" icon="search" htmlType="submit">
                      搜索
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <Pagination
                current={this.state.page}
                size="small"
                pageSizeOptions={['10', '20', '50', '100', '200']}
                pageSize={this.state.pageSize}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    this.setState({
                      pageSize: size,
                    })
                    this.onPageChange(1)
                  }, 0)
                }}
              />
              <Table
                rowSelection={rowSelection}
                loading={this.state.loading}
                rowKey="compId"
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                dataSource={this.state.dataSource}
              >
                <Column
                  title="企业名称"
                  dataIndex="compName"
                  width="250px"
                  key="compName"
                  render={(compName: any) => compName}
                />

                <Column
                  title="所属机构"
                  width="200px"
                  dataIndex="organName"
                  key="organName"
                  render={(organName: any) => organName}
                />
                <Column
                  title="企业类型"
                  width="100px"
                  dataIndex="compType"
                  key="compType"
                  render={(compType: any) => {
                    let compTypeStr = ''
                    switch (parseInt(compType)) {
                      case 1: {
                        compTypeStr = '物流企业'
                        break
                      }
                      case 2: {
                        compTypeStr = '水泥企业'
                        break
                      }
                      case 3: {
                        compTypeStr = '砂浆企业'
                        break
                      }
                      case 4: {
                        compTypeStr = '混凝土企业'
                        break
                      }
                    }
                    return compTypeStr
                  }}
                />
                <Column
                  title="地区"
                  width="200px"
                  dataIndex="provinName"
                  key="provinName"
                  render={(provinName: any, row: any) =>
                    `${row.provinName ? row.provinName + '/' : ''} ${
                      row.cityName ? row.cityName + '/' : ''
                    }${row.countrName ? row.countrName : ''}`
                  }
                />
                <Column
                  title="详细地址"
                  width="200px"
                  dataIndex="address"
                  key="address"
                  render={(address: any) => address}
                />
                <Column
                  title="法人"
                  dataIndex="legalPerson"
                  key="legalPerson"
                  render={(legalPerson: any) => legalPerson}
                />
                <Column
                  title="联系方式"
                  dataIndex="legalPhone"
                  key="legalPhone"
                  render={(legalPhone: any) => legalPhone}
                />
                <Column
                  title="备注"
                  dataIndex="remark"
                  key="remark"
                  render={(remark: any) => remark}
                />

                {hasPermits(PERM.setting_comp_func_mod) && (
                  <Column
                    title="操作"
                    dataIndex="compId"
                    key="compId"
                    render={(compId: any) => (
                      <CompAdd
                        buttonTitle="编辑"
                        icon="edit"
                        compId={compId}
                        onSuccess={() => {
                          this.onPageChange(1)
                        }}
                      />
                    )}
                  />
                )}
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}

export default Form.create<ICompProps>()(Comp)

import React from 'react'
import { Table, Form, Button, Pagination, Input, message, Popconfirm } from 'antd'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import AccountAdd from './AccountAdd'
import { acctList, acctDel } from './../../service/account'
import { FormComponentProps } from 'antd/lib/form'
import { PERM, hasPermits } from './../../utils/permits'

const { Column } = Table
export interface IAccountProps extends FormComponentProps {}
interface IAccountStates {
  dataSource?: any
  editAccountId: any
  pageSize: number
  page: number
  total: number
  checkedList: any
  loading: boolean
}

class Account extends React.Component<IAccountProps, IAccountStates> {
  constructor(props: any) {
    super(props)
    this.getAccountList = this.getAccountList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.showTotal = this.showTotal.bind(this)
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this)
    this.onDeleteCancel = this.onDeleteCancel.bind(this)
    this.state = {
      editAccountId: '',
      pageSize: 20,
      page: 1,
      dataSource: [],
      checkedList: [],
      total: 0,
      loading: false,
    }
  }
  componentDidMount() {
    this.getAccountList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  getAccountList = async () => {
    const values = this.props.form!.getFieldsValue()
    this.setState({
      loading: true,
    })
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      accountTypes: '2,3',
      ...values,
    }
    const res = await acctList(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  handleSearch = () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getAccountList()
    }, 0)
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getAccountList()
    }, 0)
  }

  onDeleteConfirm = async (e: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请先选择账号')
      return
    }
    const res = await acctDel(this.state.checkedList)
    if (res.success) {
      message.success('删除成功')
      this.getAccountList()
    } else {
      message.error('删除失败')
    }
  }

  onDeleteCancel = (e: any) => {}

  public render() {
    const { getFieldDecorator } = this.props.form!
    const rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
        setTimeout(() => {
          this.setState({
            checkedList: selectedRowKeys,
          })
        }, 0)
      },
      getCheckboxProps: (record: any) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }

    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="系统设置" second="账户管理" />
        <Main
          contentTitle="所有账户"
          centerContent={
            <div>
              <Form layout="inline" style={{ marginBottom: 16 }}>
                {hasPermits(PERM.setting_account_func_add) && (
                  <Form.Item>
                    <AccountAdd
                      buttonTitle="新增账户"
                      icon="plus"
                      accountId=""
                      onSuccess={() => {
                        this.onPageChange(1)
                      }}
                    />
                  </Form.Item>
                )}

                {hasPermits(PERM.setting_account_func_del) && (
                  <Form.Item>
                    <Popconfirm
                      title="确定要删除账户吗"
                      onConfirm={this.onDeleteConfirm}
                      onCancel={this.onDeleteCancel}
                      okText="删除"
                      cancelText="取消"
                    >
                      <Button icon="delete" type="danger" ghost>
                        删除
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              </Form>
              {hasPermits(PERM.setting_account_func_search) && (
                <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
                  <Form.Item label="账户名称">
                    {getFieldDecorator('loginName')(<Input placeholder="账户名称" allowClear />)}
                  </Form.Item>
                  <Form.Item label="创建者账户姓名">
                    {getFieldDecorator('loginName_')(
                      <Input placeholder="创建者账户姓名" allowClear />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" icon="search" htmlType="submit">
                      搜索
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <Pagination
                current={this.state.page}
                size="small"
                pageSizeOptions={['10', '20', '50', '100', '200']}
                pageSize={this.state.pageSize}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    this.setState({
                      pageSize: size,
                    })
                    this.onPageChange(1)
                  }, 0)
                }}
              />
              <Table
                rowSelection={rowSelection}
                loading={this.state.loading}
                rowKey="accountId"
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                dataSource={this.state.dataSource}
              >
                <Column
                  title="账户名称"
                  dataIndex="loginName"
                  key="loginName"
                  render={(loginName: any) => loginName}
                />

                <Column
                  title="账户类型"
                  dataIndex="accountType"
                  key="accountType"
                  render={(accountType: any) => {
                    let accountTypeStr = ''
                    switch (parseInt(accountType)) {
                      case 1: {
                        accountTypeStr = '学员'
                        break
                      }
                      case 2: {
                        accountTypeStr = '机构'
                        break
                      }
                      case 3: {
                        accountTypeStr = '企业'
                        break
                      }
                    }
                    return accountTypeStr
                  }}
                />
                <Column
                  title="企业/机构名称"
                  dataIndex="accountType"
                  key="accountType"
                  render={(accountType: any, row: any) =>
                    accountType == 2 ? row.organNames : row.compNames
                  }
                />
                <Column
                  title="角色名称"
                  dataIndex="roleName"
                  key="roleName"
                  render={(roleName: any) => roleName}
                />
                <Column
                  title="联系人"
                  dataIndex="linkman"
                  key="linkman"
                  render={(linkman: any) => linkman}
                />
                <Column
                  title="联系方式"
                  dataIndex="phone"
                  key="phone"
                  render={(phone: any) => phone}
                />
                <Column
                  title="身份证"
                  dataIndex="cardID"
                  key="cardID"
                  render={(cardID: any) => cardID}
                />
                <Column
                  title="创建账户名称"
                  dataIndex="loginName_"
                  key="loginName_"
                  render={(loginName_: any) => loginName_}
                />

                {hasPermits(PERM.setting_account_func_mod) && (
                  <Column
                    title="操作"
                    dataIndex="accountId"
                    key="accountId"
                    render={(accountId: any) => (
                      <AccountAdd
                        buttonTitle="编辑"
                        icon="edit"
                        accountId={accountId}
                        onSuccess={() => {
                          this.onPageChange(1)
                        }}
                      />
                    )}
                  />
                )}
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}

export default Form.create<IAccountProps>()(Account)

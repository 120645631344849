import React from 'react'
import { Table, Form, Button, Pagination, Input } from 'antd'
import { Link } from 'react-router-dom'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import { statCourse } from './../../service/stats'
import { FormComponentProps } from 'antd/lib/form'
import { PERM, hasPermits } from './../../utils/permits'

const { Column } = Table
export interface ICourseStatProps extends FormComponentProps {}
interface ICourseStatStates {
  dataSource?: any
  editCourseStatId: any
  pageSize: number
  page: number
  total: number
  loading: boolean
}

class CourseStat extends React.Component<ICourseStatProps, ICourseStatStates> {
  constructor(props: any) {
    super(props)
    this.getCourseStatList = this.getCourseStatList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.showTotal = this.showTotal.bind(this)
    this.state = {
      editCourseStatId: '',
      pageSize: 20,
      page: 1,
      dataSource: [],
      total: 0,
      loading: false,
    }
  }
  componentDidMount() {
    this.getCourseStatList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  handleSearch = async () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getCourseStatList()
    }, 0)
  }

  getCourseStatList = async () => {
    this.setState({
      loading: true,
    })
    const values = this.props.form!.getFieldsValue()
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      ...values,
    }
    const res = await statCourse(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getCourseStatList()
    }, 0)
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="统计管理" second="学习统计" />
        <Main
          contentTitle="学习统计"
          centerContent={
            <div>
              {hasPermits(PERM.stat_study_search) && (
                <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
                  <Form.Item label="课程标题">
                    {getFieldDecorator('title')(<Input placeholder="课程标题" allowClear />)}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" icon="search" htmlType="submit">
                      搜索
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <Pagination
                current={this.state.page}
                size="small"
                pageSizeOptions={['10', '20', '50', '100', '200']}
                pageSize={this.state.pageSize}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    this.setState({
                      pageSize: size,
                    })
                    this.onPageChange(1)
                  }, 0)
                }}
              />
              <Table
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                loading={this.state.loading}
                dataSource={this.state.dataSource}
              >
                <Column
                  title="课程标题"
                  dataIndex="title"
                  key="title"
                  render={(title: any) => title}
                />

                <Column
                  title="学习次数"
                  dataIndex="partiNum"
                  key="partiNum"
                  render={(partiNum: any) => partiNum}
                />
                <Column
                  title="需学习次数"
                  dataIndex="stuNum"
                  key="stuNum"
                  render={(stuNum: any) => stuNum}
                />
                <Column
                  title="学习中人数"
                  dataIndex="partiStuNum2"
                  key="partiStuNum2"
                  render={(partiStuNum2: any) => partiStuNum2}
                />
                <Column
                  title="学习完成人数"
                  dataIndex="partiStuNum3"
                  key="partiStuNum3"
                  render={(partiStuNum3: any) => partiStuNum3}
                />
                <Column
                  title="参与率"
                  dataIndex="partiLearnPer"
                  key="partiLearnPer"
                  render={(partiLearnPer: any) => partiLearnPer + '%'}
                />
                <Column
                  title="完成率"
                  dataIndex="finishLearnPer"
                  key="finishLearnPer"
                  render={(finishLearnPer: any) => finishLearnPer + '%'}
                />

                <Column
                  title="操作"
                  dataIndex="courseId"
                  key="courseId"
                  render={(courseId: any, course: any) => (
                    <Link
                      to={'/app/stat/coursestu?courseId=' + courseId + '&title=' + course.title}
                    >
                      <Button type="primary">学习详情</Button>
                    </Link>
                  )}
                />
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}
export default Form.create<ICourseStatProps>()(CourseStat)

import * as React from 'react'
import { Form, Input, Icon, Card, Button, message } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import BreadcrumbCustom from '../BreadcrumbCustom'
import umbrella from 'umbrella-storage'
import { acctPwdMod } from '../../service/account'

export type IAccountPwdProps = {} & FormComponentProps & RouteComponentProps

class AccountPwd extends React.Component<IAccountPwdProps> {
  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        let storageUser = umbrella.getLocalStorage('user')
        const params = {
          ...values,
          accountId: storageUser.accountId || '',
        }
        const res = await acctPwdMod(params)
        if (res.success) {
          message.success('密码修改成功！')
          umbrella.removeLocalStorage('user')
          this.props.history.push('/login')
        } else {
          message.error(res.msg)
        }
      }
    })
  }
  public render() {
    const { getFieldDecorator } = this.props.form!
    return (
      <div className="main">
        <BreadcrumbCustom first="修改密码" />
        <Card title="修改密码" style={{ width: 500 }}>
          <Form onSubmit={this.handleSubmit} layout="horizontal" style={{ marginBottom: 16 }}>
            <Form.Item label="新密码">
              {getFieldDecorator('pwd', { rules: [{ required: true, message: '请输入密码!' }] })(
                <Input
                  placeholder="新密码"
                  prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                  type="password"
                />
              )}
            </Form.Item>
            <Form.Item label="确认密码">
              {getFieldDecorator('rePwd', {
                rules: [{ required: true, message: '请输入确认密码!' }],
              })(
                <Input
                  placeholder="确认密码"
                  prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                  type="password"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    )
  }
}

export default withRouter(Form.create<IAccountPwdProps>()(AccountPwd))

import React from 'react'
import { Table, Form, Button, Pagination, Input, message, Popconfirm } from 'antd'
import BreadcrumbCustom from '../../BreadcrumbCustom'
import Main from '../../main'
import NoticeAdd from './NoticeAdd'
import { noticeList, noticeDel } from './../../../service/notice'
import { FormComponentProps } from 'antd/lib/form'
import { PERM, hasPermits } from './../../../utils/permits'

const { Column } = Table
export interface INoticeProps extends FormComponentProps {}
interface INoticeStates {
  dataSource?: any
  editNoticeId: any
  pageSize: number
  page: number
  total: number
  checkedList: any
  loading: boolean
}

class Notice extends React.Component<INoticeProps, INoticeStates> {
  constructor(props: any) {
    super(props)
    this.getNoticeList = this.getNoticeList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.showTotal = this.showTotal.bind(this)
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this)
    this.onDeleteCancel = this.onDeleteCancel.bind(this)
    this.state = {
      editNoticeId: '',
      pageSize: 20,
      page: 1,
      dataSource: [],
      checkedList: [],
      total: 0,
      loading: false,
    }
  }
  componentDidMount() {
    this.getNoticeList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  getNoticeList = async () => {
    const values = this.props.form!.getFieldsValue()
    this.setState({
      loading: true,
    })
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      topicType: '4',
      ...values,
    }
    const res = await noticeList(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  handleSearch = () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getNoticeList()
    }, 0)
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getNoticeList()
    }, 0)
  }

  onDeleteConfirm = async (e: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请先选择通知')
      return
    }
    const res = await noticeDel(this.state.checkedList)
    if (res.success) {
      message.success('删除成功')
      this.getNoticeList()
    } else {
      message.error('删除失败')
    }
  }

  onDeleteCancel = (e: any) => {}

  public render() {
    const { getFieldDecorator } = this.props.form!
    const rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
        setTimeout(() => {
          this.setState({
            checkedList: selectedRowKeys,
          })
        }, 0)
      },
      getCheckboxProps: (record: any) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }

    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="通知角色" second="通知管理" />
        <Main
          contentTitle="所有通知"
          centerContent={
            <div>
              <Form layout="inline" style={{ marginBottom: 16 }}>
                {hasPermits(PERM.setting_account_func_add) && (
                  <Form.Item>
                    <NoticeAdd
                      buttonTitle="新增通知"
                      icon="plus"
                      topicId=""
                      onSuccess={() => {
                        this.onPageChange(1)
                      }}
                    />
                  </Form.Item>
                )}

                {hasPermits(PERM.setting_account_func_del) && (
                  <Form.Item>
                    <Popconfirm
                      title="确定要删除通知吗"
                      onConfirm={this.onDeleteConfirm}
                      onCancel={this.onDeleteCancel}
                      okText="删除"
                      cancelText="取消"
                    >
                      <Button icon="delete" type="danger" ghost>
                        删除
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              </Form>
              {hasPermits(PERM.setting_account_func_search) && (
                <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
                  <Form.Item label="标题">
                    {getFieldDecorator('title')(<Input placeholder="标题" allowClear />)}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" icon="search" htmlType="submit">
                      搜索
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <Pagination
                current={this.state.page}
                size="small"
                pageSizeOptions={['10', '20', '50', '100', '200']}
                pageSize={this.state.pageSize}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    this.setState({
                      pageSize: size,
                    })
                    this.onPageChange(1)
                  }, 0)
                }}
              />
              <Table
                rowSelection={rowSelection}
                loading={this.state.loading}
                rowKey="topicId"
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                dataSource={this.state.dataSource}
              >
                <Column title="标题" dataIndex="title" key="title" render={(title: any) => title} />
                <Column
                  title="内容"
                  width={'40%'}
                  dataIndex="content"
                  key="content"
                  render={(content: any) => (
                    <span dangerouslySetInnerHTML={{ __html: content }}></span>
                  )}
                />
                <Column
                  title="发布时间"
                  dataIndex="issueDateTime"
                  key="issueDateTime"
                  render={(issueDateTime: any) => issueDateTime}
                />
                <Column
                  title="发布人"
                  dataIndex="loginName"
                  key="loginName"
                  render={(loginName: any) => loginName}
                />
                <Column
                  title="类型"
                  dataIndex="mattStatus"
                  key="mattStatus"
                  render={(mattStatus: any) => {
                    let mattStatusStr = ''
                    switch (parseInt(mattStatus)) {
                      case 1: {
                        mattStatusStr = '普通'
                        break
                      }
                      case 2: {
                        mattStatusStr = '紧急'
                        break
                      }
                    }
                    return mattStatusStr
                  }}
                />

                {/* {hasPermits(PERM.setting_account_func_mod) && ( */}
                <Column
                  title="操作"
                  dataIndex="topicId"
                  key="topicId"
                  render={(topicId: any) => (
                    <NoticeAdd
                      buttonTitle="编辑"
                      icon="edit"
                      topicId={topicId}
                      onSuccess={() => {
                        this.onPageChange(1)
                      }}
                    />
                  )}
                />
                {/* )} */}
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}

export default Form.create<INoticeProps>()(Notice)

import * as React from 'react'
import Main from './../main'
import { Button, List, Form, Input, InputNumber, Transfer, Modal, message, Spin } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { chapterList, chapterSave, courseWareList, chapterDel } from './../../service/course'
var nzhcn = require('nzh/cn')
const { confirm } = Modal

type ICourseChapterProps = {
  onNextStep?: (e: any) => void
  onLastStep?: (e: any) => void
  courseId?: any
} & FormComponentProps
export interface ICourseChapterStates {
  chapterList: any[]
  editChapterIndex: number
  courseWareList: any[]
  selectTextList: any[]
  targetKeys: any[]
  selectedKeys: any[]
  loading: boolean
}

class CourseChapter extends React.Component<ICourseChapterProps, ICourseChapterStates> {
  constructor(props: any) {
    super(props)
    this.onAddChapter = this.onAddChapter.bind(this)
    this.onChapterTitleClick = this.onChapterTitleClick.bind(this)
    this.getCourseChapter = this.getCourseChapter.bind(this)

    this.state = {
      chapterList: [],
      editChapterIndex: 0,
      courseWareList: [],
      selectTextList: [],
      targetKeys: [],
      selectedKeys: [],
      loading: false,
    }
  }

  async componentDidMount() {
    this.props.form.resetFields()
    this.setState({
      chapterList: [],
      editChapterIndex: 0,
      courseWareList: [],
      selectTextList: [],
      targetKeys: [],
      selectedKeys: [],
      loading: false,
    })
    await this.getCourseChapter()
    this.getCourseWareList()
  }
  async componentDidUpdate(props: any) {
    if (props.courseId && props.courseId != this.props.courseId) {
      this.props.form.resetFields()
      this.setState({
        chapterList: [],
        editChapterIndex: 0,
        courseWareList: [],
        selectTextList: [],
        targetKeys: [],
        selectedKeys: [],
        loading: false,
      })
      await this.getCourseChapter()
      this.getCourseWareList()
    }
  }

  getCourseChapter = async () => {
    if (this.props.courseId) {
      this.setState({
        loading: true,
      })
      const res = await chapterList(this.props.courseId)
      if (res.success) {
        const chapterList = res.data.map((item: any) => {
          const textIds = item.chapTextJson.map((text: any) => text.textId.toString())
          return {
            ...item,
            chapDura: item.chapDura / 60,
            textIds,
          }
        })
        this.setState({
          chapterList: chapterList,
          editChapterIndex: 0,
        })
        this.changeCurrentChapter(0)
      }
    }
  }

  getCourseWareList = async () => {
    const params = {}
    const res = await courseWareList(params)
    if (res.success) {
      this.setState({
        courseWareList: res.data.map((item: any) => {
          return {
            key: item.textId.toString(),
            title: item.origFileName,
            description: ``,
          }
        }),
      })
    }
    this.setState({
      loading: false,
    })
  }

  onChapterTitleClick = (index: number) => {
    if (this.state.editChapterIndex == index) {
      this.changeCurrentChapter(index)
    } else {
      confirm({
        title: '是否保存当前章节?',
        content: '不保存将会失去当前填写的内容',
        okText: '保存',
        cancelText: '不保存',
        onOk: async () => {
          this.onSaveChapter((result) => {
            if (result) {
              const chapter = this.props.form.getFieldsValue()
              this.state.chapterList[this.state.editChapterIndex] = chapter
              this.changeCurrentChapter(index)
            }
          })
        },
        onCancel: () => {
          this.changeCurrentChapter(index)
        },
      })
    }
  }

  changeCurrentChapter = (index: number) => {
    const item = this.state.chapterList[index]
    if (item) {
      this.setState({
        editChapterIndex: index,
        targetKeys: item.textIds,
      })
      this.props.form.setFieldsValue({
        chapterId: item.chapterId,
        modifyIdJson: item.modifyIdJson,
        title: item.title,
        abstract: item.abstract,
        chapDura: item.chapDura,
        textIds: item.textIds,
      })
    } else {
      this.props.form.setFieldsValue({
        chapterId: '',
        title: '',
        abstract: '',
        chapDura: '',
      })
    }
  }

  onAddChapter = (e: any) => {
    const chapterList = this.state.chapterList
    chapterList.push({
      title: `第${nzhcn.encodeS(chapterList.length + 1)}章：标题`,
      abstract: '',
    })
    this.setState({ chapterList: chapterList })
    this.changeCurrentChapter(chapterList.length - 1)
  }

  onDeleteChapter = async (index: any) => {
    const chapterList = this.state.chapterList
    const chapter = chapterList[index]

    if (chapter.chapterId) {
      const res = await chapterDel([chapter.chapterId])
      if (res.success) {
        chapterList.splice(index, 1)
        this.setState({ chapterList: chapterList })
        this.changeCurrentChapter(0)
      } else {
        message.error('章节删除失败')
      }
    } else {
      chapterList.splice(index, 1)
      this.setState({ chapterList: chapterList })
      this.changeCurrentChapter(0)
    }
  }

  handleSubmit = async (e: any) => {
    e.preventDefault()
    await this.onSaveChapter()
  }

  onSaveChapter = async (callback?: (e: boolean) => any) => {
    this.props.form &&
      this.props.form.validateFields(async (err: any, values: any) => {
        if (!err) {
          this.setState({
            loading: true,
          })
          const params = {
            ...values,
            chapDura: values.chapDura * 60,
            courseId: this.props.courseId,
            textIds: values.textIds ? values.textIds.join(',') : '',
            orderNum: this.state.editChapterIndex,
          }
          this.state.chapterList[this.state.editChapterIndex] = params
          const res = await chapterSave(params)
          if (res.success) {
            message.success('保存成功')
          } else {
            message.error(res.msg || '保存失败')
          }
          this.setState({
            loading: false,
          })
          if (callback) {
            callback(true)
          }
        } else {
          if (callback) {
            callback(false)
          }
        }
      })
  }

  normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  handleChange = (nextTargetKeys: any, direction: any, moveKeys: any) => {
    this.setState({ targetKeys: nextTargetKeys })
    this.props.form.setFieldsValue({
      textIds: nextTargetKeys,
    })
  }

  handleSelectChange = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
    this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] })
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const form = this.props.form!
    return (
      <Spin spinning={this.state.loading}>
        <Main
          leftTitle="新建章节"
          contentTitle="编辑框"
          leftContent={
            <div>
              <Button type="primary" block onClick={this.onAddChapter}>
                新建章节
              </Button>
              <List
                itemLayout="horizontal"
                dataSource={this.state.chapterList}
                bordered={true}
                renderItem={(item, index) => (
                  <List.Item
                    onClick={() => this.onChapterTitleClick(index)}
                    actions={[
                      <Button
                        icon="close"
                        size="small"
                        onClick={() => this.onDeleteChapter(index)}
                      ></Button>,
                    ]}
                  >
                    <List.Item.Meta title={item.title} />
                  </List.Item>
                )}
              />
            </div>
          }
          centerContent={
            <Form onSubmit={this.handleSubmit}>
              <Form.Item label="标题">
                {getFieldDecorator('title', {
                  rules: [{ required: true, message: '请输入标题!' }],
                })(<Input placeholder="标题" />)}
              </Form.Item>
              <Form.Item label="章节简介">
                {getFieldDecorator('abstract', {
                  rules: [{ required: true, message: '请输入章节简介!' }],
                })(<Input.TextArea rows={4} placeholder="章节简介" />)}
              </Form.Item>
              <Form.Item label="章节时长">
                {getFieldDecorator('chapDura', {
                  rules: [{ required: true, message: '请输入章节时长!' }],
                })(
                  <InputNumber
                    min={1}
                    max={100}
                    onChange={(value?: number) => {
                      form.setFieldsValue({
                        chapDura: value,
                      })
                    }}
                  />
                )}
                （分）
              </Form.Item>
              <Form.Item label="课件">
                {getFieldDecorator('textIds', {
                  rules: [{ required: true, message: '请选择课件!' }],
                  valuePropName: 'textIds',
                })(
                  <Transfer
                    dataSource={this.state.courseWareList}
                    titles={['课件列表', '已选课件']}
                    targetKeys={this.state.targetKeys}
                    selectedKeys={this.state.selectedKeys}
                    onChange={this.handleChange}
                    onSelectChange={this.handleSelectChange}
                    render={(item: any) => item.title}
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('chapterId', {})(<Input placeholder="标题" hidden />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('modifyIdJson', {})(<Input placeholder="标题" hidden />)}
              </Form.Item>
              <Form.Item>
                <Button className="mt-m" type="primary" htmlType="submit" ghost>
                  保存此章节
                </Button>
              </Form.Item>
            </Form>
          }
          bottomContent={
            this.props.onNextStep && (
              <Button
                className="mt-m"
                type="primary"
                onClick={() => {
                  if (this.props.onNextStep) {
                    this.props.onNextStep({ courseId: this.props.courseId })
                  }
                }}
                ghost
              >
                下一步
              </Button>
            )
          }
        />
      </Spin>
    )
  }
}

export default Form.create<ICourseChapterProps>()(CourseChapter)

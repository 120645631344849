import * as React from 'react'
import { Steps, Card } from 'antd'
import CourseInfo from './CourseInfo'
import CourseChapter from './CourseChapter'
import CourseSetting from './CourseSetting'
import BreadcrumbCustom from '../BreadcrumbCustom'
import { Link } from 'react-router-dom'
import { createHashHistory } from 'history'
const { Step } = Steps
const history = createHashHistory() // hash模式

export interface ICourseAddProps {}
interface ICourseAddStates {
  stepIndex: number
  courseId: any
}

export default class CourseAdd extends React.Component<ICourseAddProps, ICourseAddStates> {
  constructor(props: any) {
    super(props)
    this.onNextStep = this.onNextStep.bind(this)
    this.state = {
      stepIndex: 0,
      courseId: '',
    }
  }
  onLastStep = (e: any) => {
    const index = this.state.stepIndex - 1
    setTimeout(() => {
      this.setState({
        stepIndex: index < 0 ? 0 : index,
      })
    }, 0)
  }
  onNextStep = (e: any) => {
    const index = this.state.stepIndex + 1
    setTimeout(() => {
      this.setState({
        stepIndex: index > 2 ? 2 : index,
      })
      if (e) {
        if (this.state.stepIndex == 2) {
          history.goBack()
          return
        }
        this.setState({
          courseId: e.courseId,
        })
      }
    }, 0)
  }
  public render() {
    return (
      <div className="w-full h-full">
        <BreadcrumbCustom
          first={<Link to={'/app/course/index'}>课程管理</Link>}
          second={<Link to={'/app/course/index'}>课程列表</Link>}
          third="课程新增"
        />
        <Card>
          <Steps className="mb-l" current={this.state.stepIndex} status="process">
            <Step title="课程信息" />
            <Step title="课程章节" />
            <Step title="课程设置" />
          </Steps>
          {this.state.stepIndex == 0 && <CourseInfo onNextStep={this.onNextStep} />}
          {this.state.stepIndex == 1 && (
            <CourseChapter
              onLastStep={this.onLastStep}
              onNextStep={this.onNextStep}
              courseId={this.state.courseId}
            />
          )}
          {this.state.stepIndex == 2 && (
            <CourseSetting onNextStep={this.onNextStep} courseId={this.state.courseId} />
          )}
        </Card>
      </div>
    )
  }
}

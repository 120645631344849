import * as React from 'react'
import { Tree, Input, Form, message, Modal, Button, Spin } from 'antd'
import { permTree } from './../../service/perm'
import { roleSave, roleOne } from './../../service/role'
import { FormComponentProps } from 'antd/lib/form'
import EditableTagGroup from '../widget/EditTagGroup'

export interface IRoleAddProps extends FormComponentProps {
  roleId: any
  buttonTitle: string
  icon: string
}
export interface IRoleAddStates {
  expandedKeys: any
  treeData: any
  searchValue: any
  autoExpandParent: boolean
  dataList: any
  checkedKeys: any
  compName: any
  compId: any
  region: any
  regiProvinId: any
  regiCityId: any
  provinName: any
  cityName: any
  regiCountrId: any
  countrName: any
  editDialogVisible: boolean
  checkNodeIds: any[]
  loading: boolean
  checkedTagIds: any[]
}

const { TreeNode } = Tree

const getParentKey = (id: any, tree: any): any => {
  let parentKey
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some((item: any) => item.id === id)) {
        parentKey = node.id
      } else if (getParentKey(id, node.children)) {
        parentKey = getParentKey(id, node.children)
      }
    }
  }
  return parentKey
}

class RoleAdd extends React.Component<IRoleAddProps, IRoleAddStates> {
  constructor(props: any) {
    super(props)
    this.getPermTreeList = this.getPermTreeList.bind(this)
    this.handleOk = this.handleOk.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.getTreeNode = this.getTreeNode.bind(this)
    this.getSelectNodeIds = this.getSelectNodeIds.bind(this)
    this.state = {
      expandedKeys: [],
      treeData: [],
      searchValue: '',
      autoExpandParent: false,
      dataList: [],
      checkedKeys: [],
      checkNodeIds: [],
      compName: '',
      compId: '',
      region: {},
      regiProvinId: '',
      regiCityId: '',
      provinName: '',
      cityName: '',
      regiCountrId: '',
      countrName: '',
      editDialogVisible: false,
      loading: false,
      checkedTagIds: [],
    }
  }

  componentDidMount() {}

  onExpand = (expandedKeys: any) => {
    this.setState({
      expandedKeys,
    })
  }

  getRoleOne = async () => {
    const res = await roleOne(this.props.roleId)
    if (res.success) {
      const role = res.data[0]
      role.permJson.map((item: any) => this.getSelectNodeIds(item))
      this.props.form.setFieldsValue({
        roleName: role.roleName,
        roleDesc: role.roleDesc,
        checkedKeys: this.state.checkNodeIds,
      })

      setTimeout(() => {
        this.setState({
          checkedKeys: this.state.checkNodeIds,
          checkNodeIds: this.state.checkNodeIds,
          loading: false,
        })
      }, 0)
    }
  }

  getSelectNodeIds(node: any) {
    this.state.checkNodeIds.push(node.permId + '')
    if (node.funcJson && node.funcJson.length > 0) {
      node.funcJson.forEach((func: any) => {
        this.state.checkedTagIds.push(func.permId)
      })
    }
    if (node.children && node.children.length > 0) {
      node.children.map((item: any) => {
        this.getSelectNodeIds(item)
      })
      node.funcJson.map((item: any) => {
        this.getSelectNodeIds(item)
      })
    }
  }

  getTreeNode(node: any) {
    if (node.children && node.children.length > 0) {
      node.children = node.children.map((item: any) => {
        return this.getTreeNode(item)
      })
    }

    return {
      code: node.code,
      funcJson: node.funcJson,
      id: node.id.toString(),
      parentId: node.parentId.toString(),
      parentPermName: node.parentPermName,
      permType: node.permType,
      text: node.text,
      children: node.children,
    }
  }

  getPermTreeList = async () => {
    const res = await permTree('')
    let data: any = []
    if (res.data.length == 0) {
      data.push({
        text: '新增根节点',
        id: '_all',
        edit: true,
      })
    } else {
      data = res.data.map((item: any) => {
        return this.getTreeNode(item)
      })
    }

    const parent = data[0]
    this.setState({
      treeData: data,
      autoExpandParent: true,
      expandedKeys: [parent.id],
    })
  }

  onCheck = (checkedKeys: any, e: any) => {
    this.setState({ checkedKeys })
    this.props.form.setFieldsValue({
      checkedKeys: checkedKeys,
    })
  }

  titleRender = (item: any) => {
    const { searchValue } = this.state
    const index = item.text ? item.text.indexOf(searchValue) : ''
    const beforeStr = item.text ? item.text.substr(0, index) : ''
    const afterStr = item.text ? item.text.substr(index + searchValue.length) : ''
    const title = (
      <span className="flex-vcenter">
        <span>
          {index > -1 || (searchValue && searchValue.length > 0) ? (
            <span>
              {beforeStr}
              <span style={{ color: '#f50' }}>{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{item.text}</span>
          )}
        </span>
        <EditableTagGroup
          tags={item.funcJson && item.funcJson.map((func: any) => func)}
          checkedIds={this.state.checkedTagIds}
          parent={item}
          editable={false}
          handleTagClick={this.onTagClick}
        />
      </span>
    )
    return title
  }

  handleOk = (e: any) => {
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true })
        let checkedKeys = []
        if (values.checkedKeys.checked) {
          checkedKeys = values.checkedKeys.checked
        } else {
          checkedKeys = values.checkedKeys
        }
        const permIds = [...checkedKeys, ...this.state.checkedTagIds]
        const params = {
          roleName: values.roleName,
          roleDesc: values.roleDesc,
          permIds: permIds.join(','),
        }
        const res = await roleSave(params)
        if (res.success) {
          message.success('保存成功')
          this.setState({
            editDialogVisible: false,
          })
        } else {
          message.error('保存失败')
        }
        this.setState({ loading: false })
      }
    })
  }

  handleCancel = (e: any) => {
    console.log(e)
    this.setState({
      editDialogVisible: false,
    })
  }

  onTagClick = (tag: any, parent: any) => {
    if (tag.checked) {
      this.state.checkedTagIds.push(tag.id)
    } else {
      const index = this.state.checkedTagIds.findIndex((item) => item == tag.id)
      this.state.checkedTagIds.splice(index, 1)
    }
    this.setState({
      checkedTagIds: this.state.checkedTagIds,
    })
    console.log(this.state.checkedTagIds)
  }

  render() {
    const { getFieldDecorator } = this.props.form!
    const { expandedKeys, autoExpandParent } = this.state
    const loop = (data: any) =>
      data.map((item: any) => {
        if (item.children) {
          return (
            <TreeNode key={item.id} title={this.titleRender(item)}>
              {loop(item.children)}
            </TreeNode>
          )
        }
        return <TreeNode key={item.id} title={this.titleRender(item)} />
      })

    return (
      <div>
        <Modal
          width="40%"
          title={this.props.roleId ? '编辑角色' : '添加角色'}
          visible={this.state.editDialogVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Spin spinning={this.state.loading} delay={500}>
            <Form layout="horizontal" style={{ marginBottom: 16 }}>
              <Form.Item label="角色名称">
                {getFieldDecorator('roleName', {
                  rules: [{ required: true, message: '请输入角色名称!' }],
                })(<Input placeholder="角色名称" />)}
              </Form.Item>
              <Form.Item label="角色描述">
                {getFieldDecorator('roleDesc', {
                  rules: [{ required: true, message: '请输入角色描述!' }],
                })(<Input placeholder="角色描述" />)}
              </Form.Item>
              <Form.Item label="权限选择">
                {getFieldDecorator('checkedKeys', {
                  rules: [{ required: true, message: '请选择权限!' }],
                })(
                  <Tree
                    checkable
                    onExpand={this.onExpand}
                    checkStrictly
                    autoExpandParent={autoExpandParent}
                    expandedKeys={expandedKeys}
                    defaultExpandParent={autoExpandParent}
                    onCheck={this.onCheck}
                    checkedKeys={this.state.checkedKeys}
                    defaultCheckedKeys={this.state.checkNodeIds}
                  >
                    {loop(this.state.treeData)}
                  </Tree>
                )}
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
        <Button
          icon={this.props.icon}
          type="primary"
          onClick={async () => {
            this.setState({
              editDialogVisible: true,
              loading: true,
            })

            await this.getPermTreeList()
            if (this.props.roleId) {
              this.getRoleOne()
            } else {
              this.setState({
                loading: false,
              })
            }
          }}
        >
          {this.props.buttonTitle}
        </Button>
      </div>
    )
  }
}

export default Form.create<IRoleAddProps>()(RoleAdd)

import { post } from './tools'
import * as config from './config'

export const courseList = async (params: any) => {
  return await post({
    url: config.COURSE_LIST,
    data: params,
  })
}
export const courseOne = async (id: any) => {
  return await post({
    url: config.COURSE_ONE,
    data: { courseId: id },
  })
}
export const courseSave = async (params: any) => {
  return await post({
    url: config.COURSE_SAVE,
    data: params,
  })
}
export const courseDel = async (ids: any[]) => {
  return await post({
    url: config.COURSE_DEL,
    data: { courseIds: ids.join(',') },
  })
}
export const courseWareList = async (params: any) => {
  return await post({
    url: config.COURSE_WARE_LIST,
    data: params,
  })
}
export const courseWareOne = async (id: any) => {
  return await post({
    url: config.COURSE_WARE_ONE,
    data: { textId: id },
  })
}
export const courseWareSave = async (params: any) => {
  return await post({
    url: config.COURSE_WARE_SAVE,
    data: params,
  })
}
export const courseWareDel = async (ids: any[]) => {
  return await post({
    url: config.COURSE_WARE_DEL,
    data: { textIds: ids.join(',') },
  })
}
export const courseWareUpload = async (params: any) => {
  return await post({
    url: config.COURSE_WARE_UPLOAD,
    data: params,
  })
}
export const chapterList = async (courseId: any) => {
  return await post({
    url: config.CHAPTER_LIST,
    data: { courseId: courseId },
  })
}
export const chapterOne = async (id: any) => {
  return await post({
    url: config.CHAPTER_ONE,
    data: { chapterId: id },
  })
}
export const chapterSave = async (params: any) => {
  return await post({
    url: config.CHAPTER_SAVE,
    data: params,
  })
}
export const chapterDel = async (ids: any[]) => {
  return await post({
    url: config.CHAPTER_DEL,
    data: { chapterIds: ids.join(',') },
  })
}

export const courseSettingList = async (params: any) => {
  return await post({
    url: config.COURSE_SETTING,
    data: params,
  })
}
export const courseSettingSave = async (params: any) => {
  return await post({
    url: config.COURSE_SETTING_SAVE,
    data: params,
  })
}

import * as React from 'react'
import { Card } from 'antd'
export interface IMainProps {
  leftTitle?: string
  rightTitle?: string
  contentTitle?: string
  centerContent?: any
  leftContent?: any
  rightContent?: any
  bottomContent?: any
}

export default class Main extends React.Component<IMainProps> {
  public render() {
    // 右侧内容
    const leftWidth = this.props.leftContent ? 300 : 0
    const rightWidth = this.props.rightContent ? 300 : 0
    let contentWidth = `calc(100% - ${leftWidth + 'px'} - ${rightWidth + 'px'})`

    return (
      <div className="main">
        {this.props.leftContent && (
          <div className="main-left" style={{ width: leftWidth - 10 + 'px' }}>
            <Card title={this.props.leftTitle} style={{ width: '100%' }}>
              {this.props.leftContent}
            </Card>
          </div>
        )}
        <div className="main-content" style={{ width: contentWidth }}>
          <Card title={this.props.contentTitle} style={{ width: '100%' }}>
            {this.props.centerContent}
          </Card>
        </div>
        {this.props.rightContent && (
          <div className="main-right" style={{ width: rightWidth - 10 + 'px' }}>
            <Card title={this.props.rightTitle} style={{ width: '100%' }}>
              {this.props.rightContent}
            </Card>
          </div>
        )}
        <div className="clear-fix"></div>
        {this.props.bottomContent && <div className="main-bottom">{this.props.bottomContent}</div>}
      </div>
    )
  }
}

/**
 * Created by hao.cheng on 2017/4/16.
 */
import { get, post, downBlob } from './tools'
import * as config from './config'
import umbrella from 'umbrella-storage'
import { message } from 'antd'
import { parseTime } from './../utils/index'
import saveAs from './../libs/FileSaver'

export const authLogin = async (params: any) =>
  await post({
    url: config.LOGIN,
    data: params,
  })

export const validCodeBase64 = async () => {
  const timestamp = new Date().getTime()
  const res = await get({
    url: config.VALID_CODE + `?t=${timestamp}`,
  })
  const data = res.data[0]
  if (res.headers.rsessionid) {
    umbrella.setLocalStorage('rsessionid', res.headers.rsessionid)
  }

  return data ? data.codeFClouPic : ''
}

export const fetchGroupList = async (params: any) =>
  await post({
    url: config.GROUP_LIST,
    data: params,
  })
export const fetchGroupOne = async (id: any) =>
  await post({
    url: config.GROUP_ONE,
    data: {
      groupId: id,
    },
  })
export const saveGroup = async (params: any) =>
  await post({
    url: config.GROUP_SAVE,
    data: params,
  })

export const delGroup = async (ids: any) =>
  await post({
    url: config.GROUP_DEL,
    data: {
      groupIds: ids.join(','),
    },
  })

export const moveToGroup = async (params: any) =>
  await post({
    url: config.GROUP_MOVE,
    data: {
      ...params,
      funType: 1,
    },
  })

/**
 *
 * @param {String} url
 * @param {String} title
 * @param {Object} params
 */
export const download = async (url: any, title: any, params: any) => {
  message.loading({ content: '下载中...', url })
  const res = await downBlob({
    url,
    data: params,
    config: {
      responseType: 'blob',
    },
  })
  const blob = res.data
  if (blob.size !== 0) {
    const date = parseTime(new Date(), '{y}{m}{d}{h}{m}{s}')
    const fileName = `${title}${date}.xlsx`
    saveAs(blob, fileName)
    message.success({ content: '下载完毕!', url, duration: 2 })
  }
  return res
}

export const getRegion = async (params: any) => {
  return await post({
    url: config.REGION,
    data: params,
  })
}

export const getGlobalRegion = async (params: any) => {
  return await post({
    url: config.GLOBAL_REGION,
    data: params,
  })
}

export const setTop = async (params: any) => {
  return await post({
    url: config.SET_TOP,
    data: params,
  })
}

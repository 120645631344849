import React from 'react'
import { Table, Form, Button, Pagination, Input, DatePicker } from 'antd'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import { statMonthReportOrgan, statMonthReportComp } from './../../service/stats'
import { FormComponentProps } from 'antd/lib/form'
import { PERM, hasPermits } from './../../utils/permits'
import umbrella from 'umbrella-storage'
import moment from 'moment'
import { STATS_MONTH_REPORT_COMP_EXP, STATS_MONTH_REPORT_ORGAN_EXP } from '../../service/config'
import { download } from './../../service/index'

const { MonthPicker } = DatePicker

const { Column } = Table
const { RangePicker } = DatePicker

export interface IMonthReportStatProps extends FormComponentProps {}
interface IMonthReportStatStates {
  dataSource?: any
  editMonthReportStatId: any
  pageSize: number
  page: number
  total: number
  loading: boolean
  startDate: String
  endDate: String
  accountType: number
  defaultRangeDate: any[]
}

class MonthReportStat extends React.Component<IMonthReportStatProps, IMonthReportStatStates> {
  constructor(props: any) {
    super(props)
    this.getMonthReportStatList = this.getMonthReportStatList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.showTotal = this.showTotal.bind(this)

    const dateFormat = 'YYYY-MM-DD'
    const now = new Date()
    const startDate = now.getFullYear() + '-01-01'

    var date = new Date()
    var day = new Date(date.getFullYear(), date.getMonth(), 0).getDate()
    var endDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, day)

    const defaultRangeDate = [moment(startDate, dateFormat), moment(endDate, dateFormat)]
    this.state = {
      editMonthReportStatId: '',
      pageSize: 20,
      page: 1,
      dataSource: [],
      total: 0,
      loading: false,
      startDate: '',
      endDate: '',
      accountType: 2,
      defaultRangeDate: defaultRangeDate,
    }
  }
  componentDidMount() {
    this.props.form!.setFieldsValue({ date: this.state.defaultRangeDate })
    this.getMonthReportStatList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  handleSearch = async () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getMonthReportStatList()
    }, 0)
  }

  getMonthReportStatList = async () => {
    this.setState({
      loading: true,
    })
    let accountType = 2
    let storageUser = umbrella.getLocalStorage('user')
    if (storageUser) {
      accountType = storageUser.accountType
      setTimeout(() => {
        this.setState({
          accountType: accountType,
        })
      }, 0)
    }
    const values = this.props.form!.getFieldsValue()
    const dateFormat = 'YYYY-MM-DD'
    const startDate =
      values.date && values.date.length > 0 ? moment(values.date[0]).format(dateFormat) : ''
    const endDate =
      values.date && values.date.length > 0 ? moment(values.date[1]).format(dateFormat) : ''

    setTimeout(() => {
      this.setState({
        startDate: startDate,
        endDate: endDate,
      })
    }, 0)

    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      endDate: endDate,
      startDate: startDate,
    }
    let res = null
    let dataSource: any[] = []
    if (accountType === 2) {
      res = await statMonthReportOrgan(params)
      const report = res.data[0]
      const totalTreport = {
        area: '',
        ccieNum: report.ccieNumSum,
        compNum: report.compNumSum,
        learnedNoTest: report.learnedNoTestSum,
        organId: 'total',
        organName: '总计',
        trainCompNum: report.trainCompNumSum,
        trainStuNum: report.trainStuNumSum,
      }
      dataSource = [...report.trainReportJson, totalTreport]
    } else {
      res = await statMonthReportComp(params)

      let report = { learnNum: 0, learnedNoTest: 0, testNum: 0, testRate: 0, trainStuNum: 0 }
      res.data.forEach((item: any) => {
        report = {
          learnNum: report.learnNum + item.learnNum,
          learnedNoTest: report.learnedNoTest + item.learnedNoTest,
          testNum: report.testNum + item.testNum,
          testRate: report.testRate + item.testRate,
          trainStuNum: report.trainStuNum + item.trainStuNum,
        }
      })
      const totalTreport = {
        compId: 'total',
        compName: '总计',
        learnNum: report.learnNum,
        learnedNoTest: report.learnedNoTest,
        testNum: report.testNum,
        testRate: report.testRate,
        trainStuNum: report.trainStuNum,
      }
      dataSource = [...res.data, totalTreport]
    }

    this.setState({
      dataSource: dataSource,
      total: res.total,
      loading: false,
    })
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getMonthReportStatList()
    }, 0)
  }

  handleExport = async (e: any) => {
    const params = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    }
    const url =
      this.state.accountType === 2 ? STATS_MONTH_REPORT_ORGAN_EXP : STATS_MONTH_REPORT_COMP_EXP
    await download(url, '培训月报表', params)
  }

  public render() {
    const { getFieldDecorator } = this.props.form!

    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="统计管理" second="培训月报表" />
        <Main
          contentTitle="培训月报表"
          centerContent={
            <div>
              {hasPermits(PERM.stat_study_search) && (
                <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
                  <Form.Item label="时间">
                    {getFieldDecorator('date')(
                      <RangePicker
                        defaultValue={this.state.defaultRangeDate}
                        format="YYYY-MM-DD"
                        defaultPickerValue={this.state.defaultRangeDate}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" icon="search" htmlType="submit">
                      搜索
                    </Button>
                    <Button icon="export" type="primary" ghost onClick={this.handleExport}>
                      导出
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <Pagination
                current={this.state.page}
                size="small"
                pageSizeOptions={['10', '20', '50', '100', '200']}
                pageSize={this.state.pageSize}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    this.setState({
                      pageSize: size,
                    })
                    this.onPageChange(1)
                  }, 0)
                }}
              />
              {this.state.accountType == 2 && (
                <Table
                  pagination={{
                    current: this.state.page,
                    onChange: this.onPageChange,
                    pageSize: this.state.pageSize,
                    size: 'small',
                    total: this.state.total,
                    showTotal: this.showTotal,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: ['10', '20', '50', '100', '200'],
                    onShowSizeChange: (current, size) => {
                      setTimeout(() => {
                        this.setState({
                          pageSize: size,
                        })
                        this.onPageChange(1)
                      }, 0)
                    },
                  }}
                  loading={this.state.loading}
                  dataSource={this.state.dataSource}
                >
                  <Column
                    title="序号"
                    dataIndex="organId"
                    key="organId"
                    render={(organId: any, row, index) => (organId == 'total' ? '' : index + 1)}
                  />
                  <Column
                    title="机构名称"
                    dataIndex="organName"
                    key="organName"
                    render={(organName: any, row: any) =>
                      row.organId == 'total' ? <div className="text-b">{organName}</div> : organName
                    }
                  />
                  <Column
                    title="地区"
                    dataIndex="area"
                    key="area"
                    render={(area: any, row: any) =>
                      row.organId == 'total' ? <div className="text-b">{area}</div> : area
                    }
                  />
                  <Column
                    title="企业总数"
                    dataIndex="compNum"
                    key="compNum"
                    render={(compNum: any, row: any) =>
                      row.organId == 'total' ? <div className="text-b">{compNum}</div> : compNum
                    }
                  />
                  <Column
                    title="已开展培训的企业数"
                    dataIndex="trainCompNum"
                    key="trainCompNum"
                    render={(trainCompNum: any, row: any) =>
                      row.organId == 'total' ? (
                        <div className="text-b">{trainCompNum}</div>
                      ) : (
                        trainCompNum
                      )
                    }
                  />
                  <Column
                    title="报名注册数"
                    dataIndex="trainStuNum"
                    key="trainStuNum"
                    render={(trainStuNum: any, row: any) =>
                      row.organId == 'total' ? (
                        <div className="text-b">{trainStuNum}</div>
                      ) : (
                        trainStuNum
                      )
                    }
                  />
                  <Column
                    title="完成学习培训数（未考试）"
                    dataIndex="learnedNoTest"
                    key="learnedNoTest"
                    render={(learnedNoTest: any, row: any) =>
                      row.organId == 'total' ? (
                        <div className="text-b">{learnedNoTest}</div>
                      ) : (
                        learnedNoTest
                      )
                    }
                  />

                  <Column
                    title="发证数"
                    dataIndex="ccieNum"
                    key="ccieNum"
                    render={(ccieNum: any, row: any) =>
                      row.organId == 'total' ? <div className="text-b">{ccieNum}</div> : ccieNum
                    }
                  />
                </Table>
              )}

              {this.state.accountType == 3 && (
                <Table
                  pagination={{
                    current: this.state.page,
                    onChange: this.onPageChange,
                    pageSize: this.state.pageSize,
                    size: 'small',
                    total: this.state.total,
                    showTotal: this.showTotal,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: ['10', '20', '50', '100', '200'],
                    onShowSizeChange: (current, size) => {
                      setTimeout(() => {
                        this.setState({
                          pageSize: size,
                        })
                        this.onPageChange(1)
                      }, 0)
                    },
                  }}
                  loading={this.state.loading}
                  dataSource={this.state.dataSource}
                >
                  <Column
                    title="序号"
                    dataIndex="compId"
                    key="compId"
                    render={(compId: any, row, index) => (compId == 'total' ? '' : index + 1)}
                  />
                  <Column
                    title="企业名称"
                    dataIndex="compName"
                    key="compName"
                    render={(compName: any, row: any) =>
                      row.compId == 'total' ? <div className="text-b">{compName}</div> : compName
                    }
                  />
                  <Column
                    title="所属机构"
                    dataIndex="organName"
                    key="organName"
                    render={(organName: any, row: any) =>
                      row.compId == 'total' ? <div className="text-b">{organName}</div> : organName
                    }
                  />
                  <Column
                    title="报名注册数"
                    dataIndex="trainStuNum"
                    key="trainStuNum"
                    render={(trainStuNum: any, row: any) =>
                      row.organId == 'total' ? (
                        <div className="text-b">{trainStuNum}</div>
                      ) : (
                        trainStuNum
                      )
                    }
                  />
                  <Column
                    title="学习未完成人数"
                    dataIndex="trainStuNum"
                    key="trainStuNum"
                    render={(trainStuNum: any, row: any) =>
                      row.organId == 'total' ? (
                        <div className="text-b">{trainStuNum}</div>
                      ) : (
                        trainStuNum
                      )
                    }
                  />
                  <Column
                    title="完成学习培训数（未考试）"
                    dataIndex="learnNum"
                    key="learnNum"
                    render={(learnNum: any, row: any) =>
                      row.compId == 'total' ? <div className="text-b">{learnNum}</div> : learnNum
                    }
                  />
                  <Column
                    title="考试通过人数"
                    dataIndex="testNum"
                    key="testNum"
                    render={(testNum: any, row: any) =>
                      row.compId == 'total' ? <div className="text-b">{testNum}</div> : testNum
                    }
                  />
                  <Column
                    title="通过率"
                    dataIndex="testRate"
                    key="testRate"
                    render={(testRate: any, row: any) =>
                      row.compId == 'total' ? (
                        <div className="text-b">{testRate + '%'}</div>
                      ) : (
                        testRate + '%'
                      )
                    }
                  />
                </Table>
              )}
            </div>
          }
        />
      </div>
    )
  }
}
export default Form.create<IMonthReportStatProps>()(MonthReportStat)

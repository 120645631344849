import * as React from 'react'
import { Card } from 'antd'
import { Steps } from 'antd'
import TestPaperInfo from './TestPaperInfo'
import TestQuesEdit from './TestQuesEdit'
import TestPaperSetting from './TestPaperSetting'
import BreadcrumbCustom from '../BreadcrumbCustom'
import { Link } from 'react-router-dom'
import { createHashHistory } from 'history'
const { Step } = Steps
const history = createHashHistory() // hash模式

export interface ITestPaperAddProps {}
interface ITestPaperAddStates {
  stepIndex: number
  paperId: any
}

export default class TestPaperAdd extends React.Component<ITestPaperAddProps, ITestPaperAddStates> {
  constructor(props: any) {
    super(props)
    this.onNextStep = this.onNextStep.bind(this)
    this.state = {
      stepIndex: 0,
      paperId: '',
    }
  }

  onLastStep = (e: any) => {
    const index = this.state.stepIndex - 1
    setTimeout(() => {
      this.setState({
        stepIndex: index < 0 ? 0 : index,
      })
    }, 0)
  }
  onNextStep = (e: any) => {
    if (e) {
      if (this.state.stepIndex == 2) {
        history.goBack()
        return
      }
      setTimeout(() => {
        const index = this.state.stepIndex + 1
        this.setState({
          stepIndex: index > 2 ? 2 : index,
          paperId: e.paperId,
        })
      }, 0)
    }
  }

  public render() {
    return (
      <div className="w-full h-full">
        <BreadcrumbCustom
          first={<Link to={'/app/testpaper/index'}>试卷管理</Link>}
          second={<Link to={'/app/testpaper/index'}>试卷列表</Link>}
          third="试卷新增"
        />
        <Card>
          <Steps className="mb-l" current={this.state.stepIndex} status="process">
            <Step title="试卷信息" />
            <Step title="选择试题" />
            <Step title="试卷设置" />
          </Steps>
          {this.state.stepIndex == 0 && (
            <TestPaperInfo modal={false} onNextStep={this.onNextStep} />
          )}
          {this.state.stepIndex == 1 && (
            <TestQuesEdit
              onLastStep={this.onLastStep}
              onNextStep={this.onNextStep}
              paperId={this.state.paperId}
            />
          )}
          {this.state.stepIndex == 2 && (
            <TestPaperSetting
              onNextStep={this.onNextStep}
              paperId={this.state.paperId}
              modal={false}
            />
          )}
        </Card>
      </div>
    )
  }
}

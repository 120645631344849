import * as React from 'react'
import { InputNumber, Form, Row, Col } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { quesTypeName } from '../../utils/index'

export type IQuesExtractPanelProps = {
  group: any
  onChange: (e: any) => void
} & FormComponentProps

class QuesExtractPanel extends React.Component<IQuesExtractPanelProps, any> {
  constructor(props: any) {
    super(props)
    this.onQuesNumInputChange = this.onQuesNumInputChange.bind(this)
    this.onScoreInputChange = this.onScoreInputChange.bind(this)
    this.initData = this.initData.bind(this)
  }
  componentDidMount() {
    this.initData(this.props)
  }

  componentWillReceiveProps(props: any) {
    if (props.group && !this.props.group) {
      this.initData(props)
    }
  }

  initData = (props: any) => {
    let params: any = {}
    params[this.props.group.subjectType + '_quesNum'] = props.group ? props.group.subjNum : 0
    params[this.props.group.subjectType + '_score'] = props.group ? props.group.solScore : 1
    this.props.form.setFieldsValue(params)
  }

  onQuesNumInputChange = (value: any) => {
    const fieldsValue = this.props.form.getFieldsValue()
    const result = {
      groupId: this.props.group.groupId,
      subjectType: this.props.group.subjectType,
      subjNum: value,
      solScore: fieldsValue[this.props.group.subjectType + '_score'],
    }
    this.props.onChange(result)
  }

  onScoreInputChange = (value: any) => {
    const fieldsValue = this.props.form.getFieldsValue()
    const result = {
      groupId: this.props.group.groupId,
      subjectType: this.props.group.subjectType,
      subjNum: fieldsValue[this.props.group.subjectType + '_quesNum'],
      solScore: value,
    }
    this.props.onChange(result)
  }

  public render() {
    const formItemLayout = {}
    const { getFieldDecorator } = this.props.form!
    return (
      <Form layout="inline" {...formItemLayout}>
        <Row key={this.props.group.name + '_' + this.props.group.subjectType}>
          <Col span={4}>
            <Form.Item>
              {quesTypeName(this.props.group.subjectType)}({this.props.group.subjectNum}道试题)
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              抽选
              {getFieldDecorator(this.props.group.subjectType + '_quesNum', {
                rules: [{ required: true, message: '请输入题目数量!' }],
              })(
                <InputNumber
                  key={this.props.group.subjectType + '_quesNum'}
                  min={0}
                  max={100}
                  onChange={this.onQuesNumInputChange}
                />
              )}
              道试题
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              每题
              {getFieldDecorator(this.props.group.subjectType + '_score', {
                rules: [{ required: true, message: '请输入题目分数!' }],
              })(
                <InputNumber
                  key={this.props.group.subjectType + '_score'}
                  min={0}
                  max={100}
                  onChange={this.onScoreInputChange}
                />
              )}
              分
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

const QuesExtractPanelForm = Form.create<IQuesExtractPanelProps>()(QuesExtractPanel)
export default QuesExtractPanelForm

import * as React from 'react'
import { Cascader } from 'antd'
import { getRegion } from './../../service/index'

export interface IRegionCascaderProps {
  maxLevel?: any
  regiProvinId?: any
  provinName?: any
  regiCityId?: any
  cityName?: any
  regiCountrId?: any
  countrName?: any
  global?: boolean
  onChange: (e: any) => void
}

type IRegionCascaderStates = {
  options: any
  defaultValue?: any
}

export default class RegionCascader extends React.Component<
  IRegionCascaderProps,
  IRegionCascaderStates
> {
  constructor(props: any) {
    super(props)
    this.state = {
      options: [],
    }
    this.onChange = this.onChange.bind(this)
    this.getRegion = this.getRegion.bind(this)
  }

  async componentDidMount() {
    this.initRegion()
    this.initDefaultValue()
  }

  componentDidUpdate(props: any) {
    if (
      props.regiProvinId != this.props.regiProvinId ||
      props.regiCityId != this.props.regiCityId ||
      props.regiCountrId != this.props.regiCountrId
    ) {
      this.initRegion()
      this.initDefaultValue()
    }
  }

  initDefaultValue = () => {
    let defaultValue = []
    if (this.props.regiProvinId) {
      defaultValue.push(this.props.regiProvinId)
    }
    if (this.props.regiCityId) {
      defaultValue.push(this.props.regiCityId)
    }
    if (this.props.regiCountrId) {
      defaultValue.push(this.props.regiCountrId)
    }
    if (defaultValue.length > 0) {
      this.setState({
        defaultValue: defaultValue,
      })
    }
  }

  initRegion = async () => {
    const options = await this.getRegion()
    setTimeout(() => {
      this.setState({
        options: options,
      })
      this.initDefaultValue()
    }, 0)
  }

  getRegion = async () => {
    const params = {}
    const res = await getRegion(params)
    if (res.success) {
      return res.data
    }
    return []
  }

  onChange = (value: any, selectedOptions: any) => {
    this.props.onChange(selectedOptions)
  }

  public render() {
    return (
      <Cascader
        defaultValue={this.state.defaultValue}
        fieldNames={{ label: 'name', value: 'id', children: 'childJson' }}
        options={this.state.options}
        onChange={this.onChange}
        expandTrigger="hover"
        changeOnSelect
      />
    )
  }
}

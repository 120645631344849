import React from 'react'
import { Table, Form, Button, Pagination, Input, message, Popconfirm } from 'antd'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import OrganAdd from './OrganAdd'
import { organList, organDel } from '../../service/compOrgan'
import { FormComponentProps } from 'antd/lib/form'
import { PERM, hasPermits } from '../../utils/permits'

const { Column } = Table
export interface IOrganProps extends FormComponentProps {}
interface IOrganStates {
  dataSource?: any
  editCompId: any
  pageSize: number
  page: number
  total: number
  checkedList: any
  loading: boolean
}

class Organ extends React.Component<IOrganProps, IOrganStates> {
  constructor(props: any) {
    super(props)
    this.getCompList = this.getCompList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.showTotal = this.showTotal.bind(this)
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this)
    this.onDeleteCancel = this.onDeleteCancel.bind(this)
    this.state = {
      editCompId: '',
      pageSize: 20,
      page: 1,
      dataSource: [],
      checkedList: [],
      total: 0,
      loading: false,
    }
  }
  componentDidMount() {
    this.getCompList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  getCompList = async () => {
    const values = this.props.form!.getFieldsValue()
    this.setState({
      loading: true,
    })
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      ...values,
    }
    const res = await organList(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  handleSearch = () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getCompList()
    }, 0)
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getCompList()
    }, 0)
  }

  onDeleteConfirm = async (e: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请先选择机构')
      return
    }
    const res = await organDel(this.state.checkedList)
    if (res.success) {
      message.success('删除成功')
      this.getCompList()
    } else {
      message.error('删除失败')
    }
  }

  onDeleteCancel = (e: any) => {}

  public render() {
    const { getFieldDecorator } = this.props.form!
    const rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
        setTimeout(() => {
          this.setState({
            checkedList: selectedRowKeys,
          })
        }, 0)
      },
      getCheckboxProps: (record: any) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }

    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="系统设置" second="机构管理" />
        <Main
          contentTitle="所有机构"
          centerContent={
            <div>
              <Form layout="inline" style={{ marginBottom: 16 }}>
                {hasPermits(PERM.setting_organ_func_add) && (
                  <Form.Item>
                    <OrganAdd
                      buttonTitle="新增机构"
                      icon="plus"
                      organId=""
                      onSuccess={() => {
                        this.onPageChange(1)
                      }}
                    />
                  </Form.Item>
                )}

                {hasPermits(PERM.setting_organ_func_del) && (
                  <Form.Item>
                    <Popconfirm
                      title="确定要删除机构吗"
                      onConfirm={this.onDeleteConfirm}
                      onCancel={this.onDeleteCancel}
                      okText="删除"
                      cancelText="取消"
                    >
                      <Button icon="delete" type="danger" ghost>
                        删除
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              </Form>
              {hasPermits(PERM.setting_organ_func_search) && (
                <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
                  <Form.Item label="机构名称">
                    {getFieldDecorator('organName')(<Input placeholder="机构名称" allowClear />)}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" icon="search" htmlType="submit">
                      搜索
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <Pagination
                current={this.state.page}
                size="small"
                pageSizeOptions={['10', '20', '50', '100', '200']}
                pageSize={this.state.pageSize}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    this.setState({
                      pageSize: size,
                    })
                    this.onPageChange(1)
                  }, 0)
                }}
              />
              <Table
                rowSelection={rowSelection}
                loading={this.state.loading}
                rowKey="organId"
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                dataSource={this.state.dataSource}
              >
                <Column
                  title="机构名称"
                  dataIndex="organName"
                  key="organName"
                  render={(organName: any) => organName}
                />

                <Column
                  title="父机构"
                  dataIndex="organPName"
                  key="organPName"
                  render={(organPName: any) => organPName}
                />
                <Column
                  title="地区"
                  width="200px"
                  dataIndex="provinName"
                  key="provinName"
                  render={(provinName: any, row: any) =>
                    `${row.provinName ? row.provinName + '/' : ''} ${
                      row.cityName ? row.cityName + '/' : ''
                    }${row.countrName ? row.countrName : ''}`
                  }
                />
                <Column
                  title="证书地区编号"
                  dataIndex="ccieNoAreaCode0"
                  key="ccieNoAreaCode0"
                  render={(ccieNoAreaCode0: any) => ccieNoAreaCode0}
                />
                <Column
                  title="证书编号后缀起始值"
                  dataIndex="ccieNoStartPostfix"
                  key="ccieNoStartPostfix"
                  render={(ccieNoStartPostfix: any) => ccieNoStartPostfix}
                />
                <Column
                  title="备注"
                  dataIndex="remark"
                  key="remark"
                  render={(remark: any) => remark}
                />

                {hasPermits(PERM.setting_organ_func_mod) && (
                  <Column
                    title="操作"
                    dataIndex="organId"
                    key="organId"
                    render={(organId: any) => (
                      <OrganAdd
                        buttonTitle="编辑"
                        icon="edit"
                        organId={organId}
                        onSuccess={() => {
                          this.onPageChange(1)
                        }}
                      />
                    )}
                  />
                )}
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}

export default Form.create<IOrganProps>()(Organ)

import umbrella from 'umbrella-storage'
export const PERM = {
  home_menu: 'home_menu',
  paper_menu: 'paper_menu',
  paper_menu_list: 'paper_menu_list',
  paper_func_add: 'paper_func_add',
  paper_func_search: 'paper_func_search',
  paper_func_del: 'paper_func_del',
  paper_func_mod: 'paper_func_mod',
  paper_func_modques: 'paper_func_modques',
  paper_func_setting: 'paper_func_setting',
  paper_func_distri: 'paper_func_distri',
  paper_func_canceldistri: 'paper_func_canceldistri',
  paper_func_preview: 'paper_func_preview',
  paper_func_groupmod: 'paper_func_groupmod',
  ques_menu: 'ques_menu',
  ques_menu_list: 'ques_menu_list',
  ques_func_add: 'ques_func_add',
  ques_func_search: 'ques_func_search',
  ques_func_mod: 'ques_func_mod',
  ques_func_del: 'ques_func_del',
  ques_func_groupmod: 'ques_func_groupmod',
  ques_func_imp: 'ques_func_imp',
  ques_func_exp: 'ques_func_exp',
  ques_func_top: 'ques_func_top',
  ques_func_setting: 'ques_func_setting',
  stu_menu: 'stu_menu',
  stu_menu_list: 'stu_menu_list',
  stu_func_add: 'stu_func_add',
  stu_func_search: 'stu_func_search',
  stu_func_mod: 'stu_func_mod',
  stu_func_certs: 'stu_func_certs',
  stu_func_del: 'stu_func_del',
  stu_func_groupmod: 'stu_func_groupmod',
  stu_func_audit: 'stu_func_audit',
  stu_func_imp: 'stu_func_imp',
  stu_func_exp: 'stu_func_exp',
  study_menu: 'study_menu',
  study_menu_course: 'study_menu_course',
  study_course_func_add: 'study_course_func_add',
  study_course_func_del: 'study_course_func_del',
  study_course_func_search: 'study_course_func_search',
  study_course_func_top: 'study_course_func_top',
  study_course_func_mod: 'study_course_func_mod',
  study_course_func_modcapter: 'study_course_func_modcapter',
  study_course_func_setting: 'study_course_func_setting',
  study_course_func_groupmod: 'study_course_func_groupmod',
  study_menu_courseware: 'study_menu_courseware',
  study_courseware_func_add: 'study_courseware_func_add',
  study_courseware_func_search: 'study_courseware_func_search',
  study_courseware_mod: 'study_courseware_func_mod',
  study_courseware_func_groupmod: 'study_courseware_func_groupmod',
  study_courseware_func_del: 'study_courseware_func_del',
  cert_menu: 'cert_menu',
  cert_menu_list: 'cert_menu_list',
  cert_func_search: 'cert_func_search',
  cert_func_exp: 'cert_func_exp',
  cert_func_imp: 'cert_func_imp',
  cert_func_del: 'cert_func_del',
  stat_menu: 'stat_menu',
  stat_menu_point: 'stat_menu_point',
  stat_point_search: 'stat_point_search',
  stat_menu_paper: 'stat_menu_paper',
  stat_paper_search: 'stat_paper_search',
  stat_menu_study: 'stat_menu_study',
  stat_menu_stu_test: 'stat_menu_stu_test',
  stat_menu_month_report: 'stat_menu_month_report',
  stat_menu_stu_test_search: 'stat_menu_stu_test_search',
  stat_study_search: 'stat_study_search',
  setting_menu: 'setting_menu',
  setting_menu_account: 'setting_menu_account',
  setting_account_func_add: 'setting_account_func_add',
  setting_account_func_search: 'setting_account_func_search',
  setting_account_func_mod: 'setting_account_func_mod',
  setting_account_func_del: 'setting_account_func_del',
  setting_menu_perm: 'setting_menu_perm',
  setting_perm_func_add: 'setting_perm_func_add',
  setting_perm_func_mod: 'setting_perm_func_mod',
  setting_perm_func_del: 'setting_perm_func_del',
  setting_perm_func_modcode: 'setting_perm_func_modcode',
  setting_perm_func_addfunc: 'setting_perm_func_addfunc',
  setting_perm_func_delfunc: 'setting_perm_func_delfunc',
  setting_menu_role: 'setting_menu_role',
  setting_role_func_add: 'setting_role_func_add',
  setting_role_func_mod: 'setting_role_func_mod',
  setting_role_func_del: 'setting_role_func_del',
  setting_role_func_search: 'setting_role_func_search',
  setting_menu_comp: 'setting_menu_comp',
  setting_comp_func_add: 'setting_comp_func_add',
  setting_comp_func_search: 'setting_comp_func_search',
  setting_comp_func_mod: 'setting_comp_func_mod',
  setting_comp_func_del: 'setting_comp_func_del',
  setting_menu_organ: 'setting_menu_organ',
  setting_organ_func_add: 'setting_organ_func_add',
  setting_organ_func_search: 'setting_organ_func_search',
  setting_organ_func_mod: 'setting_organ_func_mod',
  setting_organ_func_del: 'setting_organ_func_del',
  platform: 'platform_menu',
  platform_certs: 'platform_menu_certs',
  platform_notice: 'platform_menu_notice',
}

export function hasPermits(code: string) {
  let user = umbrella.getLocalStorage('user')
  if (user) {
    const perm = user.permJson.find((item: any) => item.showCode == code)
    return perm ? true : false
  } else {
    return false
  }
}

import * as React from 'react'
import { Form, Input, Button, Select, Modal, message, Radio } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { noticeSave, noticeOne } from './../../../service/notice'
import draftToHtml from 'draftjs-to-html'
import { studentList } from '../../../service/student'
import Wysiwyg from './../../widget/Wysiwyg'
import * as _ from 'lodash'

export type INoticeAddProps = {
  onSuccess: () => void
  topicId?: any
  buttonTitle: string
  icon: string
} & FormComponentProps

type INoticeAddStates = {
  editDialogVisible: boolean
  content: any
}

class NoticeAdd extends React.Component<INoticeAddProps, INoticeAddStates> {
  constructor(props: any) {
    super(props)
    this.handleOk = this.handleOk.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.onEditChange = this.onEditChange.bind(this)
    this.state = {
      editDialogVisible: false,
      content: '',
    }
  }

  /**
   * 文本编辑改变
   * @param content
   * @param data
   */
  onEditChange(result: any, data: any): void {
    const content = draftToHtml(result)
    this.setState({
      content: content,
    })
  }

  getNotice = async () => {
    if (this.props.topicId) {
      const res = await noticeOne(this.props.topicId)
      if (res.success) {
        const acc = res.data[0]
        if (acc) {
          setTimeout(() => {
            this.setState({
              ...acc,
              content: {
                type: 0,
                value: '',
                content: acc.content,
                initial: true,
              },
            })
          }, 0)
          this.props.form.setFieldsValue(_.pick(acc, ['title']))
        }
      }
    }
  }

  searchStudent = async (str: any) => {
    const params = {
      accountName: str,
    }
    const res = await studentList(params)
    if (res.success) {
      const result = res.data.map((item: any) => {
        return {
          text: item.accountName,
          value: item.topicId,
        }
      })
      return result
    }
    return []
  }

  handleOk = (e: any) => {
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
          content: this.state.content,
          topicType: 4,
          topicId: this.props.topicId,
        }
        const res = await noticeSave(params)
        if (res.success) {
          message.success('保存成功')
          this.setState({
            editDialogVisible: false,
          })
          if (this.props.onSuccess) {
            this.props.onSuccess()
          }
        } else {
          message.error('保存失败')
        }
      }
    })
  }

  handleCancel = (e: any) => {
    console.log(e)
    this.setState({
      editDialogVisible: false,
    })
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const form = this.props.form!
    return (
      <div>
        <Modal
          width="40%"
          title={this.props.topicId ? '编辑账户' : '添加账户'}
          visible={this.state.editDialogVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form layout="horizontal" style={{ marginBottom: 16 }}>
            <Form.Item label="标题">
              {getFieldDecorator('title', {
                rules: [{ required: true, message: '请输入标题!' }],
              })(<Input placeholder="标题" />)}
            </Form.Item>
            <Form.Item label="类型">
              {getFieldDecorator('mattStatus', {
                rules: [{ required: true, message: '请选择类型!' }],
                initialValue: 1,
              })(
                <Radio.Group>
                  <Radio value={1}>普通</Radio>
                  <Radio value={2}>紧急</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item label="内容" style={{ marginBottom: 16 }}>
              <Wysiwyg onChange={this.onEditChange} data={this.state.content} />
            </Form.Item>
          </Form>
        </Modal>
        <Button
          icon={this.props.icon}
          type="primary"
          onClick={() => {
            setTimeout(async () => {
              this.setState({
                editDialogVisible: true,
              })
              await this.getNotice()
            }, 0)
          }}
        >
          {this.props.buttonTitle}
        </Button>
      </div>
    )
  }
}

export default Form.create<INoticeAddProps>()(NoticeAdd)

import * as React from 'react'
import { Button, Modal, message, Input, Form, DatePicker, Descriptions } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { certsAward, genarateCertNo } from './../../service/certs'
import moment from 'moment'

export interface IAwardCertsProps extends FormComponentProps {
  student: any
  onSuccess: () => void
}

interface IAwardCertsStates {
  editDialogVisible: any
  ccieNo: any
}

class AwardCerts extends React.Component<IAwardCertsProps, IAwardCertsStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      editDialogVisible: false,
      ccieNo: '',
    }
  }
  async componentDidMount() {}

  handleOk = async () => {
    const values = this.props.form.getFieldsValue()
    if (!this.state.ccieNo) {
      message.error('请先获取证书编号')
      return
    }

    const dateFormat = 'YYYY-MM-DD'
    const params = {
      ccieNo: this.state.ccieNo,
      issueDate: moment(values.issueDate).format(dateFormat),
      validEndDate: moment(values.validEndDate).format(dateFormat),
      studentId: this.props.student.studentId,
    }
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        const res = await certsAward(params)
        if (res.success) {
          message.success('证书颁发成功')
          this.setState({
            editDialogVisible: false,
          })
          if (this.props.onSuccess) {
            this.props.onSuccess()
          }
        } else {
          message.error('证书颁发失败')
        }
      }
    })
  }

  handleCancel = () => {
    this.setState({
      editDialogVisible: false,
    })
  }

  getCertsNo = async (student: any) => {
    const params = { compId: student.compId }
    const res = await genarateCertNo(params)
    if (res.success) {
      const ccieNo = res.data[0].ccieNo
      this.setState({
        ccieNo: ccieNo,
      })
    }
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    let vehicleType = ''
    if (this.props.student.vehicleType == 1) {
      vehicleType = '搅拌车'
    } else if (this.props.student.vehicleType == 2) {
      vehicleType = '泵车'
    } else if (this.props.student.vehicleType == 3) {
      vehicleType = '水泥及砂浆运输车'
    } else if (this.props.student.vehicleType == 4) {
      vehicleType = '其他车'
    }

    return (
      <div>
        <Modal
          width="40%"
          title={'颁发证书-' + this.props.student.stuName}
          visible={this.state.editDialogVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Descriptions column={2}>
            <Descriptions.Item label="姓名">{this.props.student.stuName}</Descriptions.Item>
            <Descriptions.Item label="身份证号">{this.props.student.cardID}</Descriptions.Item>
            <Descriptions.Item label="籍贯">
              {this.props.student.provinName}/{this.props.student.cityName}
            </Descriptions.Item>
            <Descriptions.Item label="驾驶车型">{vehicleType}</Descriptions.Item>
            <Descriptions.Item label="企业名称">{this.props.student.compName}</Descriptions.Item>
            <Descriptions.Item label="证书编号" span={2}>
              {this.state.ccieNo}
              {!this.state.ccieNo && (
                <Button type="link" onClick={() => this.getCertsNo(this.props.student)}>
                  获取编号
                </Button>
              )}
            </Descriptions.Item>
          </Descriptions>
          <Form style={{ marginBottom: 16 }}>
            <Form.Item label="发证日期">
              {getFieldDecorator('issueDate', {
                rules: [{ required: true, message: '请输入发证日期!' }],
              })(<DatePicker />)}
            </Form.Item>
            <Form.Item label="证书有效期截止日期">
              {getFieldDecorator('validEndDate', {
                rules: [{ required: true, message: '请输入截止日期!' }],
              })(<DatePicker />)}
            </Form.Item>
          </Form>
        </Modal>
        <Button
          className="ml-s"
          type="primary"
          ghost
          onClick={() => {
            this.setState({
              editDialogVisible: true,
            })
          }}
        >
          颁发证书
        </Button>
      </div>
    )
  }
}

export default Form.create<IAwardCertsProps>()(AwardCerts)

import { post } from './tools'
import * as config from './config'

export const statPaperList = async (params: any) => {
  return await post({
    url: config.STATS_PAPER,
    data: params,
  })
}

export const statPaperStuTesting = async (params: any) => {
  return await post({
    url: config.STATS_PAPERSTU_TESTING,
    data: params,
  })
}

export const statPaperStuUntest = async (params: any) => {
  return await post({
    url: config.STATS_PAPERSTU_UNTEST,
    data: params,
  })
}

export const statPaperSendMsg = async (params: any) => {
  return await post({
    url: config.STATS_PAPERSTU_SEND,
    data: params,
  })
}

export const statPaperStuTested = async (params: any) => {
  return await post({
    url: config.STATS_PAPERSTU_TESTED,
    data: params,
  })
}

export const statPaperStu = async (params: any) => {
  return await post({
    url: config.STATS_PAPERSTU,
    data: params,
  })
}

export const statCourse = async (params: any) => {
  return await post({
    url: config.STATS_COURSE,
    data: params,
  })
}

export const statCourseStu = async (params: any) => {
  return await post({
    url: config.STATS_COURSE_STU,
    data: params,
  })
}

export const statPoints = async (params: any) => {
  return await post({
    url: config.STATS_POINTS,
    data: params,
  })
}

export const statStuTest = async (params: any) => {
  return await post({
    url: config.STATS_STU_TEST,
    data: params,
  })
}

export const statStuTestDetail = async (params: any) => {
  return await post({
    url: config.STATS_STU_TEST_DEDAIL,
    data: params,
  })
}

export const statMonthReportOrgan = async (params: any) => {
  return await post({
    url: config.STATS_MONTH_REPORT_ORGAN,
    data: params,
  })
}
export const statMonthReportComp = async (params: any) => {
  return await post({
    url: config.STATS_MONTH_REPORT_COMP,
    data: params,
  })
}

import { post } from './tools'
import * as config from './config'

export const studentList = async (params: any) => {
  return await post({
    url: config.STU_LIST,
    data: params,
  })
}
export const studentOne = async (id: any) => {
  return await post({
    url: config.STU_ONE,
    data: { studentId: id },
  })
}
export const studentSave = async (params: any) => {
  return await post({
    url: config.STU_SAVE,
    data: params,
  })
}
export const studentDel = async (ids: any[]) => {
  return await post({
    url: config.STU_DEL,
    data: { studentIds: ids.join(',') },
  })
}

export const studentAudit = async (params: any) => {
  return await post({
    url: config.STU_AUDIT,
    data: params,
  })
}

export const studentImp = async (params: any) => {
  return await post({
    url: config.STU_IMP,
    data: {
      fileJson: JSON.stringify(params),
    },
  })
}

/**
 * Created by hao.cheng on 2017/5/3.
 */
import React from 'react'
import BreadcrumbCustom from '../BreadcrumbCustom'

class Dashboard extends React.Component {
  render() {
    return (
      <div className="gutter-example button-demo">
        <BreadcrumbCustom />
      </div>
    )
  }
}

export default Dashboard

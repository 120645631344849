import * as React from 'react'
import { Form, Button, Input, Card, Modal, message, Spin } from 'antd'
import Uploader from './../widget/Uploader'
import GroupSel from '../widget/GroupSel'
import { GroupType } from './../../utils/index'
import { FormComponentProps } from 'antd/lib/form'
import { testSave, testOne } from './../../service/test'

type ITestPaperInfoProps = {
  onNextStep?: (e: any) => void
  paperId?: any
  buttonDisabled?: boolean
  modal: boolean
  handlePaperInfoFinish?: () => void
} & FormComponentProps
type ITestPaperInfoStates = {
  groupId: any
  title: any
  abstract: any
  isSetFile: number
  coverDirectURL: any
  groupName: any
  editDialogVisible: boolean
  loading: boolean
}

class TestPaperInfo extends React.Component<ITestPaperInfoProps, ITestPaperInfoStates> {
  constructor(props: any) {
    super(props)
    this.onTestPaperGroupChange = this.onTestPaperGroupChange.bind(this)
    this.getTestPaper = this.getTestPaper.bind(this)
    this.state = {
      groupId: '',
      groupName: '',
      title: '',
      abstract: '',
      isSetFile: 0,
      coverDirectURL: '',
      editDialogVisible: false,
      loading: false,
    }
  }

  onTestPaperGroupChange = (e: any) => {
    this.setState({ groupId: e.groupId })
  }

  getTestPaper = async () => {
    if (this.props.paperId) {
      setTimeout(() => {
        this.setState({
          editDialogVisible: true,
          loading: true,
        })
      }, 0)
      const res = await testOne(this.props.paperId)
      setTimeout(() => {
        this.setState({
          loading: false,
        })
      }, 0)
      if (res.success) {
        const paper = res.data[0]
        if (paper) {
          const group = paper.groupJson[0]
          setTimeout(() => {
            this.setState({
              ...paper,
              groupId: group ? group.groupId : '',
              groupName: group ? group.groupName : '',
            })
          }, 0)
          this.props.form.setFieldsValue({
            title: paper.title,
            depict: paper.depict,
            groupId: paper.groupId,
          })
        }
      }
    }
  }

  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        })
        const params = {
          paperId: this.props.paperId,
          paperType: 1,
          title: values.title,
          groupId: this.state.groupId,
          depict: values.depict,
          fileJson: JSON.stringify(values.fileList),
          isSetFile: this.state.isSetFile,
        }
        const res = await testSave(params)
        if (res.success && this.props.onNextStep) {
          const paperId = res.data[0].paperId
          this.props.onNextStep({ paperId: paperId })
        }
        this.setState({
          loading: false,
        })
        if (res.success) {
          message.success('保存成功')
          this.setState({
            editDialogVisible: false,
          })
          if (this.props.handlePaperInfoFinish) {
            this.props.handlePaperInfoFinish()
          }
        } else {
          message.success(res.msg || '保存失败')
        }
      }
    })
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const form = this.props.form!
    const body = (
      <Card>
        <Spin spinning={this.state.loading} delay={500}>
          <Form layout="horizontal" style={{ marginBottom: 16 }}>
            <Form.Item label="试卷标题">
              {getFieldDecorator('title', {
                rules: [{ required: true, message: '请输入标题!' }],
              })(<Input placeholder="请填写25个字以内的标题" />)}
            </Form.Item>
            <Form.Item>
              <GroupSel
                type={GroupType.TEST}
                title="试卷类别"
                onChange={(e: any) => {
                  this.setState({ groupId: e.groupId, groupName: e.groupName })
                  form.setFieldsValue({
                    groupId: e ? e.groupId : '',
                  })
                }}
                groupId={this.state.groupId}
                groupName={this.state.groupName}
              />
            </Form.Item>
            <Form.Item label="试卷封面">
              {getFieldDecorator('fileList', {
                rules: [{ required: false, message: '请上传课程封面!' }],
                valuePropName: 'fileList',
                normalize(e: any) {
                  if (Array.isArray(e)) return e
                  return e && e.fileList
                },
              })(
                <Uploader
                  defaultImg={this.state.coverDirectURL}
                  handleUploadFileChange={(fileList: any) => {
                    form.setFieldsValue({
                      fileList: fileList,
                    })
                    this.setState({
                      isSetFile: fileList.length > 0 ? 1 : 0,
                    })
                  }}
                />
              )}
            </Form.Item>
            <Form.Item label="试卷描述">
              {getFieldDecorator('depict', {
                rules: [{ required: true, message: '请输入试卷描述!' }],
              })(<Input.TextArea rows={4} placeholder="试卷描述" />)}
            </Form.Item>
            <Form.Item>{getFieldDecorator('paperId', {})(<Input hidden />)}</Form.Item>
            <Form.Item>
              {!this.props.paperId && (
                <Button className="mt-m" type="primary" onClick={this.handleSubmit} ghost>
                  下一步
                </Button>
              )}
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    )

    return (
      <div>
        {this.props.modal && (
          <div>
            <Modal
              width="60%"
              title="编辑试卷信息"
              forceRender={true}
              visible={this.state.editDialogVisible}
              onOk={this.handleSubmit}
              onCancel={() => {
                this.setState({
                  editDialogVisible: false,
                })
              }}
            >
              {body}
            </Modal>
            <Button disabled={this.props.buttonDisabled} type="primary" onClick={this.getTestPaper}>
              编辑试卷信息
            </Button>
          </div>
        )}
        {!this.props.modal && body}
      </div>
    )
  }
}

export default Form.create<ITestPaperInfoProps>()(TestPaperInfo)

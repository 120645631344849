import * as React from 'react'
import { Table, Tag, Form, Select, Input, Button } from 'antd'
import { QuesType, quesTypeArr, GroupType } from '../../utils/index'
import { quesList } from './../../service/ques'
import { FormComponentProps } from 'antd/lib/form'
import { fetchGroupList } from './../../service/index'
const { Column } = Table
const { Option } = Select

export interface ITestQuesSelectManualProps extends FormComponentProps {
  handleChange: (e: any) => void
  defaultSelIds: any
}
type ITestQuesSelectManualStates = {
  dataSource: any
  selectedRows: any[]
  selectedRowKeys: any[]
  groupId: any
  total: number
  page: number
  pageSize: number
  groupList: any[]
  selectGroups: any[]
}

class TestQuesSelectManual extends React.Component<
  ITestQuesSelectManualProps,
  ITestQuesSelectManualStates
> {
  constructor(props: any) {
    super(props)
    this.onPageChange = this.onPageChange.bind(this)
    this.getQuesList = this.getQuesList.bind(this)
    this.showTotal = this.showTotal.bind(this)
    this.state = {
      dataSource: [],
      selectedRows: [],
      selectedRowKeys: [],
      total: 0,
      groupId: '',
      page: 1,
      pageSize: 10,
      groupList: [],
      selectGroups: [],
    }
  }

  componentWillReceiveProps(props: any) {}

  componentDidMount() {
    this.getQuesList()
    this.getGroupList()
    this.setState({
      selectedRowKeys: this.props.defaultSelIds,
    })
  }

  getQuesList = async () => {
    const values = this.props.form!.getFieldsValue()
    const params = {
      page: this.state.page,
      rows: this.state.pageSize,
      ...values,
    }
    const res = await quesList(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
    })
  }

  getGroupList = async () => {
    const params = {
      groupType: GroupType.QUES,
    }
    const res = await fetchGroupList(params)
    if (res.success) {
      this.setState({ groupList: res.data })
    }
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getQuesList()
    }, 0)
  }

  Row = (subjectId: any, index: any) => {
    const row = this.state.dataSource[index]
    let subjectTypeStr: any = ''
    switch (row.subjectType) {
      case QuesType.SINGLE_SEL:
        subjectTypeStr = '单选题'
        break
      case QuesType.MULTI_SEL:
        subjectTypeStr = '多选题'
        break
      case QuesType.TRUE_FLS:
        subjectTypeStr = '判断题'
        break
    }

    return (
      <div className="c-text-gray">
        <div className="flex-h y-center">
          <Tag color="blue">
            {(this.state.page - 1) * this.state.pageSize + index + 1}/{this.state.total}
          </Tag>
          <Tag color="blue">{subjectTypeStr}</Tag>
          <span className="html-reset" dangerouslySetInnerHTML={{ __html: row.title }}></span>
        </div>
        <div>
          <span>分类：{row.groupNames}</span>|<span>作者：{row.loginName}</span>
        </div>
      </div>
    )
  }

  onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
    selectedRows.forEach((item: any) => {
      if (selectedRowKeys.includes(item.subjectId)) {
        this.state.selectGroups.push(item)
      } else {
        const idx = this.state.selectGroups.findIndex((e) => e.subjectId == item.subjectId)
        if (idx != -1) {
          this.state.selectGroups.splice(idx, 1)
        }
      }
    })
    console.log(this.state.selectGroups)
    const selectedRowsResult = this.state.selectGroups.map((item: any) => {
      return {
        subjectId: item.subjectId,
        solScore: item.solScore,
      }
    })
    setTimeout(() => {
      this.setState({ selectedRows: selectedRowsResult, selectedRowKeys: selectedRowKeys })
      this.props.handleChange(selectedRowsResult)
    }, 0)
  }

  handleSearch = (e: any) => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getQuesList()
    }, 0)
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: true,
    }

    return (
      <div>
        <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
          <Form.Item label="标题">
            {getFieldDecorator('title')(<Input placeholder="标题" allowClear />)}
          </Form.Item>
          <Form.Item label="题型">
            {getFieldDecorator('subjectType')(
              <Select style={{ width: 120 }} placeholder="题型" allowClear>
                {quesTypeArr.map((ques: any) => {
                  return (
                    <Option key={ques.key} value={ques.key}>
                      {ques.name}
                    </Option>
                  )
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="试题分类">
            {getFieldDecorator('groupId')(
              <Select style={{ width: 200 }} placeholder="试题分类" allowClear>
                {this.state.groupList &&
                  this.state.groupList.map((crs: any) => (
                    <Option key={crs.groupId} value={crs.groupId}>
                      {crs.groupName}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" icon="search" htmlType="submit">
              搜索
            </Button>
          </Form.Item>
        </Form>
        <Table
          dataSource={this.state.dataSource}
          rowSelection={rowSelection}
          rowKey="subjectId"
          showHeader={false}
          pagination={{
            onChange: this.onPageChange,
            pageSize: this.state.pageSize,
            current: this.state.page,
            size: 'small',
            total: this.state.total,
            showTotal: this.showTotal,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
          }}
        >
          <Column
            dataIndex="subjectId"
            key="subjectId"
            render={(subjectId, record, index) => this.Row(subjectId, index)}
          />
        </Table>
      </div>
    )
  }
}
export default Form.create<ITestQuesSelectManualProps>()(TestQuesSelectManual)

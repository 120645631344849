import React from 'react'
import { Table, Form, Button, Pagination, Input, DatePicker } from 'antd'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import GroupSel from './../widget/GroupSel'
import { statPoints } from './../../service/stats'
import { FormComponentProps } from 'antd/lib/form'
import { GroupType } from './../../utils/index'
import moment from 'moment'
import RegionCascader from './../widget/RegionCascader'
import { PERM, hasPermits } from './../../utils/permits'

const { Column } = Table
const { RangePicker } = DatePicker

export interface IPointsStatProps extends FormComponentProps {}
interface IPointsStatStates {
  dataSource?: any
  editPointsStatId: any
  pageSize: number
  page: number
  total: number
  loading: boolean
  groupId: string
  groupName: string
  region: any
  regiProvinId: any
  regiCityId: any
  provinName: any
  cityName: any
  regiCountrId: any
  countrName: any
}

class PointsStat extends React.Component<IPointsStatProps, IPointsStatStates> {
  constructor(props: any) {
    super(props)
    this.getPointsStatList = this.getPointsStatList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.showTotal = this.showTotal.bind(this)
    this.state = {
      editPointsStatId: '',
      pageSize: 20,
      page: 1,
      dataSource: [],
      total: 0,
      loading: false,
      groupId: '',
      groupName: '',
      region: {},
      regiProvinId: '',
      regiCityId: '',
      provinName: '',
      cityName: '',
      regiCountrId: '',
      countrName: '',
    }
  }
  componentDidMount() {
    this.getPointsStatList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  handleSearch = async () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getPointsStatList()
    }, 0)
  }

  onChangeGroup = async (group: any) => {
    this.setState({
      groupId: group ? group.groupId : '',
      groupName: group ? group.groupName : '',
    })
  }

  getPointsStatList = async () => {
    this.setState({
      loading: true,
    })
    const values = this.props.form!.getFieldsValue()
    const dateFormat = 'YYYY-MM-DD'
    values.startDate =
      values.date && values.date.length > 0 ? moment(values.date[0]).format(dateFormat) : ''
    values.endDate =
      values.date && values.date.length > 0 ? moment(values.date[1]).format(dateFormat) : ''

    const valuesData = { ...values, date: null }
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      groupId: this.state.groupId,
      ...valuesData,
      regiProvinId: this.state.regiProvinId,
      regiCityId: this.state.regiCityId,
      regiCountrId: this.state.regiCountrId,
      region: [],
    }
    const res = await statPoints(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getPointsStatList()
    }, 0)
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="统计管理" second="积分统计" />
        <Main
          contentTitle="积分统计"
          centerContent={
            <div>
              {hasPermits(PERM.stat_point_search) && (
                <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
                  <Form.Item>
                    <GroupSel
                      title="分组"
                      buttonTitle={`分组（${this.state.groupName}）`}
                      type={GroupType.STU}
                      hideInput
                      onChange={this.onChangeGroup}
                    />
                  </Form.Item>
                  <Form.Item label="所属区域">
                    {getFieldDecorator('region')(
                      <RegionCascader
                        maxLevel={3}
                        regiProvinId={this.state.regiProvinId}
                        provinName={this.state.provinName}
                        regiCityId={this.state.regiCityId}
                        cityName={this.state.cityName}
                        regiCountrId={this.state.regiCountrId}
                        countrName={this.state.countrName}
                        onChange={(e: any) => {
                          this.setState({
                            regiProvinId: '',
                            provinName: '',
                            regiCityId: '',
                            cityName: '',
                            regiCountrId: '',
                            countrName: '',
                          })
                          if (e.length > 0) {
                            e.forEach((element: any) => {
                              setTimeout(() => {
                                if (element.level == 1) {
                                  this.setState({
                                    regiProvinId: element.id,
                                    provinName: element.name,
                                  })
                                } else if (element.level == 2) {
                                  this.setState({
                                    regiCityId: element.id,
                                    cityName: element.name,
                                  })
                                } else if (element.level == 3) {
                                  this.setState({
                                    regiCountrId: element.id,
                                    countrName: element.name,
                                  })
                                }
                              }, 0)
                            })
                          }
                        }}
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="姓名">
                    {getFieldDecorator('stuName')(<Input placeholder="姓名" allowClear />)}
                  </Form.Item>
                  <Form.Item label="企业名称">
                    {getFieldDecorator('compName')(<Input placeholder="企业名称" allowClear />)}
                  </Form.Item>
                  <Form.Item label="时间">{getFieldDecorator('date')(<RangePicker />)}</Form.Item>
                  <Form.Item>
                    <Button type="primary" icon="search" htmlType="submit">
                      搜索
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <Pagination
                current={this.state.page}
                size="small"
                pageSizeOptions={['10', '20', '50', '100', '200']}
                pageSize={this.state.pageSize}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    this.setState({
                      pageSize: size,
                    })
                    this.onPageChange(1)
                  }, 0)
                }}
              />
              <Table
                rowKey="studentId"
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                loading={this.state.loading}
                dataSource={this.state.dataSource}
              >
                <Column
                  title="姓名"
                  dataIndex="stuName"
                  key="stuName"
                  render={(stuName: any) => stuName}
                />
                <Column
                  title="性别"
                  dataIndex="gender"
                  key="gender"
                  render={(gender: any) => gender}
                />
                <Column
                  title="手机号"
                  dataIndex="phone"
                  key="phone"
                  render={(phone: any) => phone}
                />
                <Column
                  title="分组"
                  dataIndex="groupNames"
                  key="groupNames"
                  render={(groupNames: any) => groupNames}
                />
                <Column
                  title="所在区域"
                  dataIndex="provinName"
                  key="provinName"
                  render={(provinName: any, item: any) =>
                    `${provinName ? provinName + (item.cityName ? '/' : '') : ''}${
                      item.cityName ? item.cityName + (item.countrName ? '/' : '') : ''
                    }${item.countrName ? item.countrName : ''}`
                  }
                />
                <Column
                  title="企业名称"
                  dataIndex="compName"
                  key="compName"
                  render={(compName: any) => compName}
                />
                <Column
                  title="驾驶车型"
                  dataIndex="vehicleType"
                  key="vehicleType"
                  render={(vehicleType: any) => {
                    let str = ''
                    if (vehicleType == 1) {
                      str = '搅拌车'
                    } else if (vehicleType == 2) {
                      str = '泵车'
                    } else if (vehicleType == 3) {
                      str = '水泥及砂浆运输车'
                    } else if (vehicleType == 4) {
                      str = '其他车'
                    }
                    return str
                  }}
                />
                <Column
                  title="总积分"
                  dataIndex="score"
                  key="score"
                  render={(score: any) => score}
                />
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}
export default Form.create<IPointsStatProps>()(PointsStat)

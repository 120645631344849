/**
 * Created by hao.cheng on 2017/4/28.
 */
// 获取url的参数
export const queryString = () => {
  let _queryString: { [key: string]: any } = {}
  const _query = window.location.search.substr(1)
  const _vars = _query.split('&')
  _vars.forEach((v, i) => {
    const _pair = v.split('=')
    if (!_queryString.hasOwnProperty(_pair[0])) {
      _queryString[_pair[0]] = decodeURIComponent(_pair[1])
    } else if (typeof _queryString[_pair[0]] === 'string') {
      const _arr = [_queryString[_pair[0]], decodeURIComponent(_pair[1])]
      _queryString[_pair[0]] = _arr
    } else {
      _queryString[_pair[0]].push(decodeURIComponent(_pair[1]))
    }
  })
  return _queryString
}

export const AlphaOptions = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

/**
 * 校验是否登录
 * @param permits
 */
export const checkLogin = (permits: any): boolean =>
  (process.env.NODE_ENV === 'production' && !!permits) ||
  (process.env.NODE_ENV === 'development' && !!permits)

export enum GroupType {
  STU = 1, // 学员
  COURSE_WARE, // 课件
  COURSE, // 课程
  QUES, // 问题
  TEST, // 试卷
  PASS_TEST, // 闯关
}

/**
 * 题型
 */
export enum QuesType {
  SINGLE_SEL = 1,
  MULTI_SEL,
  TRUE_FLS,
  COMPLE,
  QUES,
}

export const quesTypeArr = [
  {
    key: QuesType.SINGLE_SEL,
    name: '单选题',
  },
  {
    key: QuesType.MULTI_SEL,
    name: '多选题',
  },
  {
    key: QuesType.TRUE_FLS,
    name: '判断题',
  },
  {
    key: QuesType.COMPLE,
    name: '填空题',
  },
  {
    key: QuesType.QUES,
    name: '问答题',
  },
]

export const quesTypeName = (type: QuesType): any => {
  const ques = quesTypeArr.find((item: any) => item.key == type)
  return ques ? ques.name : ''
}

export function parseTime(time: any, cFormat: any) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj: any[string] = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result: any, key: any) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * Created by hao.cheng on 2017/4/16.
 */
import React from 'react'
import { Form, Icon, Input, Button } from 'antd'
import { PwaInstaller } from '../widget'
import { connectAlita } from 'redux-alita'
import { RouteComponentProps } from 'react-router'
import { FormProps } from 'antd/lib/form'
import umbrella from 'umbrella-storage'
import { message } from 'antd'
import { authLogin, validCodeBase64 } from '../../service'

const FormItem = Form.Item
type LoginProps = {
  setAlitaState: (param: any) => void
  auth: any
} & RouteComponentProps &
  FormProps

type LoginStates = {
  validImg: any
  user: any
}

class Login extends React.Component<LoginProps, LoginStates> {
  constructor(props: any) {
    super(props)
    this.getValidCode = this.getValidCode.bind(this)
    this.state = {
      validImg: '',
      user: {},
    }
  }
  componentDidMount() {
    const { setAlitaState } = this.props
    setAlitaState({ stateName: 'auth', data: null })
    this.getValidCode()
  }
  componentDidUpdate(prevProps: LoginProps) {
    // React 16.3+弃用componentWillReceiveProps
    const { auth: nextAuth = {}, history } = this.props
    // const { history } = this.props;
    if (nextAuth.data && nextAuth.data.uid) {
      // 判断是否登陆
      umbrella.setLocalStorage('user', nextAuth.data)
      history.push('/')
    }
  }
  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        const { setAlitaState } = this.props
        const params = {
          ...values,
          isValidateCode: 1,
          exterIP: '',
          lngLat: '',
        }
        const res = await authLogin(params)
        if (res.success) {
          const user = res.data[0]
          this.setState({
            user: user,
          })
          umbrella.setLocalStorage('user', user)
          umbrella.setLocalStorage('token', user.Authorization || '')
          user && setAlitaState({ stateName: 'user', data: user })
          this.props.history.push('/')
        } else {
          message.warn(res.msg)
        }
      }
    })
  }

  getValidCode = async () => {
    const res = await validCodeBase64()
    this.setState({
      validImg: res,
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form!
    return (
      <div className="login">
        <div className="login-form">
          <div className="login-logo">
            <span>网泽培优</span>
            <PwaInstaller />
          </div>
          <Form onSubmit={this.handleSubmit} style={{ maxWidth: '300px' }}>
            <FormItem>
              {getFieldDecorator('loginName', {
                rules: [{ required: true, message: '请输入用户名!' }],
              })(
                <Input
                  prefix={<Icon type="user" style={{ fontSize: 13 }} />}
                  placeholder="用户名"
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('pwd', {
                rules: [{ required: true, message: '请输入密码!' }],
              })(
                <Input
                  prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                  type="password"
                  placeholder="密码"
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('validateCode', {
                rules: [{ required: true, message: '请输入验证码!' }],
              })(
                <div className="flex-h">
                  <Input
                    prefix={<Icon type="check" style={{ fontSize: 13 }} />}
                    placeholder="验证码"
                  />
                  <img height="28px" src={this.state.validImg} alt="" onClick={this.getValidCode} />
                </div>
              )}
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ width: '100%' }}
              >
                登录
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    )
  }
}

export default connectAlita(['auth'])(Form.create()(Login))

import * as React from 'react'
import { Form, Button, Radio, Checkbox, Input, InputNumber, message, Modal, Spin } from 'antd'
import Wysiwyg from './../widget/Wysiwyg'
import { AlphaOptions } from './../../utils/index'
import GroupSel from './../widget/GroupSel'
import { GroupType, QuesType } from './../../utils/index'
import { quesOne, quesSave } from './../../service/ques'
import { FormComponentProps } from 'antd/lib/form'
import draftToHtml from 'draftjs-to-html'
const CheckboxGroup = Checkbox.Group

export interface IQuesAddProps extends FormComponentProps {
  subjectId?: any
  buttonTitle: string
  icon: string
  handleOk: (e: any) => void
}
export interface IQuesAddStates {
  subjectType: QuesType
  answer: any
  multiAnswer: any[]
  solScore: number
  group: any
  options: any
  analysis: any
  titleData: any
  editDialogVisible: boolean
  loading: boolean
}

/**
 * 编辑器类型
 */
enum EditorType {
  QUES = 1, // 题干
  OPTION, // 选项
  ANLYSIS, // 答案解析
  GROUP,
}

class QuesAdd extends React.Component<IQuesAddProps, IQuesAddStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      subjectType: QuesType.SINGLE_SEL,
      editDialogVisible: false,
      loading: false,
      options: [
        {
          type: EditorType.OPTION,
          value: 1,
          content: '',
          initial: true,
        },
        {
          type: EditorType.OPTION,
          value: 2,
          content: '',
          initial: true,
        },
        {
          type: EditorType.OPTION,
          value: 3,
          content: '',
          initial: true,
        },
        {
          type: EditorType.OPTION,
          value: 4,
          content: '',
          initial: true,
        },
      ],
      analysis: {
        type: EditorType.ANLYSIS,
        value: '',
        content: '',
        initial: true,
      },
      answer: 1,
      multiAnswer: [],
      solScore: 0,
      group: {},
      titleData: {
        type: EditorType.QUES,
        value: '',
        content: '',
        initial: true,
      },
    }

    this.onEditChange = this.onEditChange.bind(this)
    this.onAddOption = this.onAddOption.bind(this)
    this.onDelOption = this.onDelOption.bind(this)
    this.onSingleAnswerChange = this.onSingleAnswerChange.bind(this)
    this.onMultiAnswerChange = this.onMultiAnswerChange.bind(this)
    this.onTrueFlsAnswerChange = this.onTrueFlsAnswerChange.bind(this)
    this.onScoreChange = this.onScoreChange.bind(this)
    this.onSaveQues = this.onSaveQues.bind(this)
  }

  componentDidMount() {
    this.getSubject()
  }

  /**
   * 题型改变
   * @param e
   */
  handleQuesTypeChange = (e: any) => {
    this.setState({ subjectType: e.target.value })
  }

  getSubject = async () => {
    if (this.props.subjectId) {
      this.setState({
        loading: true,
      })
      const res = await quesOne(this.props.subjectId)
      if (res.success) {
        const ques = res.data[0]
        if (ques) {
          const titleData = this.state.titleData
          titleData.content = ques.title
          titleData.initial = true

          const analysis = this.state.analysis
          analysis.content = ques.analysis
          analysis.initial = true
          const options: any[] = []
          if (ques.option0) {
            options.push({
              type: EditorType.OPTION,
              value: 1,
              content: ques.option0,
              initial: true,
            })
          }
          if (ques.option1) {
            options.push({
              type: EditorType.OPTION,
              value: 2,
              content: ques.option1,
              initial: true,
            })
          }
          if (ques.option2) {
            options.push({
              type: EditorType.OPTION,
              value: 3,
              content: ques.option2,
              initial: true,
            })
          }
          if (ques.option3) {
            options.push({
              type: EditorType.OPTION,
              value: 4,
              content: ques.option3,
              initial: true,
            })
          }
          if (ques.option4) {
            options.push({
              type: EditorType.OPTION,
              value: 5,
              content: ques.option4,
              initial: true,
            })
          }
          if (ques.option5) {
            options.push({
              type: EditorType.OPTION,
              value: 6,
              content: ques.option5,
              initial: true,
            })
          }

          let multiAnswer: any[] = []
          if (ques.subjectType == QuesType.MULTI_SEL) {
            const ans = ques.correct.split(',')
            multiAnswer = ans.map((item: any) => parseInt(item))
          }

          const group = ques.groupJson ? ques.groupJson[0] : {}
          setTimeout(() => {
            this.setState({
              subjectType: ques.subjectType,
              titleData: titleData,
              options: options,
              solScore: ques.solScore,
              analysis: analysis,
              multiAnswer: multiAnswer,
              answer: parseInt(ques.correct),
              group: group,
            })
          }, 0)
        }
      }
      this.setState({
        loading: false,
      })
    }
  }

  /**
   * 文本编辑改变
   * @param content
   * @param data
   */
  onEditChange(result: any, data: any): void {
    const type = data.type
    const content = draftToHtml(result)
    if (type == EditorType.GROUP) {
      this.setState({
        group: result,
      })
    } else {
      if (type == EditorType.QUES) {
        const titleData = this.state.titleData
        titleData.content = content
        titleData.initial = false
        this.setState({ titleData: titleData })
      } else if (type == EditorType.OPTION) {
        const index = parseInt(data.value) - 1
        const options = this.state.options
        options[index].content = content
        options[index].initial = false
        setTimeout(() => {
          this.setState({
            options: options,
          })
        }, 0)
      } else if (type == EditorType.ANLYSIS) {
        this.setState({
          analysis: {
            type: EditorType.ANLYSIS,
            value: '',
            content: content,
            initial: false,
          },
        })
      }
    }
  }
  /**
   * 判断题列表
   * @returns
   */
  trueFlsList() {
    const { getFieldDecorator } = this.props.form!
    const option0 = this.state.options[0].content
    const option1 = this.state.options[1].content
    return (
      <div>
        <Form.Item label="真项" key={1}>
          {getFieldDecorator('option0', {
            rules: [{ required: true, message: '请输入选项内容!' }],
            initialValue: option0,
          })(
            <Input
              placeholder="选项内容"
              onChange={(e) => {
                this.state.options[0].content = e.currentTarget.value
              }}
            />
          )}
        </Form.Item>
        <Form.Item label="假项" key={0}>
          {getFieldDecorator('option1', {
            rules: [{ required: true, message: '请输入选项内容!' }],
            initialValue: option1,
          })(
            <Input
              placeholder="选项内容"
              onChange={(e) => {
                this.state.options[1].content = e.currentTarget.value
              }}
            />
          )}
        </Form.Item>
      </div>
    )
  }
  /**
   * 选项列表
   * @returns
   */
  optionList() {
    const formList = this.state.options.map((option: any, index: number) => (
      <Form.Item label={'(' + AlphaOptions[index] + ')'} key={index}>
        <div className="y-center">
          <Wysiwyg onChange={this.onEditChange} data={option} />
          <Button
            className="ml-m"
            type="primary"
            size="small"
            icon="delete"
            key={index}
            onClick={() => this.onDelOption(index)}
          />
        </div>
      </Form.Item>
    ))
    return <div>{formList}</div>
  }
  /**
   * 单选列表
   */
  singleAnswerList(): JSX.Element {
    const answerList = this.state.options.map((option: any, index: number) => (
      <Radio value={index + 1} key={index}>
        {AlphaOptions[index]}
      </Radio>
    ))
    return (
      <Radio.Group onChange={this.onSingleAnswerChange} value={this.state.answer}>
        {answerList}
      </Radio.Group>
    )
  }
  /**
   * 多选列表
   */
  multiAnswerList(): JSX.Element {
    const options = this.state.options.map((item: any, index: number) => {
      return {
        label: AlphaOptions[index],
        value: index + 1,
      }
    })

    return (
      <CheckboxGroup
        options={options}
        value={this.state.multiAnswer}
        onChange={this.onMultiAnswerChange}
      />
    )
  }
  /**
   * 判断题列表
   */
  trueFlsAnswerList(): JSX.Element {
    const answerList = this.state.options.map((option: any, index: number) => (
      <Radio value={index}>{AlphaOptions[index]}</Radio>
    ))

    return (
      <Radio.Group onChange={this.onTrueFlsAnswerChange} value={this.state.answer}>
        <Radio value={1}>对</Radio>
        <Radio value={2}>错</Radio>
      </Radio.Group>
    )
  }
  /**
   * 添加选项
   * @param e
   */
  onAddOption(e: any) {
    const options = this.state.options
    if (options.length >= AlphaOptions.length) {
      return
    }
    const option = AlphaOptions[options.length]
    options.push({ option: option, content: '' })
    this.setState({
      options: options,
    })
  }
  /**
   * 删除选项
   * @param index
   */
  onDelOption(index: number) {
    const options = this.state.options
    options.splice(index, 1)
    this.setState({
      options: options,
    })
  }
  /**
   * 单选答案改变
   * @param e
   */
  onSingleAnswerChange(e: any) {
    const value = e.target.value
    this.setState({
      answer: value,
    })
  }
  /**
   * 多选答案改变
   * @param e
   */
  onMultiAnswerChange(value: any) {
    debugger
    this.setState({
      multiAnswer: value,
    })
  }
  /**
   * 判断答案改变
   * @param e
   */
  onTrueFlsAnswerChange(e: any) {
    const value = e.target.value
    this.setState({
      answer: value,
    })
  }
  /**
   * 修改分数
   * @param e
   */
  onScoreChange(e: any) {
    this.setState({
      solScore: e,
    })
  }
  onSaveQues = async (e: any) => {
    const params = {
      subjectType: this.state.subjectType,
      title: this.state.titleData.content,
      option0: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      option5: '',
      correct:
        this.state.subjectType == QuesType.MULTI_SEL
          ? this.state.multiAnswer.join(',')
          : this.state.answer,
      analysis: this.state.analysis.content,
      solScore: this.state.solScore,
      groupId: this.state.group ? this.state.group.groupId : 0,
      subjectId: this.props.subjectId,
    }

    if (this.state.options.length > 0) {
      params.option0 = this.state.options[0].content || ''
    }
    if (this.state.options.length > 1) {
      params.option1 = this.state.options[1].content || ''
    }
    if (this.state.options.length > 2) {
      params.option2 = this.state.options[2].content || ''
    }
    if (this.state.options.length > 3) {
      params.option3 = this.state.options[3].content || ''
    }
    if (this.state.options.length > 4) {
      params.option4 = this.state.options[4].content || ''
    }
    if (this.state.options.length > 5) {
      params.option5 = this.state.options[5].content || ''
    }
    if (!params.title) {
      message.warn('请填写题干')
      return
    } else if (!params.option0) {
      message.warn('请至少填写一个选项')
      return
    }
    this.setState({
      loading: true,
    })
    const res = await quesSave(params)
    if (res.success) {
      message.success('保存成功')
      this.setState({
        editDialogVisible: false,
      })
      this.props.handleOk(e)
    } else {
      message.error('保存失败')
    }
    this.setState({
      loading: false,
    })
  }
  handleCancel = (e: any) => {
    console.log(e)
    this.setState({
      editDialogVisible: false,
    })
  }

  public render() {
    const formItemLayout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    }
    return (
      <span>
        <Modal
          width="60%"
          title={this.props.subjectId ? '编辑题目' : '添加题目'}
          visible={this.state.editDialogVisible}
          onOk={this.onSaveQues}
          onCancel={this.handleCancel}
        >
          <Spin spinning={this.state.loading}>
            <Form layout="horizontal" style={{ marginBottom: 16 }}>
              <Form.Item label="题型" {...formItemLayout}>
                <Radio.Group value={this.state.subjectType} onChange={this.handleQuesTypeChange}>
                  <Radio.Button value={1}>单选题</Radio.Button>
                  <Radio.Button value={2}>多选题</Radio.Button>
                  <Radio.Button value={3}>判断题</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Form>
            <Form layout="horizontal" style={{ width: 800 }}>
              <Form.Item label="题干" {...formItemLayout}>
                <Wysiwyg onChange={this.onEditChange} data={this.state.titleData} />
              </Form.Item>
              <Form.Item label="答案选项" {...formItemLayout}>
                {this.state.subjectType != QuesType.TRUE_FLS && this.optionList()}
                {this.state.subjectType == QuesType.TRUE_FLS && this.trueFlsList()}
                {this.state.subjectType != QuesType.TRUE_FLS && this.state.options.length < 6 && (
                  <Button icon="plus" onClick={this.onAddOption}>
                    添加新选项
                  </Button>
                )}
              </Form.Item>
              <Form.Item label="正确答案" {...formItemLayout}>
                {this.state.subjectType == QuesType.SINGLE_SEL && this.singleAnswerList()}
                {this.state.subjectType == QuesType.MULTI_SEL && this.multiAnswerList()}
                {this.state.subjectType == QuesType.TRUE_FLS && this.trueFlsAnswerList()}
              </Form.Item>
              <Form.Item label="题目解析" {...formItemLayout}>
                <Wysiwyg onChange={this.onEditChange} data={this.state.analysis} />
              </Form.Item>
              <Form.Item label="试题分类" {...formItemLayout}>
                <GroupSel
                  type={GroupType.QUES}
                  title="选择试题分类"
                  onChange={(e: any) => {
                    this.setState({
                      group: e,
                    })
                    this.onEditChange(e, { type: EditorType.GROUP })
                  }}
                  groupId={this.state.group && this.state.group.groupId}
                  groupName={this.state.group && this.state.group.groupName}
                />
              </Form.Item>
              <Form.Item label="获得分数" {...formItemLayout}>
                <InputNumber
                  min={0}
                  max={100}
                  value={this.state.solScore}
                  onChange={this.onScoreChange}
                />
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
        <Button
          icon={this.props.icon}
          className="mr-s"
          type="primary"
          onClick={() => {
            setTimeout(async () => {
              this.setState({
                editDialogVisible: true,
              })
            }, 0)
          }}
        >
          {this.props.buttonTitle}
        </Button>
      </span>
    )
  }
}

export default Form.create<IQuesAddProps>()(QuesAdd)

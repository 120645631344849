import React from 'react'
import { Table, Form, Button, Pagination, Input } from 'antd'
import { Link } from 'react-router-dom'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import { statPaperList } from './../../service/stats'
import { FormComponentProps } from 'antd/lib/form'
import { PERM, hasPermits } from './../../utils/permits'

const { Column } = Table
export interface IPaperStatProps extends FormComponentProps {}
interface IPaperStatStates {
  dataSource?: any
  editPaperStatId: any
  pageSize: number
  page: number
  total: number
  loading: boolean
}

class PaperStat extends React.Component<IPaperStatProps, IPaperStatStates> {
  constructor(props: any) {
    super(props)
    this.getPaperStatList = this.getPaperStatList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.showTotal = this.showTotal.bind(this)
    this.state = {
      editPaperStatId: '',
      pageSize: 20,
      page: 1,
      dataSource: [],
      total: 0,
      loading: false,
    }
  }
  componentDidMount() {
    this.getPaperStatList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  handleSearch = async () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getPaperStatList()
    }, 0)
  }

  getPaperStatList = async () => {
    const values = this.props.form!.getFieldsValue()
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      ...values,
    }
    this.setState({
      loading: true,
    })
    const res = await statPaperList(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getPaperStatList()
    }, 0)
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="统计管理" second="试卷统计" />
        <Main
          contentTitle="试卷统计"
          centerContent={
            <div>
              {hasPermits(PERM.stat_paper_search) && (
                <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
                  <Form.Item label="试卷标题">
                    {getFieldDecorator('title')(<Input placeholder="试卷标题" allowClear />)}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" icon="search" htmlType="submit">
                      搜索
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <Pagination
                current={this.state.page}
                size="small"
                pageSizeOptions={['10', '20', '50', '100', '200']}
                pageSize={this.state.pageSize}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    this.setState({
                      pageSize: size,
                    })
                    this.onPageChange(1)
                  }, 0)
                }}
              />
              <Table
                loading={this.state.loading}
                rowKey="paperId"
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                dataSource={this.state.dataSource}
              >
                <Column title="标题" dataIndex="title" key="title" render={(title: any) => title} />

                <Column
                  title="考试次数"
                  dataIndex="partiNum"
                  key="partiNum"
                  render={(partiNum: any) => partiNum}
                />

                <Column
                  title="已考人数"
                  dataIndex="partiStuNum3"
                  key="partiStuNum3"
                  render={(partiStuNum3: any) => partiStuNum3}
                />
                <Column
                  title="待考人数"
                  dataIndex="partiStuNum12"
                  key="partiStuNum12"
                  render={(partiStuNum12: any) => partiStuNum12}
                />
                <Column
                  title="及格人数"
                  dataIndex="qualStuNum"
                  key="qualStuNum"
                  render={(qualStuNum: any) => qualStuNum}
                />
                <Column
                  title="参与率"
                  dataIndex="partiExamPer"
                  key="partiExamPer"
                  render={(partiExamPer: any) => partiExamPer + '%'}
                />
                <Column
                  title="合格率"
                  dataIndex="qualExamPer"
                  key="qualExamPer"
                  render={(qualExamPer: any) => qualExamPer + '%'}
                />

                <Column
                  title="操作"
                  dataIndex="paperId"
                  key="paperId"
                  render={(paperId: any, paper: any) => (
                    <Link to={'/app/stat/paperstu?paperId=' + paperId + '&title=' + paper.title}>
                      <Button type="primary">考试详情</Button>
                    </Link>
                  )}
                />
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}
export default Form.create<IPaperStatProps>()(PaperStat)

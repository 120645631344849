import * as React from 'react'
import { Table, Form, Button, Pagination, Upload, Popconfirm, message } from 'antd'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import Group from '../group/group'
import { GroupType } from './../../utils/index'
import { moveToGroup } from './../../service/index'
import GroupSel from './../widget/GroupSel'
import DocUploader from './../widget/DocUploader'
import { courseWareUpload, courseWareList, courseWareDel } from './../../service/course'
import { SERVER_URL } from './../../service/config'
import { PERM, hasPermits } from './../../utils/permits'
const { Column } = Table

export interface ICourseWareProps {}
interface ICourseWareStates {
  dataSource?: any
  pageSize: number
  groupId: any
  page: number
  total: number
  checkedList: any
  loading: boolean
}
export default class CourseWare extends React.Component<ICourseWareProps, ICourseWareStates> {
  constructor(props: any) {
    super(props)
    this.onGroupSelect = this.onGroupSelect.bind(this)
    this.getCourseWareList = this.getCourseWareList.bind(this)
    this.handleUploadFileChange = this.handleUploadFileChange.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.state = {
      total: 0,
      page: 1,
      pageSize: 10,
      dataSource: [],
      groupId: '',
      checkedList: [],
      loading: true,
    }
  }
  componentDidMount() {
    this.getCourseWareList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  getCourseWareList = async () => {
    this.setState({
      loading: true,
    })
    const params = { page: this.state.page, rows: this.state.pageSize, groupId: this.state.groupId }
    const res = await courseWareList(params)
    if (res.success) {
      this.setState({ dataSource: res.data, total: res.total })
    }
    this.setState({
      loading: false,
    })
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getCourseWareList()
    }, 0)
  }

  onGroupSelect = (groups: any) => {
    const group = groups[0]
    setTimeout(() => {
      if (group) {
        this.setState({
          groupId: group.groupId,
          page: 1,
        })
      } else {
        this.setState({
          groupId: '',
          page: 1,
        })
      }
      this.getCourseWareList()
    }, 0)
  }

  onChangeGroup = async (group: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请选择课件')
      return
    }
    const params = { groupId: group.groupId, serialIds: this.state.checkedList.join(',') }
    const res = await moveToGroup(params)
    if (res.success) {
      setTimeout(() => {
        this.setState({ page: 1 })
        this.getCourseWareList()
      }, 0)
    } else {
      message.error('分组移动失败')
    }
  }
  onDeleteConfirm = async (e: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请先选择账号')
      return
    }
    const res = await courseWareDel(this.state.checkedList)
    if (res.success) {
      message.success('删除成功')
      this.getCourseWareList()
    } else {
      message.error('删除失败')
    }
  }
  onDeleteCancel = (e: any) => {}
  handleUploadFileChange = async (fileList: any, callback: any) => {
    const params = {
      groupId: this.state.groupId,
      isSetFile: 1,
      fileJson: JSON.stringify(fileList),
    }
    const res = await courseWareUpload(params)
    if (res.success) {
      this.getCourseWareList()
      callback(res.success)
    } else {
      message.error('上传失败!')
    }
  }

  public render() {
    const rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        this.setState({ checkedList: selectedRowKeys })
      },
      getCheckboxProps: (record: any) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }

    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="课程管理" second="课件列表" />
        <Main
          leftTitle="课件分类列表"
          contentTitle="所有课件"
          leftContent={<Group type={GroupType.COURSE_WARE} onSelect={this.onGroupSelect} disableEdit={true}/>}
          centerContent={
            <div>
              <Form layout="inline" style={{ marginBottom: 16 }}>
                <Form.Item>
                  {hasPermits(PERM.study_courseware_func_add) && (
                    <DocUploader
                      buttonTitle="上传新课件"
                      handleUploadFileChange={this.handleUploadFileChange}
                    />
                  )}
                  {hasPermits(PERM.study_courseware_func_groupmod) && (
                    <GroupSel
                      title="移动到分类"
                      buttonTitle="移动到分类"
                      type={GroupType.COURSE_WARE}
                      hideInput={true}
                      onChange={this.onChangeGroup}
                    />
                  )}
                </Form.Item>
                {hasPermits(PERM.study_courseware_func_del) && (
                  <Form.Item>
                    <Popconfirm
                      title="确定要删除课件吗"
                      onConfirm={this.onDeleteConfirm}
                      onCancel={this.onDeleteCancel}
                      okText="删除"
                      cancelText="取消"
                    >
                      <Button icon="delete" type="danger" ghost>
                        删除课件
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              </Form>
              <Pagination
                current={this.state.page}
                size="small"
                pageSizeOptions={['10', '20', '50', '100', '200']}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    this.setState({
                      pageSize: size,
                    })
                    this.onPageChange(1)
                  }, 0)
                }}
              />
              <Table
                rowSelection={rowSelection}
                rowKey="textId"
                loading={this.state.loading}
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                  pageSize: this.state.pageSize,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                }}
                dataSource={this.state.dataSource}
              >
                <Column
                  title="文件名"
                  dataIndex="origFileName"
                  key="origFileName"
                  render={(origFileName: any) => origFileName}
                />
                <Column
                  title="分类"
                  dataIndex="groupNames"
                  key="groupNames"
                  render={(groupNames: any) => groupNames}
                />
                <Column
                  title="课件类型"
                  dataIndex="textType"
                  key="textType"
                  render={(textType: any) => {
                    let typeString = '文档'
                    switch (textType) {
                      case 1:
                        typeString = '视频'
                        break
                      case 2:
                        typeString = '音频'
                        break
                      case 3:
                        typeString = '文档'
                        break
                      case 4:
                        typeString = '图片'
                        break
                      default:
                        typeString = '其它'
                        break
                    }
                    return typeString
                  }}
                />
                <Column
                  title="文件大小"
                  dataIndex="fileSize"
                  key="fileSize"
                  render={(fileSize: any) => fileSize + 'MB'}
                />
                <Column
                  title="访问地址"
                  dataIndex="textFaceURL"
                  key="textFaceURL"
                  render={(textFaceURL: any) => (
                    <a target="_blank" href={SERVER_URL + textFaceURL}>
                      下载
                    </a>
                  )}
                />
                <Column
                  title="上传日期"
                  dataIndex="uploadDateTime"
                  key="uploadDateTime"
                  render={(uploadDateTime: any) => uploadDateTime}
                />
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}

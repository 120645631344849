import React, { useState, useEffect } from 'react'
import { Modal, Input, Button } from 'antd'
import Group from './../group/group'
import { GroupType } from './../../utils/index'
export interface IGroupSelProps {
  title: string
  onChange: (e: any) => void
  groupId?: string
  groupName?: string
  type: GroupType
  hideInput?: boolean
  buttonTitle?: string
  size?: any
}
export interface IGroupSelStates {
  visible: boolean
  group: any
  selGroups: any
}

export default class GroupSel extends React.Component<IGroupSelProps, IGroupSelStates> {
  constructor(props: any) {
    super(props)
    this.handleOk = this.handleOk.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.showEditDialog = this.showEditDialog.bind(this)
    this.state = {
      visible: false,
      selGroups: [],
      group: null,
    }
  }

  componentDidMount() {
    this.setState({
      selGroups: [this.props.groupId],
      group: {
        groupName: this.props.groupName,
        groupId: this.props.groupId,
      },
    })
  }
  componentWillReceiveProps(props: any) {
    if (props.groupId != this.props.groupId) {
      this.setState({
        selGroups: [props.groupId],
        group: {
          groupName: props.groupName,
          groupId: props.groupId,
        },
      })
    }
  }

  showEditDialog = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (e: any) => {
    const group = this.state.selGroups[0]
    setTimeout(() => {
      this.setState({
        visible: false,
        group: group,
      })
      this.props.onChange(group)
    }, 0)
  }

  handleCancel = (e: any) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }
  onSelect = (e: any) => {
    this.setState({
      selGroups: e,
    })
  }

  public render() {
    return (
      <span>
        <Modal
          width="30%"
          title={this.props.title}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Group
            type={this.props.type}
            onSelect={this.onSelect}
            selectKeys={[this.state.group && this.state.group.groupId]}
          />
        </Modal>
        {!this.props.hideInput && (
          <div className="flex-h">
            <Input
              className="group-sel"
              value={this.state.group ? this.state.group.groupName : ''}
              placeholder="请选择分类"
              disabled
            />
            <Button
              type="primary"
              size={this.props.size || 'default'}
              onClick={this.showEditDialog}
            >
              {this.props.buttonTitle || '选择分类'}
            </Button>
          </div>
        )}
        {this.props.hideInput && (
          <Button
            type="primary"
            ghost
            size={this.props.size || 'default'}
            onClick={this.showEditDialog}
          >
            {this.props.buttonTitle || '选择分类'}
          </Button>
        )}
      </span>
    )
  }
}

import * as React from 'react'
import { Tag, Input, Icon, Popconfirm } from 'antd'
import { TweenOneGroup } from 'rc-tween-one'

const { CheckableTag } = Tag

export interface IEditableTagGroupProps {
  handleTagAdded?: (value: string, parent: any) => void
  handleTagRemoved?: (removedTag: any, parent: any) => void
  handleTagCodeModed?: (code: any, tag: any) => void
  handleTagClick?: (tag: any, parent: any) => void
  tags: any[]
  parent: any
  editable: boolean
  checkedIds?: any[]
  hasPermit?: boolean
}
interface IEditableTagGroupStates {
  tags: any[]
  inputVisible: Boolean
  inputValue: any
}

export default class EditableTagGroup extends React.Component<
  IEditableTagGroupProps,
  IEditableTagGroupStates
> {
  state = {
    tags: [],
    inputVisible: false,
    inputValue: '',
  }

  input!: Input

  componentDidMount() {
    if (this.props.tags && this.props.tags.length > 0) {
      this.setState({
        tags: [...this.state.tags, ...this.props.tags],
      })
    }
  }

  handleClose = (removedTag: any) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag)
    this.setState({ tags })
    if (this.props.handleTagRemoved) {
      this.props.handleTagRemoved(removedTag, this.props.parent)
    }
  }

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus())
  }

  handleInputChange = (e: any) => {
    this.setState({ inputValue: e.target.value })
  }

  handleInputConfirm = () => {
    const { inputValue } = this.state
    let tags: any[] = this.state.tags || []
    if (inputValue && tags.indexOf(inputValue) === -1) {
      const newTag = {
        text: inputValue,
        id: '_',
      }
      tags = [...tags, newTag]

      if (this.props.handleTagAdded) {
        this.props.handleTagAdded(inputValue, this.props.parent)
      }
    }
    this.setState({
      tags,
      inputVisible: false,
      inputValue: '',
    })
  }

  saveInputRef = (input: any) => (this.input = input)

  onTagClick = () => {}

  forMap = (tag: any) => {
    const tagElem = (
      <Popconfirm
        key={tag.id + '_pop'}
        title={
          <Input
            placeholder="填写code"
            allowClear
            defaultValue={tag.code}
            onChange={(e) => {
              tag.code = e.currentTarget.value
              this.setState({
                tags: this.state.tags,
              })
            }}
          />
        }
        onConfirm={() => {
          if (this.props.handleTagCodeModed) {
            this.props.handleTagCodeModed(tag.code, tag)
          }
        }}
        onCancel={() => {}}
        okText="确定"
        cancelText="取消"
      >
        {!this.props.hasPermit && <Tag key={tag.id}>{tag.text}</Tag>}
        {this.props.hasPermit && (
          <Tag
            key={tag.id}
            closable
            onClose={(e: any) => {
              e.preventDefault()
              this.handleClose(tag)
            }}
          >
            {tag.text}
          </Tag>
        )}
      </Popconfirm>
    )

    const disEditableElem = (
      <CheckableTag
        key={tag.id}
        checked={this.props.checkedIds?.indexOf(tag.id) != -1}
        onChange={(e) => {
          tag.checked = e
          if (this.props.handleTagClick) {
            this.props.handleTagClick(tag, this.props.parent)
          }
        }}
      >
        {tag.text}
      </CheckableTag>
    )
    return (
      <span key={tag.id + '_span'} style={{ display: 'inline-block' }}>
        {this.props.editable && tagElem}
        {!this.props.editable && disEditableElem}
      </span>
    )
  }

  render() {
    const { tags, inputVisible, inputValue } = this.state
    const tagChild = tags.map(this.forMap)
    return (
      <div className="flex-vcenter ml-s">
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: 'from',
            duration: 100,
            onComplete: (e) => {
              // e.target.style = ''
            },
          }}
          leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
          appear={false}
        >
          {tagChild}
        </TweenOneGroup>
        {this.props.editable && inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {this.props.editable && !inputVisible && (
          <Tag onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
            <Icon type="plus" />
            添加
          </Tag>
        )}
      </div>
    )
  }
}

import * as React from 'react'
import { Form, Radio, Input, Button, Select, Modal, message, Spin } from 'antd'
import GroupSel from './../widget/GroupSel'
import Uploader from './../widget/Uploader'
import { FormComponentProps } from 'antd/lib/form'
import { GroupType } from './../../utils/index'
import { studentSave, studentOne } from './../../service/student'
import GlobalRegionCascader from './../widget/GlobalRegionCascader'
import CompInput from './../widget/CompInput'

const { Option } = Select

export type IStudentAddProps = {
  onNextStep?: (e: any) => void
  studentId?: any
  icon: string
  buttonTitle: string
  onSuccess: () => any
} & FormComponentProps

type IStudentAddStates = {
  groupId: any
  gender: any
  studentType: number
  photoDirectURL: any
  regiProvinId: any
  regiCityId: any
  provinName: any
  cityName: any
  compName: any
  compId: any
  groupName: any
  editDialogVisible: boolean
  isSetFile: any
  loading: boolean
  originCompName: any
  originCompId: any
}

class StudentAdd extends React.Component<IStudentAddProps, IStudentAddStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      groupId: '',
      gender: '男',
      studentType: 1,
      photoDirectURL: '',
      regiProvinId: 0,
      regiCityId: 0,
      provinName: '',
      cityName: '',
      compName: '',
      compId: '',
      groupName: '',
      editDialogVisible: false,
      isSetFile: 0,
      loading: false,
      originCompName: '',
      originCompId: '',
    }

    this.showEditDialog = this.showEditDialog.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  getStudent = async () => {
    if (this.props.studentId) {
      this.setState({ loading: true })
      const res = await studentOne(this.props.studentId)
      if (res.success) {
        const stu = res.data[0]
        if (stu) {
          const group = stu.groupJson ? stu.groupJson[0] : {}
          this.props.form.setFieldsValue({
            ...stu,
            compType: stu.compType + '',
            vehicleType: stu.vehicleType + '',
            ownerType: stu.ownerType + '',
            groupId: group ? group.groupId : '',
            region: [stu.regiProvinId, stu.regiCityId],
          })
          setTimeout(() => {
            this.setState({
              ...stu,
              groupId: group ? group.groupId : '',
              groupName: group ? group.groupName : '',
              originCompId: stu.compId,
              originCompName: stu.compName,
            })
          }, 0)
        }
      }
      this.setState({
        loading: false,
      })
    }
  }

  handleSubmit = async () => {
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        })
        const params = {
          ...values,
          studentId: this.props.studentId,
          groupId: this.state.groupId,
          fileJson: JSON.stringify(values.fileList),
          isSetFile: this.state.isSetFile,
          regiProvinId: this.state.regiProvinId,
          regiCityId: this.state.regiCityId,
          provinName: this.state.provinName,
          cityName: this.state.cityName,
          compName: this.state.compName,
          compId: this.state.compId,
          studentType: 1,
          region: [],
        }
        const res = await studentSave(params)
        if (res.success) {
          if (this.props.onNextStep) {
            this.props.onNextStep(null)
          }
          this.props.onSuccess()
          this.setState({
            editDialogVisible: false,
          })
        } else {
          message.error(res.msg)
        }
        this.setState({
          loading: false,
        })
      }
    })
  }

  showEditDialog = () => {
    this.setState({
      editDialogVisible: true,
    })
  }

  handleCancel = (e: any) => {
    this.setState({
      editDialogVisible: false,
    })
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const form = this.props.form!
    return (
      <div>
        <Modal
          width="40%"
          title="添加考生"
          visible={this.state.editDialogVisible}
          onOk={() => {
            this.handleSubmit()
          }}
          onCancel={this.handleCancel}
        >
          <Spin spinning={this.state.loading}>
            <Form layout="horizontal" style={{ marginBottom: 16 }}>
              <Form.Item label="学员姓名">
                {getFieldDecorator('stuName', {
                  rules: [{ required: true, message: '请输入姓名!' }],
                })(<Input placeholder="学员姓名" />)}
              </Form.Item>
              <Form.Item label="性别">
                {getFieldDecorator('gender', {
                  rules: [{ required: true, message: '请选择性别!' }],
                })(
                  <Radio.Group
                    onChange={(e) => {
                      console.log(e)
                      this.setState({
                        gender: e.target.value,
                      })
                    }}
                  >
                    <Radio value={'男'} key={'男'}>
                      男
                    </Radio>
                    <Radio value={'女'} key={'女'}>
                      女
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item label="学员头像">
                {getFieldDecorator('fileList', {
                  valuePropName: 'fileList',
                  rules: [
                    {
                      required: true,
                      message: '请上传学员头像!',
                      validator: (rule, value, callback) => {
                        if (
                          this.state.isSetFile == 0 &&
                          this.props.studentId &&
                          this.state.photoDirectURL
                        ) {
                          callback()
                        } else {
                          if (!form.getFieldValue('fileList')) {
                            callback('请上传学员头像!')
                          } else {
                            callback()
                          }
                        }
                      },
                    },
                  ],
                  normalize(e: any) {
                    if (Array.isArray(e)) return e
                    return e && e.fileList
                  },
                })(
                  <Uploader
                    defaultImg={this.state.photoDirectURL}
                    handleUploadFileChange={(fileList: any) => {
                      form.setFieldsValue({
                        fileList: fileList,
                      })
                      this.setState({
                        isSetFile: fileList.length > 0 ? 1 : 0,
                      })
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item label="所在组">
                {getFieldDecorator('groupId', {
                  rules: [{ required: true, message: '请选择所在组!' }],
                })(
                  <GroupSel
                    type={GroupType.STU}
                    title="所在组"
                    groupId={this.state.groupId}
                    groupName={this.state.groupName}
                    onChange={(e: any) => {
                      if (e) {
                        this.setState({ groupId: e.groupId, groupName: e.groupName })
                        form.setFieldsValue({
                          groupId: e.groupId,
                        })
                      }
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item label="手机号">
                {getFieldDecorator('phone', {
                  rules: [{ required: true, message: '请输入手机号!' }],
                })(<Input placeholder="手机号" allowClear />)}
              </Form.Item>
              <Form.Item label="身份证号">
                {getFieldDecorator('cardID', {
                  rules: [{ required: true, message: '请输入身份证号!' }],
                })(<Input placeholder="身份证号" allowClear />)}
              </Form.Item>
              <Form.Item label="文化程度">
                {getFieldDecorator('educDegree')(
                  <Select placeholder="请选择文化程度" allowClear style={{ width: '100%' }}>
                    <Option key="小学">小学</Option>
                    <Option key="初中">初中</Option>
                    <Option key="高中">高中</Option>
                    <Option key="大专">大专</Option>
                    <Option key="本科">本科</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="企业类型">
                {getFieldDecorator('compType', {
                  rules: [{ required: true, message: '请输入企业类型!' }],
                })(
                  <Select placeholder="请选择企业类型" allowClear style={{ width: '100%' }}>
                    <Option key="4">混凝土企业</Option>
                    <Option key="3">砂浆企业</Option>
                    <Option key="2">水泥企业 </Option>
                    <Option key="1">物流企业</Option>
                  </Select>
                )}
              </Form.Item>
              <CompInput
                form={form}
                name={this.state.originCompName}
                id={this.state.originCompId}
                onChange={(values: any) => {
                  setTimeout(() => {
                    this.setState({
                      ...values,
                    })
                  }, 0)
                }}
              />
              <Form.Item label="车辆类型">
                {getFieldDecorator('vehicleType', {
                  rules: [{ required: true, message: '请选择车辆类型!' }],
                })(
                  <Select placeholder="请选择车辆类型" allowClear style={{ width: '100%' }}>
                    <Option key="1">搅拌车</Option>
                    <Option key="2">泵车 </Option>
                    <Option key="3">水泥及砂浆运输车</Option>
                    <Option key="4">其他车</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="车辆归属类型">
                {getFieldDecorator('ownerType', {
                  rules: [{ required: true, message: '请选择车辆归属类型!' }],
                })(
                  <Select placeholder="请选择车辆归属类型" allowClear style={{ width: '100%' }}>
                    <Option key="1">企业自备车</Option>
                    <Option key="2">物流运输车</Option>
                    <Option key="3">个人车辆</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="学员籍贯">
                {getFieldDecorator('region', {
                  rules: [{ required: true, message: '请选择所属地区!' }],
                })(
                  <GlobalRegionCascader
                    maxLevel={2}
                    regiProvinId={this.state.regiProvinId}
                    provinName={this.state.provinName}
                    regiCityId={this.state.regiCityId}
                    cityName={this.state.cityName}
                    onChange={(e: any) => {
                      if (e.length > 0) {
                        const value = e[0]
                        this.setState({
                          regiProvinId: value.value,
                          provinName: value.label,
                        })
                      }
                      if (e.length > 1) {
                        const value = e[1]
                        this.setState({
                          regiCityId: value.value,
                          cityName: value.label,
                        })
                      }
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item label="驾驶证等级">
                {getFieldDecorator('liceClass')(
                  <Select placeholder="请选择驾驶证等级" allowClear style={{ width: '100%' }}>
                    <Option key="C1">C1</Option>
                    <Option key="B1">B1</Option>
                    <Option key="B2">B2</Option>
                    <Option key="A1">A1</Option>
                    <Option key="A2">A2</Option>
                  </Select>
                )}
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
        <Button
          icon={this.props.icon}
          type="primary"
          onClick={(e) => {
            setTimeout(() => {
              this.setState({ editDialogVisible: true })
              this.getStudent()
            }, 0)
          }}
        >
          {this.props.buttonTitle}
        </Button>
      </div>
    )
  }
}

export default Form.create<IStudentAddProps>()(StudentAdd)

import { post } from './tools'
import * as config from './config'

export const testList = async (params: any) => {
  return await post({
    url: config.TEST_LIST,
    data: params,
  })
}
export const testOne = async (id: any) => {
  return await post({
    url: config.TEST_ONE,
    data: { paperId: id },
  })
}
export const testSave = async (params: any) => {
  return await post({
    url: config.TEST_SAVE,
    data: params,
  })
}
export const testDel = async (ids: any[]) => {
  return await post({
    url: config.TEST_DEL,
    data: { paperIds: ids.join(',') },
  })
}

export const testSelQuesRandom = async (params: any) => {
  return await post({
    url: config.TEST_QUES_RANDOM,
    data: params,
  })
}
export const testSelQuesRandomNow = async (params: any) => {
  return await post({
    url: config.TEST_QUES_RANDOM_NOW,
    data: params,
  })
}
export const testSelQuesManual = async (params: any) => {
  return await post({
    url: config.TEST_QUES_MANUAL,
    data: params,
  })
}
export const testSelQuesManualRandom = async (params: any) => {
  return await post({
    url: config.TEST_QUES_MANUAL_RANDOM,
    data: params,
  })
}
export const testSettingList = async (params: any) => {
  return await post({
    url: config.TEST_SETTING_LIST,
    data: params,
  })
}
export const testSettingSave = async (params: any) => {
  return await post({
    url: config.TEST_SETTING_SAVE,
    data: params,
  })
}
export const testQuesGroup = async (params: any) => {
  return await post({
    url: config.TEST_QUES_GROUP,
    data: params,
  })
}

export const testPubish = async (id: any) => {
  return await post({
    url: config.TEST_PUBLISH,
    data: { paperId: id },
  })
}

import { post } from './tools'
import * as config from './config'

export const roleList = async (params: any) => {
  return await post({
    url: config.ROLE_LIST,
    data: params,
  })
}
export const roleOne = async (id: any) => {
  return await post({
    url: config.ROLE_ONE,
    data: { roleId: id },
  })
}
export const roleSave = async (params: any) => {
  return await post({
    url: config.ROLE_SAVE,
    data: params,
  })
}
export const roleDel = async (ids: any[]) => {
  return await post({
    url: config.ROLE_DEL,
    data: { roleIds: ids.join(',') },
  })
}

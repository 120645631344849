import React from 'react'
import { Link } from 'react-router-dom'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import { Table, Pagination, Form, Input, Button } from 'antd'
import { statCourseStu } from './../../service/stats'
import { FormComponentProps } from 'antd/lib/form'

export interface ICourseStatDetailProps extends FormComponentProps {}
interface ICourseStatDetailStates {
  editCourseStatDetailId: any
  courseId: any
  courseTitle: string
  pageSize: number
  page: number
  dataSource: any
  total: number
  loading: boolean
}

const { Column } = Table
class CourseStatDetail extends React.Component<ICourseStatDetailProps, ICourseStatDetailStates> {
  constructor(props: any) {
    super(props)
    this.showTotal = this.showTotal.bind(this)
    this.state = {
      editCourseStatDetailId: '',
      courseId: props.query.courseId,
      courseTitle: props.query.title || '',
      pageSize: 20,
      page: 1,
      dataSource: [],
      total: 0,
      loading: false,
    }
  }
  componentDidMount() {
    this.getCourseTestingList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  handleSearch = async () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getCourseTestingList()
    }, 0)
  }

  getCourseTestingList = async () => {
    const values = this.props.form!.getFieldsValue()
    this.setState({
      loading: true,
    })
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      courseId: this.state.courseId,
      ...values,
    }
    const res = await statCourseStu(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getCourseTestingList()
    }, 0)
  }
  public render() {
    const { getFieldDecorator } = this.props.form!
    const rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      },
      getCheckboxProps: (record: any) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }
    return (
      <div className="w-full h-full">
        <BreadcrumbCustom
          first={<Link to={'/app/stat/course'}>统计管理</Link>}
          second={<Link to={'/app/stat/course'}>学习统计</Link>}
          third={this.state.courseTitle}
        />
        <Main
          contentTitle={this.state.courseTitle}
          centerContent={
            <div>
              <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
                <Form.Item label="姓名">
                  {getFieldDecorator('stuName')(<Input placeholder="姓名" allowClear />)}
                </Form.Item>
                <Form.Item label="企业名称">
                  {getFieldDecorator('compName')(<Input placeholder="企业名称" allowClear />)}
                </Form.Item>
                <Form.Item>
                  <Button type="primary" icon="search" htmlType="submit">
                    搜索
                  </Button>
                </Form.Item>
              </Form>
              <Pagination
                current={this.state.page}
                size="small"
                pageSizeOptions={['10', '20', '50', '100', '200']}
                pageSize={this.state.pageSize}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    this.setState({
                      pageSize: size,
                    })
                    this.onPageChange(1)
                  }, 0)
                }}
              />
              <Table
                rowSelection={rowSelection}
                key="table1"
                loading={this.state.loading}
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                dataSource={this.state.dataSource}
              >
                <Column
                  title="姓名"
                  dataIndex="stuName"
                  key="stuName"
                  render={(stuName: any) => stuName}
                />
                <Column
                  title="企业名称"
                  dataIndex="compName"
                  key="compName"
                  render={(compName: any) => compName}
                />
                <Column
                  title="状态"
                  dataIndex="learnStatus"
                  key="learnStatus"
                  render={(learnStatus: any) => {
                    let str = ''
                    if (learnStatus == 1) {
                      str = '未学'
                    } else if (learnStatus == 2) {
                      str = '在学'
                    } else if (learnStatus == 3) {
                      str = '已学'
                    }
                    return str
                  }}
                />
                <Column
                  title="最近学习日期"
                  dataIndex="startDate"
                  key="startDate"
                  render={(startDate: any) => startDate}
                />
                <Column
                  title="获得积分"
                  dataIndex="score"
                  key="score"
                  render={(score: any) => score}
                />
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}

export default Form.create<ICourseStatDetailProps>()(CourseStatDetail)

import * as React from 'react'
import { Table, Form, Button, Pagination, DatePicker, message, Popconfirm, Input } from 'antd'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import Group from '../group/group'
import { GroupType } from '../../utils/index'
import moment from 'moment'
import { certsList, certsDel, certsImp } from '../../service/certs'
import { download } from '../../service/index'
import { FormComponentProps } from 'antd/lib/form'
import { BASE_URL, CERT_EXP } from '../../service/config'
import { PERM, hasPermits } from './../../utils/permits'
import DocUploader from './../widget/DocUploader'
import { SERVER_URL } from './../../service/config'

const { Column } = Table
const { RangePicker } = DatePicker

export interface ICertsProps extends FormComponentProps {}
interface ICertsStates {
  dataSource: any
  pageSize: number
  groupId: any
  total: number
  page: number
  checkedList: any
  editCertsId: any
  loading: boolean
}

class Certs extends React.Component<ICertsProps, ICertsStates> {
  constructor(props: any) {
    super(props)
    this.getStuList = this.getStuList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onGroupSelect = this.onGroupSelect.bind(this)
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this)
    this.state = {
      pageSize: 10,
      dataSource: [],
      groupId: '',
      total: 0,
      page: 1,
      checkedList: [],
      editCertsId: '',
      loading: false,
    }
  }
  componentDidMount() {
    this.getStuList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  getStuList = async () => {
    const values = this.props.form!.getFieldsValue()
    this.setState({
      loading: true,
    })

    const dateFormat = 'YYYY-MM-DD'
    values.startIssueDate =
      values.startDate && values.startDate.length > 0
        ? moment(values.startDate[0]).format(dateFormat)
        : ''
    values.endIssueDate =
      values.startDate && values.startDate.length > 0
        ? moment(values.startDate[1]).format(dateFormat)
        : ''
    values.startValidChokeDate =
      values.endDate && values.endDate.length > 0
        ? moment(values.endDate[0]).format(dateFormat)
        : ''
    values.endValidChokeDate =
      values.endDate && values.endDate.length > 0
        ? moment(values.endDate[1]).format(dateFormat)
        : ''
    const params = {
      page: this.state.page,
      rows: this.state.pageSize,
      groupId: this.state.groupId,
      startValidLaunchDate: values.startIssueDate,
      endValidLaunchDate: values.endIssueDate,
      startValidChokeDate: values.startValidChokeDate,
      endValidChokeDate: values.endValidChokeDate,
      stuName: values.stuName,
      phone: values.phone,
      cardID: values.cardID,
      compName: values.compName,
      ccieNo: values.ccieNo,
    }
    const res = await certsList(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  handleSearch = () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getStuList()
    }, 0)
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getStuList()
    }, 0)
  }

  onGroupSelect = (groups: any) => {
    const group = groups[0]
    setTimeout(() => {
      if (group) {
        this.setState({
          groupId: group.groupId,
          page: 1,
        })
      } else {
        this.setState({
          groupId: '',
          page: 1,
        })
      }
      this.getStuList()
    }, 0)
  }

  handleUploadFileChange = async (e: any) => {
    const res = await certsImp(e)
    if (res.success) {
      message.success('导入成功')
      this.handleSearch()
    } else {
      let str = res.msg + '，'
      res.data.forEach((item: any) => {
        str += `(第${item.rowNum}行, 【${item.column}】 列)\n`
      })
      message.warn(str)
      this.handleSearch()
    }
  }

  onDeleteConfirm = async (e: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请先选择账号')
      return
    }
    const res = await certsDel(this.state.checkedList)
    if (res.success) {
      message.success('删除成功')
      this.getStuList()
    } else {
      message.error('删除失败')
    }
  }

  onDeleteCancel = (e: any) => {}
  handleQuesExport = async (e: any) => {
    const values = this.props.form!.getFieldsValue()
    const params = {
      stuName: values.stuName || '',
      groupId: this.state.groupId,
      ccieIds: this.state.checkedList.join(','),
    }
    await download(CERT_EXP, '证书', params)
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        this.setState({
          checkedList: selectedRowKeys,
        })
      },
    }

    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="证书管理" second="证书模板" />
        <Main
          leftTitle="学员分类列表"
          contentTitle="所有证书"
          leftContent={<Group type={GroupType.STU} onSelect={this.onGroupSelect} disableEdit={true} />}
          centerContent={
            <div>
              <Form layout="inline" style={{ marginBottom: 16 }}>
                {hasPermits(PERM.cert_func_exp) && (
                  <Form.Item>
                    <Button icon="export" type="primary" ghost onClick={this.handleQuesExport}>
                      导出所选证书({this.state.checkedList.length})
                    </Button>
                  </Form.Item>
                )}
                {hasPermits(PERM.cert_func_imp) && (
                  <Form.Item>
                    <DocUploader
                      buttonTitle="导入证书"
                      supportTypes="Excel"
                      handleUploadFileChange={this.handleUploadFileChange}
                    />
                    <a target="_blank" href={SERVER_URL + 'share/ccieImpExce.xlsx'}>
                      模板下载
                    </a>
                  </Form.Item>
                )}
                {hasPermits(PERM.cert_func_del) && (
                  <Form.Item>
                    <Popconfirm
                      title="确定要删除证书吗"
                      onConfirm={this.onDeleteConfirm}
                      onCancel={this.onDeleteCancel}
                      okText="删除"
                      cancelText="取消"
                    >
                      <Button icon="delete" type="danger" ghost>
                        删除
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              </Form>
              {hasPermits(PERM.cert_func_search) && (
                <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
                  <Form.Item label="姓名">
                    {getFieldDecorator('stuName')(<Input placeholder="姓名" allowClear />)}
                  </Form.Item>
                  <Form.Item label="手机">
                    {getFieldDecorator('phone')(<Input placeholder="手机" allowClear />)}
                  </Form.Item>
                  <Form.Item label="身份证">
                    {getFieldDecorator('cardID')(<Input placeholder="身份证" allowClear />)}
                  </Form.Item>
                  <Form.Item label="企业名称">
                    {getFieldDecorator('compName')(<Input placeholder="企业名称" allowClear />)}
                  </Form.Item>
                  <Form.Item label="证书编号">
                    {getFieldDecorator('ccieNo')(<Input placeholder="证书编号" allowClear />)}
                  </Form.Item>
                  <Form.Item label="证书开始日期">
                    {getFieldDecorator('startDate')(<RangePicker />)}
                  </Form.Item>
                  <Form.Item label="证书截止日期">
                    {getFieldDecorator('endDate')(<RangePicker />)}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" icon="search" htmlType="submit">
                      搜索
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <Pagination
                size="small"
                current={this.state.page}
                pageSizeOptions={['10', '20', '50', '100', '200']}
                pageSize={this.state.pageSize}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  }, 0)
                }}
              />
              <Table
                rowSelection={rowSelection}
                rowKey="ccieId"
                loading={this.state.loading}
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                dataSource={this.state.dataSource}
              >
                <Column
                  title="姓名"
                  dataIndex="stuName"
                  key="stuName"
                  render={(stuName: any) => stuName}
                />
                <Column title="手机" dataIndex="phone" key="phone" render={(phone: any) => phone} />
                <Column
                  title="身份证"
                  dataIndex="cardID"
                  key="cardID"
                  render={(cardID: any) => cardID}
                />
                <Column
                  title="企业"
                  dataIndex="compName"
                  key="compName"
                  render={(compName: any) => compName}
                />
                <Column
                  title="所在组"
                  dataIndex="groupNames"
                  key="groupNames"
                  render={(groupNames: any) => groupNames}
                />
                <Column
                  title="籍贯"
                  dataIndex="provinName"
                  key="provinName"
                  render={(provinName: any, values: any) =>
                    provinName || '' + (values.cityName || '')
                  }
                />
                <Column
                  title="证书编号"
                  dataIndex="ccieNo"
                  key="ccieNo"
                  render={(ccieNo: any) => ccieNo}
                />
                <Column
                  title="证书来源"
                  dataIndex="ccieSource"
                  key="ccieSource"
                  render={(ccieSource: any) => {
                    if (ccieSource == 1) {
                      return '新发'
                    } else if (ccieSource == 2) {
                      return '换证'
                    } else {
                      return '补证'
                    }
                  }}
                />
                <Column
                  title="开始日期"
                  dataIndex="validStartDate"
                  key="validStartDate"
                  render={(validStartDate: any) => validStartDate}
                />
                <Column
                  title="截止日期"
                  dataIndex="validEndDate"
                  key="validEndDate"
                  render={(validEndDate: any) => validEndDate}
                />
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}

export default Form.create<ICertsProps>()(Certs)

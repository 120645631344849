import React from 'react'
import { Table, Form, Button, Pagination, Input, message, Popconfirm } from 'antd'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import RoleAdd from './RoleAdd'
import { roleList, roleDel } from './../../service/role'
import { FormComponentProps } from 'antd/lib/form'
import { PERM, hasPermits } from './../../utils/permits'

const { Column } = Table
export interface IRoleProps extends FormComponentProps {}
interface IRoleStates {
  dataSource?: any
  editRoleId: any
  pageSize: number
  page: number
  total: number
  checkedList: any
  loading: boolean
}

class Role extends React.Component<IRoleProps, IRoleStates> {
  constructor(props: any) {
    super(props)
    this.getRoleList = this.getRoleList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.showTotal = this.showTotal.bind(this)
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this)
    this.onDeleteCancel = this.onDeleteCancel.bind(this)
    this.state = {
      editRoleId: '',
      pageSize: 20,
      page: 1,
      dataSource: [],
      checkedList: [],
      total: 0,
      loading: false,
    }
  }
  componentDidMount() {
    this.getRoleList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  getRoleList = async () => {
    const values = this.props.form!.getFieldsValue()
    this.setState({
      loading: true,
    })
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      ...values,
    }
    const res = await roleList(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  handleSearch = () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getRoleList()
    }, 0)
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getRoleList()
    }, 0)
  }

  onDeleteConfirm = async (e: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请先选择角色')
      return
    }
    const res = await roleDel(this.state.checkedList)
    if (res.success) {
      message.success('删除成功')
      this.getRoleList()
    } else {
      message.error('删除失败')
    }
  }

  onDeleteCancel = (e: any) => {}

  public render() {
    const { getFieldDecorator } = this.props.form!
    const rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
        setTimeout(() => {
          this.setState({
            checkedList: selectedRowKeys,
          })
        }, 0)
      },
      getCheckboxProps: (record: any) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }

    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="系统设置" second="角色管理" />
        <Main
          contentTitle="所有角色"
          centerContent={
            <div>
              <Form layout="inline" style={{ marginBottom: 16 }}>
                {hasPermits(PERM.setting_role_func_add) && (
                  <Form.Item>
                    <RoleAdd icon="plus" roleId="" buttonTitle="添加角色" />
                  </Form.Item>
                )}
                {hasPermits(PERM.setting_role_func_del) && (
                  <Form.Item>
                    <Popconfirm
                      title="确定要删除角色吗"
                      onConfirm={this.onDeleteConfirm}
                      onCancel={this.onDeleteCancel}
                      okText="删除"
                      cancelText="取消"
                    >
                      <Button icon="delete" type="danger" ghost>
                        删除
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              </Form>
              {hasPermits(PERM.setting_role_func_search) && (
                <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
                  <Form.Item label="角色名称">
                    {getFieldDecorator('roleName')(<Input placeholder="角色名称" allowClear />)}
                  </Form.Item>
                  <Form.Item label="创建者账户">
                    {getFieldDecorator('loginName')(<Input placeholder="创建者账户" allowClear />)}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" icon="search" htmlType="submit">
                      搜索
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <Pagination
                current={this.state.page}
                size="small"
                pageSizeOptions={['10', '20', '50', '100', '200']}
                pageSize={this.state.pageSize}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    this.setState({
                      pageSize: size,
                    })
                    this.onPageChange(1)
                  }, 0)
                }}
              />
              <Table
                rowSelection={rowSelection}
                loading={this.state.loading}
                rowKey="roleId"
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                dataSource={this.state.dataSource}
              >
                <Column
                  title="角色名称"
                  dataIndex="roleName"
                  key="roleName"
                  render={(roleName: any) => roleName}
                />
                <Column
                  title="角色描述"
                  dataIndex="roleDesc"
                  key="roleDesc"
                  render={(roleDesc: any) => roleDesc}
                />
                <Column
                  title="创建者账户"
                  dataIndex="loginName"
                  key="loginName"
                  render={(loginName: any) => loginName}
                />

                <Column
                  title="人员数量"
                  dataIndex="accountNum"
                  key="accountNum"
                  render={(accountNum: any) => accountNum}
                />
                <Column
                  title="创建时间"
                  dataIndex="regtime"
                  key="regtime"
                  render={(regtime: any) => regtime}
                />
                {hasPermits(PERM.setting_role_func_mod) && (
                  <Column
                    title="操作"
                    dataIndex="roleId"
                    key="roleId"
                    render={(roleId: any) => (
                      <RoleAdd icon="edit" roleId={roleId} buttonTitle="编辑" />
                    )}
                  />
                )}
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}

export default Form.create<IRoleProps>()(Role)

import { post } from './tools'
import * as config from './config'

export const noticeList = async (params: any) => {
  return await post({
    url: config.NOTICE_LIST,
    data: params,
  })
}
export const noticeOne = async (id: any) => {
  return await post({
    url: config.NOTICE_ONE,
    data: { topicId: id },
  })
}
export const noticeSave = async (params: any) => {
  return await post({
    url: config.NOTICE_SAVE,
    data: params,
  })
}
export const noticeDel = async (ids: any[]) => {
  return await post({
    url: config.NOTICE_DEL,
    data: { topicIds: ids.join(',') },
  })
}

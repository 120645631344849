import { post } from './tools'
import * as config from './config'

export const quesList = async (params: any) => {
  return await post({
    url: config.QUES_LIST,
    data: params,
  })
}

export const quesOne = async (id: any) => {
  return await post({
    url: config.QUES_ONE,
    data: { subjectId: id },
  })
}

export const quesSave = async (params: any) => {
  return await post({
    url: config.QUES_SAVE,
    data: params,
  })
}

export const quesDel = async (ids: any[]) => {
  return await post({
    url: config.QUES_DEL,
    data: { subjectIds: ids.join(',') },
  })
}

export const quesImp = async (params: any) => {
  return await post({
    url: config.QUES_IMP,
    data: {
      fileJson: JSON.stringify(params),
    },
  })
}

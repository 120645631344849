import { post } from './tools'
import * as config from './config'

export const permList = async (params: any) => {
  return await post({
    url: config.PERM_LIST,
    data: params,
  })
}
export const permOne = async (id: any) => {
  return await post({
    url: config.PERM_ONE,
    data: { permId: id },
  })
}
export const permSave = async (params: any) => {
  return await post({
    url: config.PERM_SAVE,
    data: params,
  })
}
export const permDel = async (ids: any[]) => {
  return await post({
    url: config.PERM_DEL,
    data: { permIds: ids.join(',') },
  })
}
export const permTree = async (permId: any) => {
  return await post({
    url: config.PERM_TREE,
    data: {
      permId: permId,
    },
  })
}

import * as React from 'react'
import { Tabs, Button, Modal } from 'antd'
import ComTestQuesRandom from './TestQuesRandom'
import ComTestQuesManual from './TestQuesManual'
import { testSelQuesManual, testSelQuesRandomNow } from './../../service/test'

const { TabPane } = Tabs
type ITestQuesOfPaperProps = {
  paperId?: any
  proposition?: any
  selIds?: any
  onConfirm: (e: any) => void
}

type ITestQuesOfPaperStates = {
  randomParams: any
  manualParams: any
  tabActive: any
  testQuesDialogVisible: boolean
}

export default class TestQuesOfPaper extends React.Component<
  ITestQuesOfPaperProps,
  ITestQuesOfPaperStates
> {
  constructor(props: any) {
    super(props)
    this.state = {
      randomParams: [],
      manualParams: [],
      tabActive: '1',
      testQuesDialogVisible: false,
    }
    this.onRandomQuesChange = this.onRandomQuesChange.bind(this)
    this.onManualQuesChange = this.onManualQuesChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.callback = this.callback.bind(this)
  }

  componentWillReceiveProps(props: any) {
    if (props.proposition) {
      this.setState({
        tabActive: props.proposition,
      })
    }
  }

  onRandomQuesChange(groupList: any) {
    const papRandJson: any = []
    groupList.forEach((group: any) => {
      group.subjectTypeJson.forEach((subjType: any) => {
        papRandJson.push({
          ...subjType,
          groupId: group.groupId,
        })
      })
    })
    setTimeout(() => {
      this.setState({
        randomParams: papRandJson,
      })
    }, 0)
  }
  onManualQuesChange(groupList: any) {
    const params = groupList.map((item: any) => {
      return {
        ...item,
        operType: 1,
      }
    })
    this.setState({
      manualParams: params,
    })
  }

  callback(key: any) {
    setTimeout(() => {
      this.setState({
        tabActive: key,
      })
    }, 0)
  }

  async handleSubmit() {
    this.setState({
      testQuesDialogVisible: false,
    })
    const result = {
      proposition: this.state.tabActive,
      randomParams: this.state.randomParams,
      manualParams: this.state.manualParams,
    }
    if (this.state.tabActive == '1') {
      const params = {
        paperId: this.props.paperId,
        papRandJson: JSON.stringify(this.state.randomParams),
      }
      const res = await testSelQuesRandomNow(params)
      if (res.success) {
        if (this.props.onConfirm) {
          this.props.onConfirm!(result)
        }
      }
    } else if (this.state.tabActive == '2') {
      const params = {
        paperId: this.props.paperId,
        papSubjJson: JSON.stringify(this.state.manualParams),
      }
      const res = await testSelQuesManual(params)
      if (res.success) {
        if (this.props.onConfirm) {
          this.props.onConfirm!(result)
        }
      }
    }
  }

  public render() {
    return (
      <div>
        <Modal
          width="60%"
          title="添加试题"
          visible={this.state.testQuesDialogVisible}
          onOk={this.handleSubmit}
          onCancel={() => {
            this.setState({
              testQuesDialogVisible: false,
            })
          }}
        >
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab={<span>随机选题</span>} key="1">
              <ComTestQuesRandom handleChange={this.onRandomQuesChange} />
            </TabPane>
            <TabPane tab={<span>手动选题</span>} key="2">
              <ComTestQuesManual
                defaultSelIds={this.props.selIds}
                handleChange={this.onManualQuesChange}
              />
            </TabPane>
          </Tabs>
        </Modal>

        <Button
          type="primary"
          onClick={() => {
            setTimeout(() => {
              this.setState({
                testQuesDialogVisible: true,
              })
            }, 0)
          }}
        >
          添加试题
        </Button>
      </div>
    )
  }
}

import * as React from 'react'
import { Form, Button, InputNumber, DatePicker, Select, Radio, Modal, message, Spin } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { courseSettingList, courseSettingSave } from './../../service/course'
import { GroupType } from './../../utils/index'
import { fetchGroupList } from './../../service/index'
import moment from 'moment'

const { Option } = Select

const { RangePicker } = DatePicker
type ICourseSettingProps = {
  onNextStep?: (e: any) => void
  courseId: any
} & FormComponentProps
type ICourseSettingStates = {
  stuGroupList: any
  editDialogVisible: boolean
  loading: boolean
}

class CourseSetting extends React.Component<ICourseSettingProps, ICourseSettingStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      stuGroupList: [],
      editDialogVisible: false,
      loading: false,
    }
    this.getStuGroupList = this.getStuGroupList.bind(this)
    this.getSettings = this.getSettings.bind(this)
  }

  componentDidMount() {
    this.getStuGroupList()
    this.getSettings()
  }

  componentWillReceiveProps(props: any) {
    if (props.courseId && props.courseId != this.props.courseId) {
      this.props.form.resetFields()
      this.getSettings()
    }
  }

  getStuGroupList = async () => {
    const params = {
      groupType: GroupType.STU,
    }
    const res = await fetchGroupList(params)
    if (res.success) {
      const stuGroupList = res.data
      this.setState({ stuGroupList: stuGroupList })
    }
  }

  getSettings = async () => {
    if (this.props.courseId) {
      this.setState({
        loading: true,
      })
      const res = await courseSettingList({ courseId: this.props.courseId })
      if (res.success) {
        const settings = res.data
        console.log(settings)
        if (settings) {
          settings.forEach((setting: any) => {
            if (setting.title == 'stuGroup') {
              const option0 = setting.option0.split(',')
              this.props.form.setFieldsValue({ stuGroupList: option0 })
            } else if (setting.title == 'pageTime') {
              this.props.form.setFieldsValue({ pageTime: parseInt(setting.option0) })
            } else if (setting.title == 'popTime') {
              this.props.form.setFieldsValue({ popTime: parseInt(setting.option0) })
            } else if (setting.title == 'isValid') {
              const isValid = parseInt(setting.option0)
              this.props.form.setFieldsValue({ isValid: isValid })
              if (isValid == 2) {
                const option0Json = JSON.parse(setting.opt0Json)
                const dateFormat = 'YYYY-MM-DD'
                const rangeDate = [
                  moment(option0Json.invalidStartDate, dateFormat),
                  moment(option0Json.invalidEndDate, dateFormat),
                ]
                this.props.form.setFieldsValue({ rangeDate: rangeDate })
              }
            }
          })
        }
      }
      this.setState({
        loading: false,
      })
    }
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    this.props.form &&
      this.props.form.validateFields(async (err: any, values: any) => {
        if (!err) {
          console.log('Received values of form: ', values)
          if (this.props.courseId) {
            this.setState({
              loading: true,
            })
            const courSetJson = []
            const stuGroupList = {
              setType: 1,
              setDesc: '试卷权限',
              title: 'stuGroup',
              option0: values.stuGroupList.join(','),
            }
            courSetJson.push(stuGroupList)

            const pageTime = {
              setType: 1,
              setDesc: '有效翻页时长',
              title: 'pageTime',
              option0: values.pageTime,
            }
            courSetJson.push(pageTime)

            const popTime = {
              setType: 1,
              setDesc: '弹窗间隔时长',
              title: 'popTime',
              option0: values.popTime,
            }
            courSetJson.push(popTime)

            const dateFormat = 'YYYY-MM-DD'
            const isValid = {
              setType: 1,
              setDesc: '课程有效期',
              title: 'isValid',
              option0: values.isValid,
              opt0Json:
                values.isValid == 2
                  ? {
                      invalidStartDate: moment(values.rangeDate[0]).format(dateFormat),
                      invalidEndDate: moment(values.rangeDate[1]).format(dateFormat),
                    }
                  : '',
            }
            courSetJson.push(isValid)

            const params = {
              courseId: this.props.courseId,
              courSetJson: JSON.stringify(courSetJson),
            }
            const res = await courseSettingSave(params)
            if (res.success) {
              if (this.props.onNextStep) {
                this.props.onNextStep({ courseId: this.props.courseId })
              } else {
                this.setState({
                  editDialogVisible: false,
                })
              }
            } else {
              message.error(res.msg || '保存失败')
            }
            this.setState({
              loading: false,
            })
          }
        }
      })
  }
  public render() {
    const form = this.props.form!
    const { getFieldDecorator } = this.props.form!
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }
    const body = (
      <Spin spinning={this.state.loading}>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item label="学习权限">
            {getFieldDecorator('stuGroupList', {
              rules: [{ required: true, message: '请选择学习权限!' }],
            })(
              <Select
                mode="tags"
                placeholder="请选择学习权限"
                onChange={() => {}}
                style={{ width: '100%' }}
              >
                {this.state.stuGroupList &&
                  this.state.stuGroupList.map((stu: any) => (
                    <Option key={stu.groupId}>{stu.groupName}</Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="有效翻页时长（秒）">
            {getFieldDecorator('pageTime', {
              rules: [{ required: true, message: '请填写有效翻页时长!' }],
            })(
              <InputNumber
                size="small"
                min={10}
                max={1000}
                onChange={(value) => {
                  form.setFieldsValue({ pageTime: value })
                }}
              />
            )}
          </Form.Item>
          <Form.Item label="防作弊弹窗间隔时长（秒）">
            {getFieldDecorator('popTime', {
              rules: [{ required: true, message: '请填写弹窗间隔时长!' }],
            })(
              <InputNumber
                size="small"
                min={10}
                max={1000}
                onChange={(value) => {
                  form.setFieldsValue({ popTime: value })
                }}
              />
            )}
          </Form.Item>
          <Form.Item label="课程有效期">
            {getFieldDecorator('isValid', {
              rules: [{ required: true, message: '请输入试卷有效期!' }],
            })(
              <Radio.Group>
                <Radio value={1}>永久</Radio>
                <Radio value={2}>固定时间</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          {form.getFieldValue('isValid') == 2 && (
            <Form.Item label="课程有效时段">
              {getFieldDecorator('rangeDate', {
                rules: [{ required: true, message: '请输入试卷有效期!' }],
              })(<RangePicker />)}
            </Form.Item>
          )}
          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 },
            }}
          >
            {this.props.onNextStep && (
              <Button type="primary" htmlType="submit">
                保存
              </Button>
            )}
          </Form.Item>
        </Form>
      </Spin>
    )
    return (
      <div>
        {this.props.onNextStep && body}
        {!this.props.onNextStep && (
          <React.Fragment>
            <Modal
              width="60%"
              title="课程设置"
              forceRender
              visible={this.state.editDialogVisible}
              onOk={this.handleSubmit}
              onCancel={() => {
                this.setState({
                  editDialogVisible: false,
                })
              }}
            >
              {body}
            </Modal>
            <Button
              icon="setting"
              type="primary"
              onClick={() => {
                this.setState({
                  editDialogVisible: true,
                })

                this.props.form.resetFields()
                this.getSettings()
              }}
            >
              设置
            </Button>
          </React.Fragment>
        )}
      </div>
    )
  }
}
const CourseSettingForm = Form.create<ICourseSettingProps>()(CourseSetting)
export default CourseSettingForm

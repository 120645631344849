import { post } from './tools'
import * as config from './config'

export const certsList = async (params: any) => {
  return await post({
    url: config.CERT_LIST,
    data: params,
  })
}

export const certsDel = async (ids: any[]) => {
  return await post({
    url: config.CERT_DEL,
    data: { ccieIds: ids.join(',') },
  })
}

export const certsAward = async (params: any) => {
  return await post({
    url: config.CERT_ADD,
    data: params,
  })
}

export const genarateCertNo = async (params: any) => {
  return await post({
    url: config.CERT_NO_GENE,
    data: params,
  })
}

export const certsImp = async (params: any) => {
  return await post({
    url: config.CERT_IMP,
    data: {
      fileJson: JSON.stringify(params),
    },
  })
}

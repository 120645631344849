import * as React from 'react'
import {
  Table,
  message,
  Form,
  Button,
  Popconfirm,
  Card,
  Radio,
  Row,
  Col,
  Descriptions,
  InputNumber,
} from 'antd'
import { Link } from 'react-router-dom'
import { QuesType } from './../../utils/index'
import { testOne } from './../../service/test'
import BreadcrumbCustom from '../BreadcrumbCustom'
import TestQuesOfPaper from './TestQuesOfPaper'
import ComTestQuesRandom from './TestQuesRandom'
import { testSelQuesRandom, testSelQuesManual, testSelQuesManualRandom } from './../../service/test'
import { testSave } from './../../service/test'
import QuesBlock from '../widget/QuesBlock'

const { Column } = Table

export interface ITestQuesEditProps {
  onNextStep?: (e: any) => void
  onLastStep?: (e: any) => void
  paperId?: any
}
interface ITestQuesEditStates {
  groupId: any
  checkedList: any
  paperId: any
  proposition: any
  paper: any
  totalScore: number
  totalSubjNum: number
  subjIds: any
  edit: boolean
  testTimeDisabled: boolean
  loading: boolean
}

export default class TestQuesEdit extends React.Component<ITestQuesEditProps, ITestQuesEditStates> {
  constructor(props: any) {
    super(props)
    this.caculateTotalScore = this.caculateTotalScore.bind(this)
    this.getTestOne = this.getTestOne.bind(this)
    this.randomTestChanged = this.randomTestChanged.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.modPaperTime = this.modPaperTime.bind(this)

    this.state = {
      groupId: '',
      checkedList: [],
      paperId: props.query ? props.query.paperId : this.props.paperId,
      proposition: 1,
      paper: {},
      totalScore: 0,
      totalSubjNum: 0,
      subjIds: [],
      testTimeDisabled: true,
      edit: false,
      loading: false,
    }
  }
  componentDidMount() {
    this.getTestOne()
  }

  componentWillReceiveProps(props: any) {
    if (props.paperId) {
      this.getTestOne()
    }
  }

  getTestOne = async () => {
    this.setState({
      loading: true,
    })
    const paperId = this.props.paperId ? this.props.paperId : this.state.paperId
    if (!paperId) {
      return
    }
    const res = await testOne(paperId)
    const paper = res.data[0]
    if (!paper) {
      return
    }
    const subjIds = paper.papSubjJson.map((item: any) => {
      return item.subjectId
    })
    setTimeout(() => {
      paper.papSubjJson = paper.papSubjJson.map((item: any, index: number) => {
        return {
          ...item,
          no: index + 1,
        }
      })
      this.setState({
        paper: paper,
        subjIds,
        proposition: paper.proposition || 1,
        loading: false,
      })

      this.caculateTotalScore(paper.proposition)
    }, 0)
  }

  caculateTotalScore = (proposition: any) => {
    if (proposition == 1) {
      const papSubjJson = this.state.paper.papSubjJson || []
      let totalScore = 0
      papSubjJson.forEach((ques: any) => {
        totalScore += ques.solScore
      })
      setTimeout(() => {
        this.setState({
          totalScore: totalScore,
          totalSubjNum: papSubjJson.length,
        })
      }, 0)
    } else if (proposition == 2) {
      const papRandJson = this.state.paper.papRandJson
      let totalScore = 0
      let totalSubjNum = 0
      if (papRandJson) {
        papRandJson.forEach((ques: any) => {
          totalScore += ques.solScore * ques.subjNum
          totalSubjNum += ques.subjNum
        })
      }
      setTimeout(() => {
        this.setState({
          totalScore: totalScore,
          totalSubjNum: totalSubjNum,
        })
      }, 0)
    } else if (proposition == 3) {
      let totalScore = 0
      const papSubjJson = this.state.paper.papSubjJson || []
      papSubjJson.forEach((ques: any) => {
        totalScore += ques.solScore
      })

      let totalSubjNum = papSubjJson.length
      const papRandJson = this.state.paper.papRandJson
      if (papRandJson) {
        papRandJson.forEach((ques: any) => {
          totalScore += ques.solScore * ques.subjNum
          totalSubjNum += ques.subjNum
        })
      }
      setTimeout(() => {
        this.setState({
          totalScore: totalScore,
          totalSubjNum: totalSubjNum,
        })
      }, 0)
    }
  }

  onDeleteConfirm = async (e: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请先选择题目')
      return
    }
    this.deleteQues(this.state.checkedList)
  }

  deleteQues = async (ids: any) => {
    const params = ids.map((item: any) => {
      return {
        subjectId: item,
        operType: 3,
      }
    })
    const res = await testSelQuesManual({
      paperId: this.state.paperId,
      papSubjJson: JSON.stringify(params),
    })
    if (res.success) {
      message.success('删除成功')
      this.getTestOne()
    } else {
      message.error('删除失败')
    }
  }

  onDeleteCancel = (e: any) => {}

  randomTestChanged = (e: any) => {
    const papRandJson: any[] = []
    e.forEach((group: any) => {
      group.subjectTypeJson.forEach((subjType: any) => {
        papRandJson.push({
          ...subjType,
          groupId: group.groupId,
        })
      })
    })
    this.state.paper.papRandJson = papRandJson
    setTimeout(() => {
      this.setState({
        paper: this.state.paper,
      })
      this.caculateTotalScore(this.state.proposition)
    }, 0)
  }
  onQuesChooseConfirm = (e: any) => {
    this.getTestOne()
  }

  modPaperTime = async (paperTime: any) => {
    const params = {
      paperId: this.state.paperId,
      examDura: paperTime * 60,
      paperType: 1,
      isSetFile: 0,
    }
    const res = await testSave(params)
    if (!res.success) {
      message.error('考试时长修改失败！')
    } else {
      this.state.paper.examDura = paperTime * 60
      setTimeout(() => {
        this.setState({
          testTimeDisabled: true,
          paper: this.state.paper,
        })
      }, 0)
    }
  }

  async handleSubmit() {
    if (!this.state.paper.examDura || this.state.paper.examDura == 0) {
      message.error('请填写考试总时长')
      return
    }
    this.setState({
      loading: true,
    })
    if (this.state.proposition == 2) {
      const res = await testSelQuesRandom({
        paperId: this.state.paperId,
        proposition: 2,
        papRandJson: JSON.stringify(this.state.paper.papRandJson),
      })
      if (res.success) {
        if (this.props.onNextStep) {
          this.props.onNextStep!({ paperId: this.state.paperId })
        } else {
          message.success('保存成功')
        }
      } else {
        message.error(res.msg || '保存失败')
      }
    } else if (this.state.proposition == 1) {
      const papSubjJson = this.state.paper.papSubjJson.map((item: any) => {
        return {
          subjectId: item.subjectId,
          solScore: item.solScore,
          operType: 2,
        }
      })
      const res = await testSelQuesManual({
        paperId: this.state.paperId,
        papSubjJson: JSON.stringify(papSubjJson),
      })
      if (res.success) {
        if (this.props.onNextStep) {
          this.props.onNextStep!({ paperId: this.state.paperId })
        } else {
          message.success('保存成功')
        }
      } else {
        message.error(res.msg || '保存失败')
      }
    } else if (this.state.proposition == 3) {
      const papSubjJson = this.state.paper.papSubjJson.map((item: any) => {
        return {
          subjectId: item.subjectId,
          solScore: item.solScore,
          operType: 2,
        }
      })
      const res = await testSelQuesManualRandom({
        paperId: this.state.paperId,
        proposition: 2,
        papRandJson: JSON.stringify(this.state.paper.papRandJson),
        papSubjJson: JSON.stringify(papSubjJson),
      })
      if (res.success) {
        if (this.props.onNextStep) {
          this.props.onNextStep!({ paperId: this.state.paperId })
        } else {
          message.success('保存成功')
        }
      } else {
        message.error(res.msg || '保存失败')
      }
    }
    this.setState({
      loading: false,
    })
  }

  onTabChanged = (e: any) => {
    setTimeout(() => {
      this.setState({
        proposition: e.target.value,
      })
      this.caculateTotalScore(e.target.value)
    }, 0)
  }

  public render() {
    const rowSelection = {
      onChange: (selectedRowKeys: any) => {
        this.setState({
          checkedList: selectedRowKeys,
        })
      },
    }

    return (
      <div className="w-full h-full">
        {!this.props.paperId && (
          <BreadcrumbCustom
            first="试卷管理"
            second={<Link to={'/app/testpaper/index'}>试卷列表</Link>}
            third="试题管理"
          />
        )}
        <div className="title mb-m mt-m">{this.state.paper.title}</div>
        <Radio.Group onChange={this.onTabChanged} value={this.state.proposition}>
          <Radio.Button value={1}>固定选题</Radio.Button>
          <Radio.Button value={2}>随机选题</Radio.Button>
          <Radio.Button value={3}>固定+随机选题</Radio.Button>
        </Radio.Group>
        <Row className="mt-m" gutter={16}>
          <Col span={19}>
            {(this.state.proposition == 1 || this.state.proposition == 3) && (
              <Card title="固定选题" className="mb-m">
                <Form layout="inline" style={{ marginBottom: 16 }}>
                  <Form.Item>
                    <TestQuesOfPaper
                      selIds={this.state.subjIds}
                      paperId={this.state.paperId}
                      onConfirm={this.onQuesChooseConfirm}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Popconfirm
                      title="确定要删除试题吗"
                      onConfirm={this.onDeleteConfirm}
                      onCancel={this.onDeleteCancel}
                      okText="删除"
                      cancelText="取消"
                    >
                      <Button icon="delete" type="danger" ghost>
                        删除试题
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      icon="edit"
                      type="primary"
                      ghost
                      onClick={() => {
                        this.setState({
                          edit: !this.state.edit,
                        })
                      }}
                    >
                      编辑分数
                    </Button>
                  </Form.Item>
                </Form>
                <Table
                  dataSource={this.state.paper.papSubjJson}
                  expandedRowRender={(item) => <QuesBlock row={item} />}
                  rowSelection={rowSelection}
                  rowKey="subjectId"
                  pagination={false}
                  loading={this.state.loading}
                >
                  <Column title="序号" dataIndex="no" key="no" render={(no: any) => no} />
                  <Column
                    title="标题"
                    width="100"
                    dataIndex="title"
                    key="title"
                    render={(title: any) => (
                      <span dangerouslySetInnerHTML={{ __html: title }}></span>
                    )}
                  />
                  <Column
                    title="作者"
                    width={80}
                    dataIndex="loginName"
                    key="loginName"
                    render={(loginName: any) => loginName}
                  />
                  <Column
                    title="分类"
                    width={80}
                    dataIndex="groupNames"
                    key="groupNames"
                    render={(groupNames: any) => groupNames}
                  />
                  <Column
                    title="题型"
                    width={80}
                    dataIndex="subjectType"
                    key="subjectType"
                    render={(subjectType: any) => {
                      let subjectTypeStr: any = ''
                      switch (subjectType) {
                        case QuesType.SINGLE_SEL:
                          subjectTypeStr = '单选题'
                          break
                        case QuesType.MULTI_SEL:
                          subjectTypeStr = '多选题'
                          break
                        case QuesType.TRUE_FLS:
                          subjectTypeStr = '判断题'
                          break
                      }
                      return subjectTypeStr
                    }}
                  />
                  <Column
                    title="分数"
                    width={80}
                    dataIndex="solScore"
                    key="solScore"
                    render={(solScore: any, row: any) => (
                      <span>
                        {!this.state.edit && solScore}
                        {this.state.edit && (
                          <InputNumber
                            size="small"
                            min={0}
                            max={100}
                            value={parseInt(solScore)}
                            onChange={(value) => {
                              row.solScore = value
                              this.state.paper.papSubjJson[row.no - 1] = row
                              setTimeout(() => {
                                this.setState({ paper: this.state.paper })
                                this.caculateTotalScore(this.state.proposition)
                              }, 0)
                            }}
                          />
                        )}
                      </span>
                    )}
                  />
                </Table>
              </Card>
            )}
            {this.state.proposition != 1 && (
              <Card title="随机选题" className="mb-m">
                <ComTestQuesRandom
                  randList={this.state.paper.papRandJson}
                  handleChange={this.randomTestChanged}
                />
              </Card>
            )}
          </Col>
          <Col span={5}>
            <Card title="考试时长">
              <Descriptions>
                <Descriptions.Item span={3} label="总时长">
                  <InputNumber
                    size="small"
                    min={0}
                    max={200}
                    disabled={this.state.testTimeDisabled}
                    value={this.state.paper.examDura / 60 || 0}
                    onBlur={(e) => {
                      this.modPaperTime(e.target.value)
                    }}
                  />
                  <Button
                    type="link"
                    icon="edit"
                    onClick={() => {
                      this.setState({
                        testTimeDisabled: !this.state.testTimeDisabled,
                      })
                    }}
                  >
                    修改
                  </Button>
                </Descriptions.Item>
                <Descriptions.Item label="总分">{this.state.totalScore || 0}</Descriptions.Item>
                <Descriptions.Item label="总题数">{this.state.totalSubjNum || 0}</Descriptions.Item>
              </Descriptions>
              <Button className="mt-m" type="primary" block onClick={this.handleSubmit}>
                {!this.props.onNextStep && '保存'}
                {this.props.onNextStep && '下一步'}
              </Button>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

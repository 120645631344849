/*
 * File: Copyright.tsx
 * Desc: 版权信息
 * File Created: 2020-04-12 22:50:33
 * Author: chenghao
 * ------
 * Copyright 2020 - present, karakal
 */
import React from 'react'

const Copyright = () => {
  return <div>Wazert ©{new Date().getFullYear()}</div>
}

export default Copyright

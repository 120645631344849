import * as React from 'react'
import { Collapse, Icon, Tag } from 'antd'
import GroupMultiSel from '../widget/GroupMultiSel'
import QuesExtractPanel from './QuesExtractPanel'
import { GroupType, quesTypeArr, quesTypeName } from '../../utils/index'
import { testQuesGroup } from './../../service/test'

export interface ITestQuesRandomProps {
  randList?: any
  handleChange: (e: any) => void
}
export interface ITestQuesRandomStates {
  groupList: any[]
  totalQuesNum: any
  totalQuesScore: any
}

const { Panel } = Collapse

export default class TestQuesRandom extends React.Component<
  ITestQuesRandomProps,
  ITestQuesRandomStates
> {
  constructor(props: any) {
    super(props)
    this.onGroupFieldsChanged = this.onGroupFieldsChanged.bind(this)
    this.onDeleteGroup = this.onDeleteGroup.bind(this)
    this.onGroupChange = this.onGroupChange.bind(this)
    this.onDeleteGroup = this.onDeleteGroup.bind(this)
    this.getGroupListByIds = this.getGroupListByIds.bind(this)
    this.state = {
      groupList: [],
      totalQuesNum: 0,
      totalQuesScore: 0,
    }
  }

  componentWillReceiveProps(props: any) {
    if (props.randList && this.state.groupList.length == 0) {
      this.getGroupListByIds(props.randList)
    }
  }

  componentDidMount() {
    if (this.props.randList && this.state.groupList.length == 0) {
      this.getGroupListByIds(this.props.randList)
    }
  }

  getGroupListByIds(randList: any) {
    const groupIds = randList.map((item: any) => item.groupId)
    if (groupIds) {
      this.getQuesGroup(Array.from(new Set(groupIds)))
    }
  }

  /**
   *
   * @param groupIds 根据已有数据取Group
   */
  getQuesGroup = async (groupIds: any) => {
    const params = {
      groupIds: groupIds.join(','),
    }
    const res = await testQuesGroup(params)
    if (res.success) {
      const groupList = res.data

      let totalQuesNum = 0
      let totalQuesScore = 0
      groupList.forEach((group: any) => {
        if (this.props.randList) {
          // 如果是修改的话，将原始数据的值填充
          let subjNum = 0 // 每组试题总数
          let solScore = 0
          group.subjectTypeJson.forEach((groupRand: any) => {
            const randJson = this.props.randList.find(
              (e: any) =>
                (e.groupId == group.groupId || (!e.groupId && !group.groupId)) &&
                e.subjectType == groupRand.subjectType
            )
            if (randJson) {
              groupRand.subjNum = randJson.subjNum
              groupRand.solScore = randJson.solScore
              groupRand.papRandId = randJson.papRandId
              subjNum += randJson.subjNum
              solScore += randJson.solScore * randJson.subjNum
            }
          })
          group.subjNum = subjNum
          group.solScore = solScore
          totalQuesNum += subjNum
          totalQuesScore += solScore
        }
      })
      setTimeout(() => {
        this.setState({
          groupList: groupList,
          totalQuesNum: totalQuesNum,
          totalQuesScore: totalQuesScore,
        })
      }, 0)
    }
  }

  onDeleteGroup(groupId: any) {
    const groupList = this.state.groupList
    const index = groupList.findIndex((item) => item.groupId == groupId)
    groupList.splice(index, 1)
    this.setState({
      groupList: groupList,
    })
  }

  extra = (groupId: any) => {
    return (
      <Icon
        type="delete"
        onClick={(e) => {
          this.onDeleteGroup(groupId)
        }}
      />
    )
  }

  onGroupChange = (e: any) => {
    console.log(e)
    const groupIdArray = e.map((item: any) => {
      return item.groupId
    })
    this.getQuesGroup(groupIdArray)
  }

  onGroupFieldsChanged(e: any) {
    let index = 0
    let groupList = this.state.groupList
    if (e.groupId) {
      index = groupList.findIndex((item) => item.groupId == e.groupId)
    }
    const group = groupList[index]
    const subjTypeIndex = group.subjectTypeJson.findIndex(
      (item: any) => item.subjectType == e.subjectType
    )
    const subjectType = group.subjectTypeJson[subjTypeIndex]
    if (subjectType) {
      subjectType.subjNum = e.subjNum
      subjectType.solScore = e.solScore
    }

    let totalQuesNum = 0
    let totalQuesScore = 0
    groupList = groupList.map((item: any) => {
      let subjNum = 0 // 每组的试题数目
      let solScore = 0
      item.subjectTypeJson.forEach((qt: any) => {
        subjNum += qt.subjNum
        solScore += qt.subjNum * qt.solScore
      })
      totalQuesNum += subjNum
      totalQuesScore += solScore
      return {
        ...item,
        subjNum: subjNum,
        solScore: solScore,
      }
    })
    setTimeout(() => {
      this.setState({
        groupList: groupList,
        totalQuesNum: totalQuesNum,
        totalQuesScore: totalQuesScore,
      })
    }, 0)
    this.props.handleChange(groupList)
  }

  public render() {
    return (
      <div>
        <div>
          <span>
            将从<span className="text-info mr-s ml-s f-b">{this.state.groupList.length}</span>
            个分类中随机抽取
            <span className="text-info mr-s ml-s f-b">{this.state.totalQuesNum}</span>
            道试题。
          </span>
          <span className="ml-m">
            总共<span className="text-info mr-s ml-s f-b">{this.state.totalQuesScore}</span>分
          </span>
        </div>
        {this.state.groupList && this.state.groupList.length > 0 && (
          <Collapse className="mt-m" defaultActiveKey={['1']} expandIconPosition="left">
            {this.state.groupList.map((group: any, index: number) => (
              <Panel
                header={
                  <div style={{ width: '80%', display: 'inline-block' }}>
                    <span>
                      {group.groupName}({group.subjectNum} 道试题)
                    </span>
                    <span className="ml-m">
                      抽选<span className="f-b">{group.subjNum}</span>道试题{' '}
                    </span>
                    <span className="ml-m mr-m">
                      {' '}
                      总共<span className="f-b">{group.solScore}</span>分
                    </span>
                    {group.subjectTypeJson.map((ques: any) => {
                      return (
                        <Tag color="blue" key={'tag' + ques.subjectType}>
                          {quesTypeName(ques.subjectType) + '   '}
                          {ques.subjNum}
                        </Tag>
                      )
                    })}
                  </div>
                }
                key={index}
                extra={this.extra(group.groupId)}
              >
                {group.subjectTypeJson.map((quesTypeObj: any) => (
                  <QuesExtractPanel
                    group={{ ...quesTypeObj, groupId: group.groupId }}
                    key={group.groupId + '_' + quesTypeObj.subjectType}
                    onChange={this.onGroupFieldsChanged}
                  />
                ))}
              </Panel>
            ))}
          </Collapse>
        )}
        <GroupMultiSel type={GroupType.QUES} title="选择试题分类" onChange={this.onGroupChange} />
      </div>
    )
  }
}

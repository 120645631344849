import * as React from 'react'
import { Form, Radio, Input, InputNumber, Button, Modal, Spin, message } from 'antd'
import GroupSel from './../widget/GroupSel'
import Uploader from './../widget/Uploader'
import { FormComponentProps } from 'antd/lib/form'
import { GroupType } from './../../utils/index'
import { courseSave, courseOne } from './../../service/course'

export type ICourseInfoProps = {
  onNextStep?: (e: any) => void
  courseId?: any
} & FormComponentProps

type ICourseInfoStates = {
  groupId: any
  groupName: any
  title: any
  abstract: any
  courseType: number
  totalScore: number
  coverDirectURL: any
  isSetFile: number
  editDialogVisible: boolean
  loading: boolean
}

class CourseInfo extends React.Component<ICourseInfoProps, ICourseInfoStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      groupId: '',
      groupName: '',
      title: '',
      abstract: '',
      courseType: 1,
      totalScore: 0,
      coverDirectURL: '',
      isSetFile: 0,
      editDialogVisible: false,
      loading: false,
    }
  }

  componentDidMount() {
    this.props.form.setFieldsValue({
      courseType: 1,
      totalScore: 0,
    })
    this.getCourseInfo()
  }

  getCourseInfo = async () => {
    if (this.props.courseId) {
      this.setState({
        loading: true,
      })
      const res = await courseOne(this.props.courseId)
      if (res.success) {
        const course = res.data[0]
        if (course) {
          const group = course.groupJson[0]
          this.props.form.setFieldsValue({
            courseType: course.courseType,
            title: course.title,
            abstract: course.abstract,
            totalScore: course.totalScore,
          })
          this.setState({
            ...course,
            groupId: group ? group.groupId : '',
            groupName: group ? group.groupName : '',
          })
        }
      }
      this.setState({
        loading: false,
      })
    }
  }

  handleSubmit = async () => {
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        })
        const params = {
          courseType: values.courseType,
          title: values.title,
          groupId: this.state.groupId,
          abstract: values.abstract,
          totalScore: values.totalScore,
          fileJson: JSON.stringify(values.fileList),
          isSetFile: this.state.isSetFile,
          courseId: this.props.courseId,
        }
        const res = await courseSave(params)
        if (res.success) {
          if (this.props.onNextStep) {
            const courseId = res.data[0].courseId
            this.props.onNextStep({ courseId: courseId })
          }
          this.setState({
            editDialogVisible: false,
          })
        } else {
          message.error(res.msg || '保存失败')
        }
        this.setState({
          loading: false,
        })
      }
    })
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const form = this.props.form!
    const body = (
      <Spin spinning={this.state.loading}>
        <Form onSubmit={this.handleSubmit} layout="horizontal" style={{ marginBottom: 16 }}>
          <Form.Item label="课程标题">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: '请输入标题!' }],
            })(<Input placeholder="请填写25个字以内的标题" />)}
          </Form.Item>
          <Form.Item label="课程封面">
            {getFieldDecorator('fileList', {
              rules: [{ required: false, message: '请上传课程封面!' }],
              valuePropName: 'fileList',
              normalize(e: any) {
                if (Array.isArray(e)) return e
                return e && e.fileList
              },
            })(
              <Uploader
                defaultImg={this.state.coverDirectURL}
                handleUploadFileChange={(fileList: any) => {
                  form.setFieldsValue({
                    fileList: fileList,
                  })
                  this.setState({
                    isSetFile: fileList.length > 0 ? 1 : 0,
                  })
                }}
              />
            )}
          </Form.Item>
          <Form.Item label="课程类别">
            <GroupSel
              type={GroupType.COURSE}
              title="课程类别"
              onChange={(e: any) => {
                this.setState({ groupId: e ? e.groupId : '', groupName: e.groupName })
                form.setFieldsValue({
                  groupId: e ? e.groupId : '',
                })
              }}
              groupId={this.state.groupId}
              groupName={this.state.groupName}
            />
          </Form.Item>
          <Form.Item label="课程简介">
            {getFieldDecorator('abstract', {
              rules: [{ required: false, message: '请输入课程简介!' }],
            })(<Input.TextArea rows={4} placeholder="课程简介" />)}
          </Form.Item>
          <Form.Item label="课程类型">
            {getFieldDecorator('courseType', {
              rules: [{ required: true, message: '请输入课程类型!' }],
            })(
              <Radio.Group
                onChange={(e) => {
                  this.setState({
                    courseType: e.target.value,
                  })
                }}
              >
                <Radio value={1}>必修课</Radio>
                <Radio value={2}>选修课</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="积分">
            {getFieldDecorator('totalScore', {
              rules: [{ required: true, message: '请输入积分!' }],
            })(<InputNumber min={0} max={100} />)}
          </Form.Item>
          {this.props.onNextStep && (
            <Form.Item>
              <Button className="mt-m" htmlType="submit" type="primary" ghost>
                下一步
              </Button>
            </Form.Item>
          )}
        </Form>
      </Spin>
    )
    return (
      <div>
        {this.props.onNextStep && body}
        {!this.props.onNextStep && (
          <React.Fragment>
            <Modal
              width="60%"
              title="编辑课程信息"
              forceRender={true}
              visible={this.state.editDialogVisible}
              onOk={this.handleSubmit}
              onCancel={() => {
                this.setState({
                  editDialogVisible: false,
                })
              }}
            >
              {body}
            </Modal>
            <Button
              icon="edit"
              type="primary"
              onClick={() => {
                this.setState({
                  editDialogVisible: true,
                })
                this.props.form.setFieldsValue({
                  courseType: 1,
                  title: '',
                  abstract: '',
                  totalScore: 0,
                })
                this.getCourseInfo()
              }}
            >
              编辑课程信息
            </Button>
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default Form.create<ICourseInfoProps>()(CourseInfo)

import * as React from 'react'
import { Tag } from 'antd'
import { QuesType, AlphaOptions } from './../../utils/index'
export interface IAppProps {}

export default (props: any) => {
  const row = props.row
  let subjectTypeStr: any = ''
  let answer: any = ''
  switch (row.subjectType) {
    case QuesType.SINGLE_SEL:
      subjectTypeStr = '单选题'
      answer = AlphaOptions[parseInt(row.correct) - 1]
      break
    case QuesType.MULTI_SEL:
      const multiAnswer = row.correct.split(',')
      const multi = multiAnswer.map((item: any) => {
        return AlphaOptions[parseInt(item) - 1]
      })
      subjectTypeStr = '多选题'
      answer = multi.join(',')
      break
    case QuesType.TRUE_FLS:
      subjectTypeStr = '判断题'
      answer = row.correct == 1 ? '对' : '错'
      break
  }

  const optionRender = (props: any) => {
    return (
      <div className="flex-h y-center">
        <span>
          <p>（{props.option}）</p>
        </span>
        <span
          dangerouslySetInnerHTML={{
            __html: props.optionContent,
          }}
        ></span>
      </div>
    )
  }

  return (
    <div className="c-text-gray">
      <div className="flex-h y-center">
        <p>
          <Tag color="blue">{subjectTypeStr}</Tag>
          <span dangerouslySetInnerHTML={{ __html: row.title }}></span>
        </p>
      </div>
      {row.option0 && optionRender({ option: 'A', optionContent: row.option0 })}
      {row.option1 && optionRender({ option: 'B', optionContent: row.option1 })}
      {row.option2 && optionRender({ option: 'C', optionContent: row.option2 })}
      {row.option3 && optionRender({ option: 'D', optionContent: row.option3 })}
      {row.option4 && optionRender({ option: 'E', optionContent: row.option4 })}
      {row.option5 && optionRender({ option: 'F', optionContent: row.option5 })}
      <div>
        {QuesType.TRUE_FLS == row.subjectType && (
          <span className="c-blue">
            正确答案：
            {row.correct == '1'
              ? optionRender({ option: 'A', optionContent: row.option0 })
              : optionRender({ option: 'B', optionContent: row.option1 })}
          </span>
        )}
        {QuesType.TRUE_FLS != row.subjectType && (
          <span className="c-blue">正确答案：{AlphaOptions[parseInt(row.correct) - 1]}</span>
        )}
      </div>
      <div className="flex-h y-center">
        <span>
          <p>题目解析：</p>
        </span>
        <span dangerouslySetInnerHTML={{ __html: row.analysis }}></span>
      </div>
      <div>
        <span>分类：{row.groupNames}</span>
      </div>
    </div>
  )
}

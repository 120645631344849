import { post } from './tools'
import * as config from './config'

export const platCertsNoListByOrgan = async (params: any) => {
  return await post({
    url: config.PLAT_CERTSNO_LIST,
    data: params,
  })
}

export const platCertsNoMod = async (params: any) => {
  return await post({
    url: config.PLAT_CERTSNO_MOD,
    data: params,
  })
}

/**
 * Created by 叶子 on 2017/7/30.
 * http通用工具函数
 */
import axios from 'axios'
import { message } from 'antd'
import qs from 'qs'
import umbrella from 'umbrella-storage'

interface IFRequestParam {
  url: string
  msg?: string
  config?: any
  data?: any
}

interface IFResponse {
  msg?: string
  data?: any
  success: boolean
  total: number
  headers: any
}
const defaultConfig = {
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
  },
  withCredentials: true,
}
/**
 * 公用get请求
 * @param url       接口地址
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const get = ({ url, msg = '接口异常', config = defaultConfig }: IFRequestParam) => {
  config.headers.Authorization = umbrella.getLocalStorage('token') || ''
  const rsessionid = umbrella.getLocalStorage('rsessionid')
  if (rsessionid) {
    config.headers.RSESSIONID = rsessionid
  }

  return axios
    .get(url, config)
    .then((res) => {
      const data = res.data
      const result: IFResponse = {
        success: data.msgStatsNum == 1,
        msg: data.msg,
        data: data.rows,
        total: data.total,
        headers: res.headers,
      }
      if (data.msgStatsNum == 10) {
        umbrella.removeLocalStorage('user')
      }
      return result
    })
    .catch((err) => {
      message.warn(msg)
      const result: IFResponse = {
        success: false,
        msg: msg,
        data: [],
        total: 0,
        headers: {},
      }
      return result
    })
}

/**
 * 公用post请求
 * @param url       接口地址
 * @param data      接口参数
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const post = ({ url, data, msg = '接口异常', config = defaultConfig }: IFRequestParam) => {
  if (!config.headers) {
    config.headers = {}
  }
  config.headers.Authorization = umbrella.getLocalStorage('token') || ''
  const rsessionid = umbrella.getLocalStorage('rsessionid')
  if (rsessionid) {
    config.headers.RSESSIONID = rsessionid
  }
  return axios
    .post(url, qs.stringify(data), config)
    .then((res) => {
      const data = res.data
      const result: IFResponse = {
        success: data.msgStatsNum == 1,
        msg: data.msg,
        data: data.rows,
        total: data.total,
        headers: res.headers,
      }
      if (data.msgStatsNum == 10) {
        umbrella.removeLocalStorage('user')
      }
      return result
    })
    .catch((err) => {
      message.warn(msg)
      const result: IFResponse = {
        success: false,
        msg: msg,
        data: [],
        total: 0,
        headers: {},
      }
      return result
    })
}

/**
 * 公用post请求
 * @param url       接口地址
 * @param data      接口参数
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const downBlob = ({
  url,
  data,
  msg = '接口异常',
  config = defaultConfig,
}: IFRequestParam): any => {
  if (!config.headers) {
    config.headers = {}
  }
  config.headers.Authorization = umbrella.getLocalStorage('token') || ''
  const rsessionid = umbrella.getLocalStorage('rsessionid')
  if (rsessionid) {
    config.headers.RSESSIONID = rsessionid
  }
  config.headers.responseType = 'blob'
  return axios
    .post(url, qs.stringify(data), config)
    .then((res) => {
      return res
    })
    .catch((err) => {
      message.warn(msg)

      return { error: err }
    })
}

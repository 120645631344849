import { Button, Form, Input, message, Modal, Select } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import * as _ from 'lodash'
import * as React from 'react'
import { organList, organOne, organSave } from './../../service/compOrgan'
import GlobalRegionCascader from './../widget/GlobalRegionCascader'
import { roleList } from '../../service/role'

export type IOrganAddProps = {
  onSuccess: () => void
  organId?: any
  buttonTitle: string
  icon: string
} & FormComponentProps

type IOrganAddStates = {
  groupId: any
  studentType: number
  organList: any[]
  editDialogVisible: boolean
  compName: string
  organName: string
  organId: any
  regiProvinId: any
  regiCityId: any
  regiCountrId: any
  provinName: any
  cityName: any
  countrName: any
  roleList: any[]
}

const { Option } = Select

class CompAdd extends React.Component<IOrganAddProps, IOrganAddStates> {
  constructor(props: any) {
    super(props)
    this.handleOk = this.handleOk.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.state = {
      groupId: '',
      studentType: 1,
      organList: [],
      editDialogVisible: false,
      compName: '',
      organName: '',
      organId: null,
      regiProvinId: null,
      regiCityId: null,
      regiCountrId: null,
      provinName: '',
      cityName: '',
      countrName: '',
      roleList: [],
    }
  }

  getComp = async () => {
    if (this.props.organId) {
      const res = await organOne(this.props.organId)
      if (res.success) {
        const acc = res.data[0]
        if (acc) {
          setTimeout(() => {
            this.setState({
              ...acc,
            })
          }, 0)
          this.props.form.setFieldsValue(
            _.pick(acc, [
              'organName',
              'compType',
              'organPId',
              'address',
              'ccieNoAreaCode0',
              'ccieNoStartPostfix',
              'remark',
              'roleId',
            ])
          )
          this.props.form.setFieldsValue({
            region: [acc.regiProvinId, acc.regiCityId, acc.regiCountrId],
          })
        }
      }
    }
  }

  getOrganList = async () => {
    const res = await organList({})
    if (res.success) {
      this.setState({
        organList: res.data,
      })
    }
  }

  getRoleList = async () => {
    const res = await roleList({})
    if (res.success) {
      this.setState({
        roleList: res.data,
      })
    }
  }

  searchOrgan = async (str: any) => {
    const params = {
      compName: str,
    }
    const res = await organList(params)
    if (res.success) {
      const result = res.data.map((item: any) => {
        return {
          text: item.organName,
          value: item.organId,
        }
      })
      return result
    }
    return []
  }

  handleOk = (e: any) => {
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
          organId: this.state.organId,
          regiProvinId: this.state.regiProvinId,
          regiCityId: this.state.regiCityId,
          regiCountrId: this.state.regiCountrId,
          provinName: this.state.provinName,
          cityName: this.state.cityName,
          countrName: this.state.countrName,
          region: [],
        }
        const res = await organSave(params)
        if (res.success) {
          message.success('保存成功')
          this.setState({
            editDialogVisible: false,
          })
          if (this.props.onSuccess) {
            this.props.onSuccess()
          }
        } else {
          message.error('保存失败')
        }
      }
    })
  }

  handleCancel = (e: any) => {
    console.log(e)
    this.setState({
      editDialogVisible: false,
    })
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const form = this.props.form!
    const compType = this.props.form.getFieldValue('compType')
    return (
      <div>
        <Modal
          width="40%"
          title={this.props.organId ? '编辑机构' : '添加机构'}
          visible={this.state.editDialogVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form layout="horizontal" style={{ marginBottom: 16 }}>
            <Form.Item label="机构名称">
              {getFieldDecorator('organName', {
                rules: [{ required: true, message: '请输入机构名称!' }],
              })(<Input placeholder="机构名称" />)}
            </Form.Item>
            <Form.Item label="选择父机构">
              {getFieldDecorator('organPId')(
                <Select style={{ width: 300 }} allowClear>
                  {this.state.organList.map((organ: any) => {
                    return (
                      <Option key={organ.organId} value={organ.organId}>
                        {organ.organName}
                      </Option>
                    )
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="默认管理员角色">
              {getFieldDecorator('roleId', {
                rules: [{ required: true, message: '请选择角色!' }],
              })(
                <Select style={{ width: 120 }}>
                  {this.state.roleList.map((role: any) => {
                    return (
                      <Option key={role.roleId} value={role.roleId}>
                        {role.roleName}
                      </Option>
                    )
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="所属区域">
              {getFieldDecorator('region', {
                rules: [{ required: true, message: '请选择所属地区!' }],
              })(
                <GlobalRegionCascader
                  maxLevel={3}
                  regiProvinId={this.state.regiProvinId}
                  provinName={this.state.provinName}
                  regiCityId={this.state.regiCityId}
                  cityName={this.state.cityName}
                  regiCountrId={this.state.regiCountrId}
                  onChange={(e: any) => {
                    if (e.length > 0) {
                      const value = e[0]
                      this.setState({
                        regiProvinId: value.value,
                        provinName: value.label,
                      })
                    }
                    if (e.length > 1) {
                      const value = e[1]
                      this.setState({
                        regiCityId: value.value,
                        cityName: value.label,
                      })
                    }
                    if (e.length > 2) {
                      const value = e[2]
                      this.setState({
                        regiCountrId: value.value,
                        countrName: value.label,
                      })
                    }
                  }}
                />
              )}
            </Form.Item>
            <Form.Item label="证书地区编号">
              {getFieldDecorator('ccieNoAreaCode0', {
                rules: [{ required: true, message: '请填写证书地区编号!' }],
              })(<Input placeholder="证书地区编号" />)}
            </Form.Item>
            <Form.Item label="证书编号后缀起始值">
              {getFieldDecorator('ccieNoStartPostfix', {
                rules: [{ required: true, message: '请填写证书编号后缀起始值!' }],
              })(<Input placeholder="证书编号后缀起始值" />)}
            </Form.Item>
            <Form.Item label="备注">
              {getFieldDecorator('remark')(<Input placeholder="备注" />)}
            </Form.Item>
          </Form>
        </Modal>
        <Button
          icon={this.props.icon}
          type="primary"
          onClick={() => {
            setTimeout(async () => {
              this.setState({
                editDialogVisible: true,
              })
              this.getRoleList()
              this.getOrganList()
              await this.getComp()
            }, 0)
          }}
        >
          {this.props.buttonTitle}
        </Button>
      </div>
    )
  }
}

export default Form.create<IOrganAddProps>()(CompAdd)

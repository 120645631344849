import * as React from 'react'
import { Card, Descriptions, Tag, Table, Collapse, Spin } from 'antd'
import { testOne } from './../../service/test'
import { QuesType } from '../../utils/index'
import { courseList } from './../../service/course'
import { fetchGroupList } from './../../service/index'
import QuesBlock from '../widget/QuesBlock'
import { GroupType } from './../../utils/index'
import { testQuesGroup } from './../../service/test'
import { group } from 'console'
import PaperStat from '../statistics/PaperStat'
const { Column } = Table
const { Panel } = Collapse

type ITestPapePreviewProps = {
  onNextStep?: (e: any) => void
  paperId?: any
}

type ITestPapePreviewStates = {
  paper: any
  paperId: any
  paperSetting: any
  reqCourseList: any[]
  optCourseList: any[]
  stuGroupList: any[]
  papSubjJson: any[]
  papRandJson: any[]
  total: number
  loading: boolean
  randGroupList: any[]
  randTotalQuesNum: number
  randTotalQuesScore: number
  manualTotalQuesScore: number
}

class TestPapePreview extends React.Component<ITestPapePreviewProps, ITestPapePreviewStates> {
  constructor(props: any) {
    super(props)
    this.getTestPaper = this.getTestPaper.bind(this)
    this.state = {
      paper: {},
      paperId: props.paperId,
      paperSetting: {},
      reqCourseList: [],
      optCourseList: [],
      stuGroupList: [],
      papSubjJson: [],
      papRandJson: [],
      total: 0,
      loading: false,
      randGroupList: [],
      randTotalQuesNum: 0,
      randTotalQuesScore: 0,
      manualTotalQuesScore: 0,
    }
  }

  componentDidMount() {
    setTimeout(async () => {
      this.setState({
        paperId: this.props.paperId,
        loading: true,
      })

      await this.getCourseList(1)
      await this.getCourseList(2)
      await this.getGroupList()
      this.getTestPaper()
    }, 0)
  }

  componentWillReceiveProps(props: any) {
    if (props.paperId && props.paperId != this.props.paperId) {
      setTimeout(async () => {
        this.setState({
          paperId: props.paperId,
          loading: true,
        })

        await this.getCourseList(1)
        await this.getCourseList(2)
        await this.getGroupList()
        this.getTestPaper()
      }, 0)
    }
  }

  getGroupList = async () => {
    const params = {
      groupType: GroupType.STU,
    }
    const res = await fetchGroupList(params)
    if (res.success) {
      const stuGroupList = res.data
      this.setState({ stuGroupList: stuGroupList })
    }
  }

  getTestPaper = async () => {
    if (this.state.paperId) {
      const res = await testOne(this.state.paperId)
      if (res.success) {
        const paper = res.data[0]
        if (paper) {
          setTimeout(() => {
            let manualTotalQuesScore = 0
            paper.papSubjJson.forEach((papSub: any) => {
              manualTotalQuesScore += papSub.solScore
            })
            this.setState({
              paper: paper,
              papSubjJson: paper.papSubjJson,
              papRandJson: paper.papRandJson,
              manualTotalQuesScore: manualTotalQuesScore,
            })

            if (paper.proposition != 1) {
              const groupIds = Array.from(new Set(paper.papRandJson.map((g: any) => g.groupId)))
              this.getQuesGroup(groupIds)
            } else {
              this.setState({
                loading: false,
              })
            }
            this.showSettings(paper.papSetJson)
          }, 0)
        }
      }
    }
  }

  getCourseList = async (courseType: number) => {
    const params = { courseType: courseType }
    const res = await courseList(params)
    if (res.success) {
      if (courseType == 1) {
        this.setState({
          reqCourseList: res.data,
        })
      } else if (courseType == 2) {
        this.setState({
          optCourseList: res.data,
        })
      }
    }
  }

  showSettings = async (settings: any) => {
    const paperSetting: {
      stuGroup: any[]
      requireScore: number
      reqCourses: any[]
      optScore: number
      optCourses: any[]
      courLineScore: number
      maxExamNum: number
      qualLineScore: number
      isValid: number
      validDate: String
    } = {
      stuGroup: [],
      requireScore: 0,
      reqCourses: [],
      optScore: 0,
      optCourses: [],
      courLineScore: 0,
      maxExamNum: 0,
      qualLineScore: 0,
      isValid: 1,
      validDate: '',
    }
    if (settings) {
      settings.forEach((setting: any) => {
        if (setting.title == 'stuGroup') {
          const option0 = setting.option0 ? setting.option0.split(',') : []
          const stuGroupList = this.state.stuGroupList.filter((item: any) =>
            option0.includes(item.groupId.toString())
          )
          const stuGroup = stuGroupList.map((item) => item.groupName)
          paperSetting.stuGroup = stuGroup
        } else if (setting.title == 'reqCourseIds') {
          const option0 = setting.option0 ? setting.option0.split(',') : []

          const reqList = this.state.reqCourseList.filter((item: any) =>
            option0.includes(item.courseId.toString())
          )
          if (reqList) {
            let reqScore = 0
            reqList.forEach((reqCrs: any) => {
              reqScore += reqCrs.totalScore
            })
            paperSetting.requireScore = reqScore

            const reqCourses = reqList.map((item) => item.title)
            paperSetting.reqCourses = reqCourses
          }
        } else if (setting.title == 'optCourseIds') {
          const option0 = setting.option0 ? setting.option0.split(',') : []

          const optList = this.state.optCourseList.filter((item: any) =>
            option0.includes(item.courseId.toString())
          )
          if (optList) {
            let optScore = 0
            optList.forEach((optCrs: any) => {
              optScore += optCrs.totalScore
            })

            paperSetting.optScore = optScore

            const optCourses = optList.map((item) => item.title)
            paperSetting.optCourses = optCourses
          }
        } else if (setting.title == 'courLineScore') {
          paperSetting.courLineScore = setting.option0
        } else if (setting.title == 'maxExamNum') {
          paperSetting.maxExamNum = setting.option0
        } else if (setting.title == 'qualLineScore') {
          paperSetting.qualLineScore = setting.option0
        } else if (setting.title == 'isValid') {
          const isValid = parseInt(setting.option0)
          paperSetting.isValid = isValid
          if (isValid == 2) {
            const option0Json = JSON.parse(setting.opt0Json)
            paperSetting.validDate = option0Json.invalidStartDate + '/' + option0Json.invalidEndDate
          } else {
            paperSetting.validDate = '永久'
          }
        }
      })
    }

    this.setState({
      paperSetting: paperSetting,
    })
  }

  Row = (subjectId: any, index: any) => {
    const row = this.state.papSubjJson[index]
    let subjectTypeStr: any = ''
    switch (row.subjectType) {
      case QuesType.SINGLE_SEL:
        subjectTypeStr = '单选题'
        break
      case QuesType.MULTI_SEL:
        subjectTypeStr = '多选题'
        break
      case QuesType.TRUE_FLS:
        subjectTypeStr = '判断题'
        break
    }

    return (
      <div className="c-text-gray">
        <div className="flex-h y-center">
          <Tag color="blue">
            {index + 1}/{this.state.paper.papSubjJson.length}
          </Tag>
          <Tag color="blue">{subjectTypeStr}</Tag>
          <span className="html-reset" dangerouslySetInnerHTML={{ __html: row.title }}></span>
        </div>
        <div>
          <span>分类：{row.groupNames}</span>|<span>作者：{row.loginName}</span>
        </div>
      </div>
    )
  }

  /**
   *
   * @param groupIds 根据已有数据取Group
   */
  getQuesGroup = async (groupIds: any) => {
    const params = {
      groupIds: groupIds.join(','),
    }
    const res = await testQuesGroup(params)
    if (res.success) {
      const groupList = res.data

      let totalQuesNum = 0
      let totalQuesScore = 0
      groupList.forEach((group: any) => {
        if (this.state.papRandJson) {
          // 如果是修改的话，将原始数据的值填充
          let subjNum = 0 // 每组试题总数
          let solScore = 0
          group.subjectTypeJson.forEach((groupRand: any) => {
            const randJson = this.state.papRandJson.find(
              (e: any) =>
                (e.groupId == group.groupId || (!e.groupId && !group.groupId)) &&
                e.subjectType == groupRand.subjectType
            )
            switch (groupRand.subjectType) {
              case QuesType.SINGLE_SEL:
                groupRand.subjectName = '单选题'
                break
              case QuesType.MULTI_SEL:
                groupRand.subjectName = '多选题'
                break
              case QuesType.TRUE_FLS:
                groupRand.subjectName = '判断题'
                break
            }
            if (randJson) {
              groupRand.subjNum = randJson.subjNum
              groupRand.solScore = randJson.solScore
              groupRand.papRandId = randJson.papRandId
              subjNum += randJson.subjNum
              solScore += randJson.solScore * randJson.subjNum
            }
          })
          group.subjNum = subjNum
          group.solScore = solScore
          totalQuesNum += subjNum
          totalQuesScore += solScore
        }
      })
      setTimeout(() => {
        this.setState({
          randGroupList: groupList,
          randTotalQuesNum: totalQuesNum,
          randTotalQuesScore: totalQuesScore,
        })
      }, 0)
    }

    this.setState({
      loading: false,
    })
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  public render() {
    return (
      <Spin spinning={this.state.loading}>
        <Card>
          <Descriptions title={this.state.paper.title}>
            <Descriptions.Item label="试卷描述" span={3}>
              {this.state.paper.depict}
            </Descriptions.Item>
            <Descriptions.Item label="分类">{this.state.paper.groupNames}</Descriptions.Item>
            <Descriptions.Item label="考试时长">
              {this.state.paper.examDura ? parseInt(this.state.paper.examDura) / 60 : 0}分钟
            </Descriptions.Item>
            <Descriptions.Item label="试卷状态">
              {this.state.paper.paperStatus == 1 && <Tag color="gray">草稿</Tag>}
              {this.state.paper.paperStatus == 2 && <Tag color="orange">未开始</Tag>}
              {this.state.paper.paperStatus == 3 && <Tag color="red">考试中</Tag>}
              {this.state.paper.paperStatus == 4 && <Tag color="green">已考完</Tag>}
            </Descriptions.Item>
            <Descriptions.Item label="出题方式">
              {this.state.paper.proposition == 1 && <Tag>固定选题</Tag>}
              {this.state.paper.proposition == 2 && <Tag>随机选题</Tag>}
              {this.state.paper.proposition == 3 && <Tag>固定+随机选题</Tag>}
            </Descriptions.Item>
            <Descriptions.Item label="总分">
              <span className="text-b">{this.state.paper.totalScore || 0}</span>
            </Descriptions.Item>
            <Descriptions.Item label="题目数量">
              <span className="text-b">{this.state.paper.subjectNum}</span>
            </Descriptions.Item>
            <Descriptions.Item label="考试次数">
              <span className="text-b">{this.state.paper.examNum}</span>
            </Descriptions.Item>
          </Descriptions>
          <Collapse defaultActiveKey={['0']} expandIconPosition="right">
            <Panel header="试卷设置" key="0">
              <Descriptions>
                <Descriptions.Item label="课程合格线分积分">
                  <span className="text-b">{this.state.paperSetting.courLineScore}</span>
                </Descriptions.Item>
                <Descriptions.Item label="考试合格分数">
                  <span className="text-b">{this.state.paperSetting.qualLineScore}</span>
                </Descriptions.Item>
                <Descriptions.Item label="最多考试次数">
                  <span className="text-b">{this.state.paperSetting.maxExamNum}</span>
                </Descriptions.Item>
                <Descriptions.Item label="考试权限" span={3}>
                  {this.state.paperSetting.stuGroup &&
                    this.state.paperSetting.stuGroup.map((item: any) => (
                      <Tag color="red">{item}</Tag>
                    ))}
                </Descriptions.Item>
                <Descriptions.Item label="必修课程" span={3}>
                  {this.state.paperSetting.reqCourses &&
                    this.state.paperSetting.reqCourses.map((item: any) => (
                      <Tag color="blue">{item}</Tag>
                    ))}
                </Descriptions.Item>
                <Descriptions.Item label="选修课程" span={3}>
                  {this.state.paperSetting.optCourses &&
                    this.state.paperSetting.optCourses.map((item: any) => <Tag>{item}</Tag>)}
                </Descriptions.Item>
                <Descriptions.Item label="试卷有效期">
                  {this.state.paperSetting.validDate}
                </Descriptions.Item>
              </Descriptions>
            </Panel>
            {this.state.paper.proposition != 2 && (
              <Panel
                header={
                  <span>
                    固定试题（共 {<span className="text-b">{this.state.papSubjJson.length}</span>}
                    道题，{<span className="text-b">{this.state.manualTotalQuesScore}</span>} 分）
                  </span>
                }
                key="1"
              >
                <Table
                  dataSource={this.state.papSubjJson}
                  expandedRowRender={(item) => <QuesBlock row={item} />}
                  rowKey="subjectId"
                  pagination={false}
                >
                  <Column
                    title="题目"
                    dataIndex="subjectId"
                    key="subjectId"
                    render={(subjectId, record, index) => this.Row(subjectId, index)}
                  />
                  <Column
                    title="分数"
                    width={80}
                    dataIndex="solScore"
                    key="solScore"
                    render={(solScore: any) => solScore}
                  />
                  <Column
                    title="分类"
                    width={150}
                    dataIndex="groupNames"
                    key="groupNames"
                    render={(groupNames: any) => groupNames}
                  />
                </Table>
              </Panel>
            )}
            {this.state.paper.proposition != 1 && (
              <Panel
                header={
                  <span>
                    随机试题（共 {<span className="text-b">{this.state.randTotalQuesNum}</span>}
                    道题，{<span className="text-b">{this.state.randTotalQuesScore}</span>} 分）
                  </span>
                }
                key="2"
              >
                {this.state.randGroupList.map((group: any) => {
                  return (
                    <Descriptions
                      bordered
                      className="mb-s"
                      key={group.groupId}
                      column={2}
                      title={
                        <span>
                          {group.groupName}
                          <span style={{ fontSize: '14px', fontWeight: 500, marginLeft: '15px' }}>
                            抽选 <span className="text-b">{group.subjNum}</span> 道题， 总共{' '}
                            <span className="text-b">{group.solScore}</span> 分
                          </span>
                        </span>
                      }
                    >
                      {group.subjectTypeJson.map((subjectType: any) => (
                        <React.Fragment>
                          <Descriptions.Item label={subjectType.subjectName}>
                            {subjectType.subjNum || 0}道题
                          </Descriptions.Item>
                          <Descriptions.Item label="每题">
                            {subjectType.solScore || 0}分
                          </Descriptions.Item>
                        </React.Fragment>
                      ))}
                    </Descriptions>
                  )
                })}
              </Panel>
            )}
          </Collapse>
        </Card>
      </Spin>
    )
  }
}

export default TestPapePreview

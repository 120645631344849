import { PERM } from './../utils/permits'
export interface IFMenuBase {
  key: string
  title: string
  icon?: string
  component?: string
  query?: string
  requireAuth?: string
  route?: string
  /** 是否登录校验，true不进行校验（访客） */
  login?: boolean
  code?: string
}

export interface IFMenu extends IFMenuBase {
  subs?: IFMenu[]
}

const menus: {
  menus: IFMenu[]
  others: IFMenu[] | []
  [index: string]: any
} = {
  menus: [
    {
      key: '/app/dashboard/index',
      title: '首页',
      icon: 'home',
      component: 'Dashboard',
      code: PERM.home_menu,
    },
    {
      key: '/app/testpaper',
      title: '试卷管理',
      icon: 'check-circle',
      code: PERM.paper_menu,
      subs: [
        {
          key: '/app/testpaper/index',
          title: '试卷列表',
          icon: 'check-circle',
          component: 'TestPaper',
          code: PERM.paper_menu_list,
        },
      ],
    },
    {
      key: '/app/ques',
      title: '题库管理',
      icon: 'question-circle',
      code: PERM.ques_menu,
      subs: [
        {
          key: '/app/ques/index',
          title: '题目管理',
          icon: 'question-circle',
          component: 'Ques',
          code: PERM.ques_menu_list,
        },
      ],
    },
    {
      key: '/app/student',
      title: '学员管理',
      icon: 'user',
      code: PERM.stu_menu,
      subs: [
        {
          key: '/app/student/index',
          title: '学员列表',
          icon: 'user',
          component: 'Student',
          code: PERM.stu_menu_list,
        },
      ],
    },
    {
      key: '/app/course',
      title: '学习管理',
      icon: 'book',
      code: PERM.study_menu,
      subs: [
        {
          key: '/app/course/index',
          title: '课程管理',
          icon: 'book',
          component: 'Course',
          code: PERM.study_menu_course,
        },
        {
          key: '/app/course/courseWare',
          title: '课件管理',
          icon: 'cloud-upload-o',
          component: 'CourseWare',
          code: PERM.study_menu_courseware,
        },
      ],
    },
    {
      key: '/app/certs',
      title: '证书管理',
      icon: 'trophy',
      code: PERM.cert_menu,
      subs: [
        {
          key: '/app/certs/index',
          title: '证书列表',
          icon: 'trophy',
          component: 'Certs',
          code: PERM.cert_menu_list,
        },
      ],
    },
    {
      key: '/app/stat',
      title: '统计管理',
      icon: 'bar-chart',
      code: PERM.stat_menu,
      subs: [
        {
          key: '/app/stat/points',
          title: '积分统计',
          icon: 'book',
          component: 'PointsStat',
          code: PERM.stat_menu_point,
        },
        {
          key: '/app/stat/paper',
          title: '试卷统计',
          icon: 'book',
          component: 'PaperStat',
          code: PERM.stat_menu_paper,
        },
        {
          key: '/app/stat/course',
          title: '学习统计',
          icon: 'calculator',
          component: 'CourseStat',
          code: PERM.stat_menu_study,
        },
        {
          key: '/app/stat/stutest',
          title: '学员考试统计',
          icon: 'calculator',
          component: 'StuTestStat',
          code: PERM.stat_menu_stu_test,
        },
        {
          key: '/app/stat/monthstat',
          title: '培训月报表',
          icon: 'calculator',
          component: 'MonthReportStat',
          code: PERM.stat_menu_month_report,
        },
      ],
    },
    {
      key: '/app/settings',
      title: '系统设置',
      icon: 'setting',
      code: PERM.setting_menu,
      subs: [
        {
          key: '/app/settings/account',
          title: '账户管理',
          icon: 'team',
          component: 'Account',
          code: PERM.setting_menu_account,
        },
        {
          key: '/app/settings/permission',
          title: '权限管理',
          icon: 'lock',
          component: 'Permission',
          code: PERM.setting_menu_perm,
        },
        {
          key: '/app/settings/role',
          title: '角色管理',
          icon: 'idcard',
          component: 'Role',
          code: PERM.setting_menu_role,
        },
        {
          key: '/app/settings/comp',
          title: '企业管理',
          icon: 'global',
          component: 'Comp',
          code: PERM.setting_menu_comp,
        },
        {
          key: '/app/settings/organ',
          title: '机构管理',
          icon: 'audit',
          component: 'Organ',
          code: PERM.setting_menu_organ,
        },
      ],
    },
    {
      key: '/app/platsetting',
      title: '平台管理',
      icon: 'setting',
      code: PERM.platform,
      subs: [
        {
          key: '/app/platsetting/certs',
          title: '证书设置',
          icon: 'team',
          component: 'PlatformCerts',
          code: PERM.platform_certs,
        },
        {
          key: '/app/platsetting/notice',
          title: '通知管理',
          icon: 'notification',
          code: PERM.platform_notice,
          component: 'Notice',
        },
      ],
    },
  ],
  others: [
    {
      key: '/app/testpaper/add',
      title: '新增试卷',
      component: 'TestQuesAdd',
    },
    {
      key: '/app/testpaper/quesedit',
      title: '试题管理',
      component: 'TestQuesEdit',
    },
    {
      key: '/app/course/add',
      title: '新增课程',
      component: 'CourseAdd',
    },
    {
      key: '/app/stat/paperstu',
      title: '考试详情',
      component: 'PaperStatDetail',
    },
    {
      key: '/app/stat/coursestu',
      title: '学习详情',
      component: 'CourseStatDetail',
    },
    {
      key: '/app/pwdmod',
      title: '修改密码',
      component: 'AccountPwdMod',
    },
  ], // 非菜单相关路由
}

export default menus

import * as React from 'react'
import { Button, Table, Modal, message } from 'antd'
import { statStuTestDetail } from './../../service/stats'
const { Column } = Table

export type IStuTestStatDetailProps = {
  studentId?: any
  startDate?: String
  endDate?: String
}

type IStuTestStatDetailStates = {
  editDialogVisible: boolean
  dataSource: any[]
  pageSize: number
  page: number
  total: number
  loading: boolean
}

class StuTestStatDetail extends React.Component<IStuTestStatDetailProps, IStuTestStatDetailStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      editDialogVisible: false,
      pageSize: 20,
      page: 1,
      dataSource: [],
      total: 0,
      loading: false,
    }

    this.showEditDialog = this.showEditDialog.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  getStudentTestList = async () => {
    if (this.props.studentId) {
      const params = {
        studentId: this.props.studentId,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      }
      this.setState({
        loading: true,
      })
      const res = await statStuTestDetail(params)
      if (res.success) {
        const data = res.data
        if (data) {
          setTimeout(() => {
            this.setState({
              dataSource: data,
            })
          }, 0)
        }
      }
      this.setState({
        loading: false,
      })
    }
  }

  showEditDialog = () => {
    this.setState({
      editDialogVisible: true,
    })
  }

  handleCancel = (e: any) => {
    this.setState({
      editDialogVisible: false,
    })
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getStudentTestList()
    }, 0)
  }

  public render() {
    return (
      <div>
        <Modal
          width="50%"
          title="考试明细"
          visible={this.state.editDialogVisible}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
        >
          <Table
            pagination={{
              current: this.state.page,
              onChange: this.onPageChange,
              pageSize: this.state.pageSize,
              size: 'small',
              total: this.state.total,
              showTotal: (total: number) => {
                return `共 ${total} 条`
              },
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ['10', '20', '50', '100', '200'],
              onShowSizeChange: (current, size) => {
                setTimeout(() => {
                  this.setState({
                    pageSize: size,
                  })
                  this.onPageChange(1)
                }, 0)
              },
            }}
            loading={this.state.loading}
            dataSource={this.state.dataSource}
          >
            <Column
              title="学员姓名"
              dataIndex="stuName"
              key="stuName"
              render={(stuName: any) => stuName}
            />
            <Column
              title="试卷名称"
              dataIndex="paperName"
              key="paperName"
              render={(paperName: any) => paperName}
            />
            <Column
              title="考试时间"
              dataIndex="lastEndDateTime"
              key="lastEndDateTime"
              render={(lastEndDateTime: any) => lastEndDateTime}
            />
            <Column title="得分" dataIndex="score" key="score" render={(score: any) => score} />
          </Table>
        </Modal>
        <Button
          type="primary"
          ghost
          onClick={(e) => {
            setTimeout(() => {
              this.setState({ editDialogVisible: true })
              this.getStudentTestList()
            }, 0)
          }}
        >
          详情
        </Button>
      </div>
    )
  }
}

export default StuTestStatDetail

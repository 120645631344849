import { post } from './tools'
import * as config from './config'

export const acctList = async (params: any) => {
  return await post({
    url: config.ACC_LIST,
    data: params,
  })
}
export const acctOne = async (id: any) => {
  return await post({
    url: config.ACC_ONE,
    data: { accountId: id },
  })
}
export const acctSave = async (params: any) => {
  return await post({
    url: config.ACC_SAVE,
    data: params,
  })
}
export const acctPwdMod = async (params: any) => {
  return await post({
    url: config.ACC_PWD_MOD,
    data: params,
  })
}
export const acctDel = async (ids: any[]) => {
  return await post({
    url: config.ACC_DEL,
    data: { accountIds: ids.join(',') },
  })
}

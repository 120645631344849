import * as React from 'react'
import { Table, Pagination, Form, Input, Select, Button, message } from 'antd'
import { statPaperStuUntest, statPaperSendMsg } from '../../service/stats'
import { FormComponentProps } from 'antd/lib/form'
import GroupSel from './../widget/GroupSel'
import { GroupType } from './../../utils/index'
import { download } from './../../service/index'
import { STATS_PAPERSTU_EXP } from './../../service/config'

export interface IPaperStuUntestProps extends FormComponentProps {
  paperId: any
}
interface IPaperStuUntestStates {
  pageSize: number
  page: number
  dataSource: any
  total: number
  groupId: any
  groupName: string
  loading: boolean
  checkList: any
}

const { Column } = Table
const { Option } = Select

class PaperStuUntest extends React.Component<IPaperStuUntestProps, IPaperStuUntestStates> {
  constructor(props: any) {
    super(props)
    this.getPaperUntestList = this.getPaperUntestList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.state = {
      pageSize: 20,
      page: 1,
      dataSource: [],
      total: 0,
      groupId: '',
      groupName: '',
      loading: false,
      checkList: [],
    }
  }

  componentDidMount() {
    this.getPaperUntestList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  handleSearch = async () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getPaperUntestList()
    }, 0)
  }

  onChangeGroup = async (group: any) => {
    this.setState({
      groupId: group ? group.groupId : '',
      groupName: group ? group.groupName : '',
    })
  }

  getPaperUntestList = async () => {
    const values = this.props.form!.getFieldsValue()
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      paperId: this.props.paperId,
      groupId: this.state.groupId,
      ...values,
    }
    this.setState({
      loading: true,
    })
    const res = await statPaperStuUntest(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getPaperUntestList()
    }, 0)
  }

  handleUntestStuExport = async (e: any) => {
    const values = this.props.form!.getFieldsValue()
    const params = {
      ...values,
      paperId: this.props.paperId,
      groupId: this.state.groupId,
    }
    await download(STATS_PAPERSTU_EXP, '学员', params)
  }

  handleSendMessage = async () => {
    if (this.state.checkList.length == 0) {
      message.warning('请选择学员')
      return
    }
    const params = {
      paperId: this.props.paperId,
      studentIds: this.state.checkList.join(','),
    }
    const res = await statPaperSendMsg(params)
    if (res.success) {
      message.success('通知发送成功')
    } else {
      message.error(res.msg || '通知发送失败')
    }
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        this.setState({
          checkList: selectedRowKeys,
        })
      },
    }
    return (
      <div>
        <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
          <Form.Item label="企业名称">
            {getFieldDecorator('compName')(<Input placeholder="企业名称" allowClear />)}
          </Form.Item>
          <Form.Item label="姓名">
            {getFieldDecorator('stuName')(<Input placeholder="姓名" allowClear />)}
          </Form.Item>
          <Form.Item>
            <GroupSel
              title="分组"
              buttonTitle={`分组（${this.state.groupName}）`}
              type={GroupType.STU}
              hideInput={true}
              onChange={this.onChangeGroup}
            />
          </Form.Item>
          <Form.Item label="是否通知">
            {getFieldDecorator('isNotify')(
              <Select allowClear style={{ width: '80px' }}>
                <Option key="-1">全部</Option>
                <Option key="1">已通知</Option>
                <Option key="2">未通知</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" icon="search" htmlType="submit">
              搜索
            </Button>
          </Form.Item>
          <Form.Item>
            <Button icon="export" type="primary" ghost onClick={this.handleUntestStuExport}>
              导出学员
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" ghost onClick={this.handleSendMessage}>
              通知考试
            </Button>
          </Form.Item>
        </Form>
        <Pagination
          current={this.state.page}
          size="small"
          pageSizeOptions={['10', '20', '50', '100', '200']}
          pageSize={this.state.pageSize}
          showSizeChanger
          showQuickJumper
          total={this.state.total}
          showTotal={this.showTotal}
          onChange={this.onPageChange}
          onShowSizeChange={(current, size) => {
            setTimeout(() => {
              this.setState({
                pageSize: size,
              })
              this.onPageChange(1)
            }, 0)
          }}
        />
        <Table
          rowSelection={rowSelection}
          key="table1"
          loading={this.state.loading}
          rowKey="studentId"
          pagination={{
            current: this.state.page,
            onChange: this.onPageChange,
            pageSize: this.state.pageSize,
            size: 'small',
            total: this.state.total,
            showTotal: this.showTotal,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            onShowSizeChange: (current, size) => {
              setTimeout(() => {
                this.setState({
                  pageSize: size,
                })
                this.onPageChange(1)
              }, 0)
            },
          }}
          dataSource={this.state.dataSource}
        >
          <Column
            title="姓名"
            dataIndex="stuName"
            key="stuName"
            render={(stuName: any) => stuName}
          />
          <Column
            title="分组"
            dataIndex="groupNames"
            key="groupNames"
            render={(groupNames: any) => groupNames}
          />
          <Column
            title="企业名称"
            dataIndex="compName"
            key="compName"
            render={(compName: any) => compName}
          />

          <Column title="手机号码" dataIndex="phone" key="phone" render={(phone: any) => phone} />
          <Column
            title="是否已通知考试"
            dataIndex="isNotify"
            key="isNotify"
            render={(isNotify: any) => (isNotify == 1 ? '是' : '否')}
          />
          <Column
            title="学习状态"
            dataIndex="courLearnStatus"
            key="courLearnStatus"
            render={(courLearnStatus: any) => (courLearnStatus == 1 ? '已完成' : '未完成')}
          />
        </Table>
      </div>
    )
  }
}
export default Form.create<IPaperStuUntestProps>()(PaperStuUntest)

import * as React from 'react'
import { Form, Button, InputNumber, DatePicker, Select, Radio, message, Modal, Spin } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { testSettingList, testSettingSave } from './../../service/test'
import { courseList } from './../../service/course'
import { GroupType } from './../../utils/index'
import { fetchGroupList } from './../../service/index'
import moment from 'moment'

const { Option } = Select

const { RangePicker } = DatePicker
type ITestPaperSettingProps = {
  onNextStep?: (e: any) => void
  paperId?: any
  buttonDisabled?: boolean
  modal: boolean
  handleSaveSettingFinished?: () => void
} & FormComponentProps
type ITestPaperSettingStates = {
  stuGroupList: any
  reqCourseList: any
  optCourseList: any
  requireScore: any
  optScore: any
  editDialogVisible: boolean
  loading: boolean
}

class TestPaperSetting extends React.Component<ITestPaperSettingProps, ITestPaperSettingStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      stuGroupList: [],
      reqCourseList: [],
      optCourseList: [],
      requireScore: 0,
      optScore: 0,
      editDialogVisible: false,
      loading: false,
    }
    this.getStuGroupList = this.getStuGroupList.bind(this)
    this.getSettings = this.getSettings.bind(this)
    this.getCourseList = this.getCourseList.bind(this)
    this.onReqCourseChanged = this.onReqCourseChanged.bind(this)
    this.onOptCourseChanged = this.onOptCourseChanged.bind(this)
  }

  async componentDidMount() {
    if (!this.props.modal) {
      this.setState({
        loading: true,
      })
      this.getStuGroupList()
      await this.getCourseList(1)
      await this.getCourseList(2)
      this.getSettings()
    }
  }

  onDelStudyGroup = (e: any) => {}

  getStuGroupList = async () => {
    const params = {
      groupType: GroupType.STU,
    }
    const res = await fetchGroupList(params)
    if (res.success) {
      const stuGroupList = res.data
      this.setState({ stuGroupList: stuGroupList })
    }
  }

  getCourseList = async (courseType: number) => {
    const params = { courseType: courseType }
    const res = await courseList(params)
    if (res.success) {
      if (courseType == 1) {
        this.setState({
          reqCourseList: res.data,
        })
      } else if (courseType == 2) {
        this.setState({
          optCourseList: res.data,
        })
      }
    }
  }

  getSettings = async () => {
    if (this.props.paperId) {
      const res = await testSettingList({ paperId: this.props.paperId })
      if (res.success) {
        const settings = res.data
        if (settings) {
          settings.forEach((setting: any) => {
            if (setting.title == 'stuGroup') {
              const option0 = setting.option0 ? setting.option0.split(',') : []
              this.props.form.setFieldsValue({ stuGroupList: option0 })
            } else if (setting.title == 'reqCourseIds') {
              const option0 = setting.option0 ? setting.option0.split(',') : []
              this.props.form.setFieldsValue({ reqCourses: option0 })
              const reqList = this.state.reqCourseList.filter((item: any) =>
                option0.includes(item.courseId.toString())
              )
              if (reqList) {
                let reqScore = 0
                reqList.forEach((reqCrs: any) => {
                  reqScore += reqCrs.totalScore
                })
                this.setState({
                  requireScore: reqScore,
                })
              }
            } else if (setting.title == 'optCourseIds') {
              const option0 = setting.option0 ? setting.option0.split(',') : []
              this.props.form.setFieldsValue({ optCourses: option0 })
              const optList = this.state.optCourseList.filter((item: any) =>
                option0.includes(item.courseId.toString())
              )
              if (optList) {
                let optScore = 0
                optList.forEach((optCrs: any) => {
                  optScore += optCrs.totalScore
                })
                this.setState({
                  optScore: optScore,
                })
              }
            } else if (setting.title == 'courLineScore') {
              this.props.form.setFieldsValue({ courLineScore: parseInt(setting.option0) })
            } else if (setting.title == 'maxExamNum') {
              this.props.form.setFieldsValue({ maxExamNum: setting.option0 })
            } else if (setting.title == 'qualLineScore') {
              this.props.form.setFieldsValue({ qualLineScore: parseInt(setting.option0) })
            } else if (setting.title == 'isValid') {
              const isValid = parseInt(setting.option0)
              this.props.form.setFieldsValue({ isValid: isValid })
              if (isValid == 2) {
                const option0Json = JSON.parse(setting.opt0Json)
                const dateFormat = 'YYYY-MM-DD'
                const rangeDate = [
                  moment(option0Json.invalidStartDate, dateFormat),
                  moment(option0Json.invalidEndDate, dateFormat),
                ]
                this.props.form.setFieldsValue({ rangeDate: rangeDate })
              }
            }
          })
        }
      }
      this.setState({
        loading: false,
      })
    }
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    this.props.form &&
      this.props.form.validateFields(async (err: any, values: any) => {
        if (!err) {
          this.setState({
            loading: true,
          })
          if (this.props.paperId) {
            const papSetJson = []
            const stuGroupList = {
              setType: 3,
              setDesc: '试卷权限(学员分组流水号(多个逗号隔开))',
              title: 'stuGroup',
              option0: values.stuGroupList.join(','),
            }
            papSetJson.push(stuGroupList)
            const reqCourses = {
              setType: 3,
              setDesc: '试卷对应必修课程(课程流水号(多个逗号隔开))',
              title: 'reqCourseIds',
              option0: values.reqCourses.join(','),
            }
            papSetJson.push(reqCourses)
            const optCourses = {
              setType: 3,
              setDesc: '试卷对应选修课程(课程流水号(多个逗号隔开))',
              title: 'optCourseIds',
              option0: values.optCourses ? values.optCourses.join(',') : '',
            }
            papSetJson.push(optCourses)
            const courLineScore = {
              setType: 3,
              setDesc: '课程合格线积分(正整数)',
              title: 'courLineScore',
              option0: values.courLineScore,
            }
            papSetJson.push(courLineScore)

            const maxExamNum = {
              setType: 3,
              setDesc: '最大考试次数(正整数)',
              title: 'maxExamNum',
              option0: values.maxExamNum,
            }
            papSetJson.push(maxExamNum)

            const qualLineScore = {
              setType: 7,
              setDesc: '考试合格线分值(正整数)',
              title: 'qualLineScore',
              option0: values.qualLineScore,
            }
            papSetJson.push(qualLineScore)

            const dateFormat = 'YYYY-MM-DD'
            const isValid = {
              setType: 3,
              setDesc: '试卷有效期',
              title: 'isValid',
              option0: values.isValid,
              opt0Json:
                values.isValid == 2
                  ? {
                      invalidStartDate: moment(values.rangeDate[0]).format(dateFormat),
                      invalidEndDate: moment(values.rangeDate[1]).format(dateFormat),
                    }
                  : '',
            }
            papSetJson.push(isValid)

            const params = { paperId: this.props.paperId, papSetJson: JSON.stringify(papSetJson) }
            const res = await testSettingSave(params)
            if (res.success) {
              message.success('保存成功')
              if (this.props.onNextStep) {
                this.props.onNextStep({ paperId: this.props.paperId })
              }
              this.setState({
                editDialogVisible: false,
              })
              if (this.props.handleSaveSettingFinished) {
                this.props.handleSaveSettingFinished()
              }
            } else {
              message.error('保存失败')
            }
            this.setState({
              loading: false,
            })
          }
        }
      })
  }
  onReqCourseChanged = (selectKeys: any, options: any) => {
    let requireScore = 0
    this.state.reqCourseList.forEach((crs: any) => {
      if (selectKeys.includes(crs.courseId + '')) {
        requireScore += crs.totalScore
      }
    })
    setTimeout(() => {
      this.setState({
        requireScore: requireScore,
      })
    }, 0)
    this.props.form.setFieldsValue({
      courLineScore: requireScore,
    })
  }

  onOptCourseChanged = (selectKeys: any, options: any) => {
    let optScore = 0
    this.state.optCourseList.forEach((crs: any) => {
      if (selectKeys.includes(crs.courseId + '')) {
        optScore += crs.totalScore
      }
    })
    this.setState({
      optScore: optScore,
    })
  }

  onChange = (e: any) => {}
  public render() {
    const form = this.props.form!
    const { getFieldDecorator } = this.props.form!
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    const body = (
      <Spin spinning={this.state.loading}>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item label="考试权限">
            {getFieldDecorator('stuGroupList', {
              rules: [{ required: true, message: '请选择学员组!' }],
            })(
              <Select
                mode="tags"
                placeholder="请选择学员组"
                onChange={() => {}}
                style={{ width: '100%' }}
              >
                {this.state.stuGroupList &&
                  this.state.stuGroupList.map((stu: any) => (
                    <Option key={stu.groupId}>{stu.groupName}</Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="必修课程">
            {getFieldDecorator('reqCourses', {
              rules: [{ required: true, message: '请选择学习组!' }],
            })(
              <Select
                mode="tags"
                placeholder="请选择必修课"
                onChange={this.onReqCourseChanged}
                style={{ width: '100%' }}
              >
                {this.state.reqCourseList &&
                  this.state.reqCourseList.map((crs: any) => (
                    <Option key={crs.courseId}>
                      {crs.title}（{crs.totalScore}）
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="选修课程">
            {getFieldDecorator('optCourses', {
              rules: [{ required: false, message: '请选择学习组!' }],
            })(
              <Select
                mode="tags"
                placeholder="请选择选修课"
                style={{ width: '100%' }}
                onChange={this.onOptCourseChanged}
              >
                {this.state.optCourseList &&
                  this.state.optCourseList.map((crs: any) => (
                    <Option key={crs.courseId}>
                      {crs.title}（{crs.totalScore}）
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="课程合格线积分">
            {getFieldDecorator('courLineScore', {
              rules: [{ required: true, message: '请填写积分!' }],
            })(
              <InputNumber
                size="small"
                min={this.state.requireScore}
                max={parseInt(this.state.requireScore) + parseInt(this.state.optScore)}
                onChange={this.onChange}
              />
            )}
          </Form.Item>
          <Form.Item label="最多考试次数">
            {getFieldDecorator('maxExamNum', {
              rules: [{ required: true, message: '请填写考试次数!' }],
            })(<InputNumber size="small" min={0} max={100} onChange={this.onChange} />)}
          </Form.Item>
          <Form.Item label="考试合格分数">
            {getFieldDecorator('qualLineScore', {
              rules: [{ required: true, message: '请填写分数!' }],
            })(<InputNumber size="small" min={0} max={200} onChange={this.onChange} />)}
          </Form.Item>
          <Form.Item label="试卷有效期">
            {getFieldDecorator('isValid', {
              rules: [{ required: true, message: '请输入试卷有效期!' }],
            })(
              <Radio.Group>
                <Radio value={1}>永久</Radio>
                <Radio value={2}>固定时间</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          {form.getFieldValue('isValid') == 2 && (
            <Form.Item label="试卷有效期">
              {getFieldDecorator('rangeDate', {
                rules: [{ required: true, message: '请输入试卷有效期!' }],
              })(<RangePicker />)}
            </Form.Item>
          )}
          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 },
            }}
          >
            {this.props.onNextStep && (
              <Button type="primary" htmlType="submit">
                保存
              </Button>
            )}
          </Form.Item>
        </Form>
      </Spin>
    )
    return (
      <div>
        {!this.props.onNextStep && (
          <div>
            <Modal
              width="60%"
              title="设置"
              visible={this.state.editDialogVisible}
              onOk={this.handleSubmit}
              onCancel={() => {
                this.setState({
                  editDialogVisible: false,
                })
              }}
            >
              {body}
            </Modal>

            <Button
              type="primary"
              disabled={this.props.buttonDisabled}
              onClick={() => {
                setTimeout(async () => {
                  this.setState({
                    editDialogVisible: true,
                    loading: true,
                  })
                  this.getStuGroupList()
                  await this.getCourseList(1)
                  await this.getCourseList(2)
                  this.getSettings()
                }, 0)
              }}
            >
              设置
            </Button>
          </div>
        )}
        {this.props.onNextStep && body}
      </div>
    )
  }
}

const TestPaperSettingForm = Form.create<ITestPaperSettingProps>()(TestPaperSetting)
export default TestPaperSettingForm

import * as React from 'react'
import { Table, Button, Input, Card, message, Divider } from 'antd'
import { PERM, hasPermits } from './../../utils/permits'
import { platCertsNoListByOrgan, platCertsNoMod } from './../../service/platCerts'
import umbrella from 'umbrella-storage'

export interface IPlatformCertsProps {}
interface IPlatformCertsStates {
  certsnoList: any[]
  loading: boolean
}

export default class PlatformCerts extends React.Component<
  IPlatformCertsProps,
  IPlatformCertsStates
> {
  constructor(props: any) {
    super(props)
    this.state = {
      certsnoList: [],
      loading: false,
    }
  }

  componentDidMount() {
    this.getOrganCertsNoList()
  }

  getOrganCertsNoList = async () => {
    this.setState({
      loading: true,
    })

    let user = umbrella.getLocalStorage('user')
    const organ = user.organJson ? user.organJson[0] : {}
    const params = {
      organId: organ.organId,
    }
    const res = await platCertsNoListByOrgan(params)
    setTimeout(() => {
      this.setState({
        certsnoList: res.data,
        loading: false,
      })
    }, 0)
  }

  onSaveCertsno = async (cert: any) => {
    const params = {
      organId: cert.organId,
      ccieNoStartPostfix: cert.ccieNoStartPostfix,
    }
    cert.loading = true
    setTimeout(() => {
      this.setState({
        certsnoList: this.state.certsnoList,
      })
    }, 0)
    const res = await platCertsNoMod(params)
    if (res.success) {
      message.success('保存成功')
    } else {
      message.error('保存失败')
    }
    cert.loading = false
    setTimeout(() => {
      this.setState({
        certsnoList: this.state.certsnoList,
      })
    }, 0)
  }

  public render() {
    return (
      <div className="main mt-l">
        <Card>
          {this.state.certsnoList.map((item: any) => (
            <div className="flex-vcenter pb-s pt-s border-bottom" key={item.organId}>
              <div className="flex-1 text-b">{item.organName}</div>
              <div className="flex-2 flex">
                <div className="flex-vcenter">
                  <span style={{ width: '120px' }}>{item.ccieNoMain}</span>
                  <Input
                    className="flex-1"
                    placeholder="选项内容"
                    defaultValue={item.ccieNoStartPostfix}
                    onChange={(e) => {
                      const value = e.currentTarget.value
                      item.ccieNoStartPostfix = value
                    }}
                  />
                  <Button
                    className="ml-m"
                    loading={item.loading}
                    type="primary"
                    onClick={() => this.onSaveCertsno(item)}
                  >
                    保存
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </Card>
      </div>
    )
  }
}

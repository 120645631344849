import * as React from 'react'
import { Table, Pagination, Form, Input, Button, Select } from 'antd'
import { statPaperStuTested } from '../../service/stats'
import { FormComponentProps } from 'antd/lib/form'

export interface IPaperStuTestedProps extends FormComponentProps {
  paperId: any
}

interface IPaperStuTestedStates {
  pageSize: number
  page: number
  dataSource: any
  total: number
  loading: boolean
}

const { Column } = Table
const { Option } = Select

class PaperStuTested extends React.Component<IPaperStuTestedProps, IPaperStuTestedStates> {
  constructor(props: any) {
    super(props)

    this.getPaperTestedList = this.getPaperTestedList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.state = {
      pageSize: 20,
      page: 1,
      dataSource: [],
      total: 0,
      loading: false,
    }
  }

  componentDidMount() {
    this.getPaperTestedList()
  }
  showTotal(total: number) {
    return `共 ${total} 条`
  }

  handleSearch = async () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getPaperTestedList()
    }, 0)
  }

  getPaperTestedList = async () => {
    const values = this.props.form!.getFieldsValue()
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      paperId: this.props.paperId,
      qualNumStandard: 0,
      ...values,
    }
    this.setState({
      loading: true,
    })
    const res = await statPaperStuTested(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getPaperTestedList()
    }, 0)
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      },
      getCheckboxProps: (record: any) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }
    return (
      <div>
        <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
          <Form.Item label="企业名称">
            {getFieldDecorator('compName')(<Input placeholder="企业名称" allowClear />)}
          </Form.Item>
          <Form.Item label="姓名">
            {getFieldDecorator('stuName')(<Input placeholder="姓名" allowClear />)}
          </Form.Item>
          <Form.Item label="及格次数">
            {getFieldDecorator('qualNumType')(
              <Select allowClear style={{ width: '80px' }}>
                <Option key="-1">不限</Option>
                <Option key="3">大于0</Option>
                <Option key="2">等于0</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" icon="search" htmlType="submit">
              搜索
            </Button>
          </Form.Item>
        </Form>
        <Pagination
          current={this.state.page}
          size="small"
          pageSizeOptions={['10', '20', '50', '100', '200']}
          pageSize={this.state.pageSize}
          showSizeChanger
          showQuickJumper
          total={this.state.total}
          showTotal={this.showTotal}
          onChange={this.onPageChange}
          onShowSizeChange={(current, size) => {
            setTimeout(() => {
              this.setState({
                pageSize: size,
              })
              this.onPageChange(1)
            }, 0)
          }}
        />
        <Table
          rowSelection={rowSelection}
          key="table1"
          rowKey="studentId"
          loading={this.state.loading}
          pagination={{
            current: this.state.page,
            onChange: this.onPageChange,
            pageSize: this.state.pageSize,
            size: 'small',
            total: this.state.total,
            showTotal: this.showTotal,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            onShowSizeChange: (current, size) => {
              setTimeout(() => {
                this.setState({
                  pageSize: size,
                })
                this.onPageChange(1)
              }, 0)
            },
          }}
          dataSource={this.state.dataSource}
        >
          <Column
            title="姓名"
            dataIndex="stuName"
            key="stuName"
            render={(stuName: any) => stuName}
          />
          <Column
            title="分组"
            dataIndex="groupNames"
            key="groupNames"
            render={(groupNames: any) => groupNames}
          />
          <Column
            title="企业名称"
            dataIndex="compName"
            key="compName"
            render={(compName: any) => compName}
          />

          <Column
            title="考试次数"
            dataIndex="examNum"
            key="examNum"
            render={(examNum: any) => examNum}
          />
          <Column
            title="平均分"
            dataIndex="examAvg"
            key="examAvg"
            render={(examAvg: any) => examAvg}
          />
          <Column
            title="及格次数"
            dataIndex="qualNum"
            key="qualNum"
            render={(qualNum: any) => qualNum}
          />
          <Column
            title="最高分数"
            dataIndex="maxScore"
            key="maxScore"
            render={(maxScore: any) => maxScore}
          />
          <Column
            title="最低分数"
            dataIndex="minScore"
            key="minScore"
            render={(minScore: any) => minScore}
          />
        </Table>
      </div>
    )
  }
}
export default Form.create<IPaperStuTestedProps>()(PaperStuTested)

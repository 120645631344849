import * as React from 'react'
import { Modal, Icon, Upload, message, Button } from 'antd'
import { BASE_URL } from './../../service/config'
const { Dragger } = Upload

type IDocUploaderProps = {
  buttonTitle?: string
  handleUploadFileChange: (fileList: any[], callback?: any) => void
  supportTypes?: string
}
type IDocUploaderStates = {
  editDialogVisible: boolean
  fileList: any
}
export default class DocUploader extends React.Component<IDocUploaderProps, IDocUploaderStates> {
  constructor(props: any) {
    super(props)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.state = {
      editDialogVisible: false,
      fileList: [],
    }
  }

  showEditDialog = () => {
    this.setState({
      editDialogVisible: true,
    })
  }

  handleOk = (e: any) => {
    if (this.props.handleUploadFileChange) {
      this.props.handleUploadFileChange(this.state.fileList, this.handleCancel)
    }
    this.setState({
      editDialogVisible: false,
    })
  }

  handleCancel = (e: any) => {
    this.setState({
      editDialogVisible: false,
    })
  }

  handleFileChange = (info: any) => {
    const fileList: any[] = []
    info.fileList.forEach((item: any) => {
      if (item.response.msgStatsNum) {
        fileList.push(item.response.rows[0])
        this.setState({
          fileList: fileList,
        })
      }
    })
  }

  public render() {
    const props = {
      name: 'file',
      multiple: true,
      action: BASE_URL + 'communalcs/upload',
      onChange: (info: any) => {
        const { status } = info.file
        if (status !== 'uploading') {
        }
        if (status === 'done') {
          message.success(`${info.file.name} 上传成功.`)
          this.handleFileChange(info)
        } else if (status === 'error') {
          message.error(`${info.file.name} 上传失败.`)
        }
      },
    }
    return (
      <span className="mr-s">
        <Button icon="import" type="primary" onClick={this.showEditDialog}>
          {this.props.buttonTitle || '上传'}
        </Button>
        <Modal
          width="40%"
          title={this.props.buttonTitle || '上传'}
          visible={this.state.editDialogVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          {this.state.editDialogVisible && (
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">点击这里上传</p>
              <p className="ant-upload-hint">
                1.支持上传
                {this.props.supportTypes
                  ? this.props.supportTypes
                  : '音频、视频、图片、Excel、Word、PPT、TXT、PDF'}
                类型的文件；
              </p>
              <p className="ant-upload-hint">2.上传的文档类文件需 ≤ 100MB；</p>
            </Dragger>
          )}
        </Modal>
      </span>
    )
  }
}

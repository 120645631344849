/**
 * Created by hao.cheng on 2017/4/26.
 */
import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState } from 'draft-js'
import { convertFromHTML, ContentState } from 'draft-js'

type WysiwygState = {
  editorContent: any
  editorState: EditorState | undefined
}
export interface WysiwygProps {
  onChange: (e: any, data: any) => void
  data?: any
}
class Wysiwyg extends React.Component<WysiwygProps, WysiwygState> {
  constructor(props: any) {
    super(props)
    this.state = {
      editorContent: undefined,
      editorState: EditorState.createEmpty(),
    }
  }

  componentDidMount() {
    if (this.props.data && this.props.data.content && this.props.data.content.length > 0) {
      const blocksFromHtml = convertFromHTML(this.props.data.content)
      if (blocksFromHtml && blocksFromHtml.contentBlocks !== null) {
        const state = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks,
          blocksFromHtml.entityMap
        )
        this.setState({
          editorState: EditorState.createWithContent(state),
        })
      }
    }
  }

  componentWillReceiveProps(props: any) {
    if (props.data && props.data.content && props.data.content.length > 0 && props.data.initial) {
      const blocksFromHtml = convertFromHTML(props.data.content)
      if (blocksFromHtml && blocksFromHtml.contentBlocks !== null) {
        const state = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks,
          blocksFromHtml.entityMap
        )
        this.setState({
          editorState: EditorState.createWithContent(state),
        })
      }
    }
  }

  onEditorChange = (editorContent: any) => {
    this.setState({
      editorContent,
    })
    this.props.onChange(editorContent, {
      ...this.props.data,
      initial: false,
    })
  }

  clearContent = () => {
    this.setState({
      editorState: EditorState.createEmpty(),
    })
  }

  onContentStateChange = (contentState: any) => {
    console.log('contentState', contentState)
  }

  onEditorStateChange = (editorState: any) => {
    this.setState({
      editorState,
    })
  }

  imageUploadCallBack = (file: any) =>
    new Promise((resolve, reject) => {
      console.log(file)
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function (ev: any) {
        //文件读取成功完成时触发
        var dataURL = ev.target.result //获得文件读取成功后的DataURL,也就是base64编码
        const image = { data: { link: dataURL } }
        resolve(image)
      }
    })

  render() {
    const { editorState } = this.state
    return (
      <div className="gutter-example button-demo">
        <Editor
          editorState={editorState}
          toolbarClassName="wysiwyg-toolbar"
          wrapperClassName="wysiwyg-wrapper"
          editorClassName="wysiwyg-editor"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: [
              'inline',
              'fontSize',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'image',
              'remove',
              'history',
            ],
            inline: { inDropdown: false },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            image: {
              urlEnabled: true,
              uploadEnabled: true,
              previewImage: true,
              uploadCallback: this.imageUploadCallBack,
              inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            },
          }}
          onContentStateChange={this.onEditorChange}
          placeholder="请输入内容"
          spellCheck
          onFocus={() => {
            console.log('focus')
          }}
          onBlur={() => {
            console.log('blur')
          }}
          onTab={() => {
            console.log('tab')
            return true
          }}
          localization={{
            locale: 'zh',
            translations: { 'generic.add': '确认' },
          }}
        />

        <style>{`
        .wysiwyg-toolbar {
            border: none;
            border-bottom: solid 1px #eee;
            font-size: 12px;
        }
        .rdw-option-wrapper {
            padding: 2px;
            height: 20px;
            margin: 0 2px;
        }
        .rdw-option-wrapper img {
            height:12px;
        }
        .wysiwyg-wrapper {
            border: 1px solid #eee;
        }
        .wysiwyg-editor {
            padding: 0 10px;
        }
        `}</style>
      </div>
    )
  }
}

export default Wysiwyg

import { post } from './tools'
import * as config from './config'

export const compList = async (params: any) => {
  return await post({
    url: config.COMP_LIST,
    data: params,
  })
}
export const compOne = async (id: any) => {
  return await post({
    url: config.COMP_ONE,
    data: { compId: id },
  })
}
export const compSave = async (params: any) => {
  return await post({
    url: config.COMP_SAVE,
    data: params,
  })
}
export const compDel = async (ids: any[]) => {
  return await post({
    url: config.COMP_DEL,
    data: { compIds: ids.join(',') },
  })
}

export const organList = async (params: any) => {
  return await post({
    url: config.ORGAN_LIST,
    data: params,
  })
}
export const organOne = async (id: any) => {
  return await post({
    url: config.ORGAN_ONE,
    data: { organId: id },
  })
}
export const organSave = async (params: any) => {
  return await post({
    url: config.ORGAN_SAVE,
    data: params,
  })
}
export const organDel = async (ids: any[]) => {
  return await post({
    url: config.ORGAN_DEL,
    data: { organIds: ids.join(',') },
  })
}

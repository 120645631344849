import * as React from 'react'
import { List, Form, Button, Checkbox, Modal, Tag, Popconfirm, message } from 'antd'
import { Link } from 'react-router-dom'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import Group from '../group/group'
import TestPaperAdd from './TestPaperAdd'
import { GroupType } from './../../utils/index'
import GroupSel from './../widget/GroupSel'
import { moveToGroup, setTop } from './../../service/index'
import { testList, testDel, testPubish } from './../../service/test'
import TestPaperInfo from './TestPaperInfo'
import TestPaperSetting from './TestPaperSetting'
import TestPaperPreview from './TestPaperPreview'
import { IMAGE_SERVER } from '../../service/config'
import { PERM, hasPermits } from './../../utils/permits'

type ITestPaperProps = {
  history: any
}
interface ITestPaperStates {
  dataSource?: any
  editDialogVisible: boolean
  checkedList: any[]
  indeterminate: boolean
  checkAll: boolean
  pageSize: number
  page: number
  groupId: any
  total: number
  testPreviewDialogVisible: boolean
  testPreviewId: any
  loading: boolean
}

export default class TestPaper extends React.Component<ITestPaperProps, ITestPaperStates> {
  constructor(props: any) {
    super(props)
    this.onPageChange = this.onPageChange.bind(this)
    this.getTestList = this.getTestList.bind(this)
    this.showEditDialog = this.showEditDialog.bind(this)
    this.handleOk = this.handleOk.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this)
    this.onTestPaperCheck = this.onTestPaperCheck.bind(this)
    this.onTestPaperCheckAll = this.onTestPaperCheckAll.bind(this)
    this.onGroupSelect = this.onGroupSelect.bind(this)
    this.onChangeGroup = this.onChangeGroup.bind(this)
    this.refresh = this.refresh.bind(this)
    this.state = {
      editDialogVisible: false,
      dataSource: [],
      checkedList: [],
      indeterminate: true,
      checkAll: false,
      pageSize: 10,
      page: 1,
      groupId: '',
      total: 0,
      loading: false,
      testPreviewDialogVisible: false,
      testPreviewId: '',
    }
  }
  componentDidMount() {
    this.getTestList()
  }

  getTestList = async () => {
    this.setState({
      loading: true,
    })
    const params = {
      page: this.state.page,
      rows: this.state.pageSize,
      groupId: this.state.groupId,
      paperType: 1,
    }
    const res = await testList(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getTestList()
    }, 0)
  }

  showEditDialog = () => {
    this.setState({
      editDialogVisible: true,
    })
  }

  handleOk = (e: any) => {
    console.log(e)
    this.setState({
      editDialogVisible: false,
    })
  }

  handleCancel = (e: any) => {
    console.log(e)
    this.setState({
      editDialogVisible: false,
    })
  }

  onEdit = (e: any) => {}
  onSetTop = async (id: any, isTop: any) => {
    this.setState({
      loading: true,
    })
    const res = await setTop({
      recordType: 5,
      serialIds: id,
      isTop: isTop,
    })
    if (res.success) {
      message.success((isTop == 1 ? '置顶' : '取消置顶') + '成功')
      this.setState({ page: 1 })
      this.getTestList()
    } else {
      message.error((isTop == 1 ? '置顶' : '取消置顶') + '失败')
    }
    this.setState({
      loading: false,
    })
  }

  onPublishPaper = async (id: any, distri: any) => {
    this.setState({
      loading: true,
    })
    const res = await testPubish(id)
    if (res.success) {
      message.success((distri == 1 ? '发布' : '取消发布') + '成功')
      this.setState({ page: 1 })
      this.getTestList()
    } else {
      message.error((distri == 1 ? '发布' : '取消发布') + '失败')
    }
    this.setState({
      loading: false,
    })
  }

  onDeleteConfirm = async (e: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请先选择试卷')
      return
    }
    const res = await testDel(this.state.checkedList)
    if (res.success) {
      message.success('删除成功')
      this.getTestList()
    } else {
      message.error('删除失败')
    }
  }
  onDeleteCancel = (e: any) => {}
  onTestPaperCheck = (e: any): void => {
    const value = e.target.value
    const checked = e.target.checked
    const TestPaper = this.state.dataSource.find((item: any) => item.paperId == value)
    TestPaper.checked = checked
    if (checked) {
      if (this.state.checkedList.indexOf(value) == -1) {
        this.state.checkedList.push(value)
      }
    } else {
      const index = this.state.checkedList.findIndex((item) => item == value)
      this.state.checkedList.splice(index, 1)
    }
    setTimeout(() => {
      this.setState({
        checkedList: this.state.checkedList,
        indeterminate:
          !!this.state.checkedList.length &&
          this.state.checkedList.length < this.state.dataSource.length,
        checkAll: this.state.checkedList.length === this.state.dataSource.length,
      })
    }, 0)
  }
  onTestPaperCheckAll = (e: any) => {
    const ids = this.state.dataSource.map((item: any) => {
      return item.paperId
    })
    this.setState({
      checkedList: e.target.checked ? ids : [],
      indeterminate: false,
      checkAll: e.target.checked,
    })
  }

  onGroupSelect = (groups: any) => {
    const group = groups[0]
    setTimeout(() => {
      if (group) {
        this.setState({
          groupId: group.groupId,
          page: 1,
        })
      } else {
        this.setState({
          groupId: '',
          page: 1,
        })
      }
      this.getTestList()
    }, 0)
  }
  onChangeGroup = async (group: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请选择试卷')
      return
    }
    const params = { groupId: group.groupId, serialIds: this.state.checkedList.join(',') }
    const res = await moveToGroup(params)
    if (res.success) {
      message.success('分组移动成功')
      setTimeout(() => {
        this.setState({ page: 1 })
        this.getTestList()
      }, 0)
    } else {
      message.error('分组移动失败')
    }
  }

  refresh = () => {
    this.setState({ page: 1 })
    this.getTestList()
  }

  public render() {
    const setTopBtn = (item: any) => {
      if (item.isTop == 1) {
        return (
          <Button type="primary" ghost onClick={() => this.onSetTop(item.paperId, 0)}>
            取消置顶
          </Button>
        )
      } else {
        return (
          <Button type="primary" ghost onClick={() => this.onSetTop(item.paperId, 1)}>
            置顶
          </Button>
        )
      }
    }

    const paperPrevBtn = (item: any) => {
      return (
        <Button
          type="primary"
          ghost
          onClick={() => {
            setTimeout(() => {
              this.setState({
                testPreviewDialogVisible: true,
                testPreviewId: item.paperId,
              })
            }, 0)
          }}
        >
          预览
        </Button>
      )
    }

    const actions = (item: any) => {
      const actionArray = []
      if (hasPermits(PERM.paper_func_mod)) {
        actionArray.push(setTopBtn(item))
      }
      if (hasPermits(PERM.paper_func_preview)) {
        actionArray.push(paperPrevBtn(item))
      }
      if (hasPermits(PERM.paper_func_mod)) {
        actionArray.push(
          <TestPaperInfo
            modal={true}
            buttonDisabled={item.paperStatus != 1}
            paperId={item.paperId}
            handlePaperInfoFinish={() => {
              this.refresh()
            }}
          />
        )
      }
      if (hasPermits(PERM.paper_func_modques)) {
        actionArray.push(
          <div>
            {item.paperStatus != 1 && (
              <Button type="primary" disabled>
                管理试题
              </Button>
            )}
            {item.paperStatus == 1 && (
              <Link to={'/app/testpaper/quesedit?paperId=' + item.paperId}>
                <Button type="primary">管理试题</Button>
              </Link>
            )}
          </div>
        )
      }
      if (hasPermits(PERM.paper_func_setting)) {
        actionArray.push(
          <TestPaperSetting
            modal={true}
            paperId={item.paperId}
            buttonDisabled={item.paperStatus != 1}
            handleSaveSettingFinished={this.refresh}
          />
        )
      }
      if (hasPermits(PERM.paper_func_distri) && item.paperStatus == 1) {
        actionArray.push(
          <Button type="primary" onClick={() => this.onPublishPaper(item.paperId, 1)}>
            发布
          </Button>
        )
      }
      if (
        hasPermits(PERM.paper_func_canceldistri) &&
        item.paperStatus != 3 &&
        item.paperStatus != 1
      ) {
        actionArray.push(
          <Button type="danger" ghost onClick={() => this.onPublishPaper(item.paperId, 0)}>
            取消发布
          </Button>
        )
      }
      return actionArray
    }

    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="试卷管理" second="试卷列表" />
        <Main
          leftTitle="试卷分类列表"
          leftContent={<Group type={GroupType.TEST} onSelect={this.onGroupSelect} disableEdit={true}/>}
          centerContent={
            <div>
              <Form layout="inline" style={{ marginBottom: 16 }}>
                {hasPermits(PERM.paper_func_add) && (
                  <Form.Item>
                    <Link to={'/app/testpaper/add'}>
                      {hasPermits(PERM.paper_func_add) && (
                        <Button type="primary" size="large">
                          新增试卷
                        </Button>
                      )}
                    </Link>
                    ,
                  </Form.Item>
                )}
                {hasPermits(PERM.paper_func_groupmod) && (
                  <Form.Item>
                    <GroupSel
                      title="移动到分类"
                      buttonTitle="移动到分类"
                      type={GroupType.TEST}
                      hideInput={true}
                      onChange={this.onChangeGroup}
                      size="large"
                    />
                  </Form.Item>
                )}
                {hasPermits(PERM.paper_func_del) && (
                  <Form.Item>
                    <Popconfirm
                      title="确定要删除试题吗"
                      onConfirm={this.onDeleteConfirm}
                      onCancel={this.onDeleteCancel}
                      okText="删除"
                      cancelText="取消"
                    >
                      <Button icon="delete" size="large" type="danger" ghost>
                        删除
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              </Form>
              <List
                itemLayout="vertical"
                size="large"
                loading={this.state.loading}
                header={
                  <div>
                    <Checkbox
                      className="mr-s"
                      indeterminate={this.state.indeterminate}
                      onChange={this.onTestPaperCheckAll}
                      checked={this.state.checkAll}
                    >
                      全选
                    </Checkbox>
                  </div>
                }
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  total: this.state.total,
                  size: 'small',
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                dataSource={this.state.dataSource}
                footer={<div></div>}
                renderItem={(item: any) => (
                  <Checkbox.Group style={{ width: '100%' }} value={this.state.checkedList}>
                    <List.Item
                      key={item.title}
                      actions={actions(item)}
                      extra={
                        <div className="flex-h">
                          <div className="flex-v y-center ">
                            <div className="text">题目数量</div>
                            <div className="text-2x text-blue">{item.subjectNum}</div>
                          </div>
                          <div className="ml-m flex-v y-center">
                            <div className="text">已考试人数</div>
                            <div className="text-2x text-blue">{item.examNum}</div>
                          </div>
                        </div>
                      }
                    >
                      <List.Item.Meta
                        avatar={
                          <div>
                            <Checkbox
                              className="mr-s"
                              value={item.paperId}
                              disabled={item.paperStatus != 1}
                              onChange={this.onTestPaperCheck}
                              checked={item.checked}
                            ></Checkbox>
                            {item.coverDirectURL && (
                              <img
                                width={272}
                                height={200}
                                alt="logo"
                                src={IMAGE_SERVER + item.coverDirectURL}
                              />
                            )}
                          </div>
                        }
                        title={
                          <a href={item.href}>
                            {item.title}
                            <span className="ml-m">
                              {item.paperStatus == 1 && <Tag color="gray">草稿</Tag>}
                              {item.paperStatus == 2 && <Tag color="orange">未开始</Tag>}
                              {item.paperStatus == 3 && <Tag color="red">考试中</Tag>}
                              {item.paperStatus == 4 && <Tag color="green">已考完</Tag>}
                            </span>
                          </a>
                        }
                        description={
                          <div>
                            <div>{item.groupNames}</div>
                            <div>
                              考试时长约：{item.examDura ? parseInt(item.examDura) / 60 : 0}分钟
                            </div>
                            <div>{item.abstract}</div>
                            {item.reqCourseJson && item.reqCourseJson.length > 0 && (
                              <div>
                                必修课：
                                {Array.from(item.reqCourseJson.map((c: any) => c.title)).join('，')}
                              </div>
                            )}
                            {item.optCourseJson && item.optCourseJson.length > 0 && (
                              <div>
                                选修课：
                                {Array.from(item.optCourseJson.map((c: any) => c.title)).join('，')}
                              </div>
                            )}

                            <div>
                              所需学习积分：
                              {item.papSetJson ? item.papSetJson.courLineScore || 0 : 0}
                            </div>
                            {item.papSetJson && item.papSetJson.isValid == '1' && (
                              <div>永久有效</div>
                            )}
                            {item.papSetJson && item.papSetJson.isValid == '2' && (
                              <div>有效期至：{item.papSetJson.invalidEndDate}</div>
                            )}
                          </div>
                        }
                      />
                      {item.content}
                    </List.Item>
                  </Checkbox.Group>
                )}
              />
              <Modal
                width="60%"
                title="新增试卷"
                visible={this.state.editDialogVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
              >
                <TestPaperAdd />
              </Modal>
              <Modal
                width="80%"
                title="试卷预览"
                visible={this.state.testPreviewDialogVisible}
                onOk={() => {
                  this.setState({
                    testPreviewDialogVisible: false,
                  })
                }}
                onCancel={() => {
                  this.setState({
                    testPreviewDialogVisible: false,
                  })
                }}
              >
                <TestPaperPreview paperId={this.state.testPreviewId} />
              </Modal>
            </div>
          }
        />
      </div>
    )
  }
}

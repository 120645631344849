import * as React from 'react'
import BreadcrumbCustom from '../BreadcrumbCustom'
import { Tree, Input, Card, Button, Popconfirm } from 'antd'
import { permTree, permSave, permDel } from './../../service/perm'
import EditableTagGroup from '../widget/EditTagGroup'
import { PERM, hasPermits } from './../../utils/permits'
const ButtonGroup = Button.Group
export interface IPermissionProps {}
export interface IPermissionStates {
  expandedKeys: any
  treeData: any
  searchValue: any
  autoExpandParent: boolean
  dataList: any
  newPermName: any
}

const { TreeNode } = Tree
const { Search } = Input

const dataList: any[] = []

const getParentKey = (id: any, tree: any): any => {
  let parentKey
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some((item: any) => item.id === id)) {
        parentKey = node.id
      } else if (getParentKey(id, node.children)) {
        parentKey = getParentKey(id, node.children)
      }
    }
  }
  return parentKey
}

export default class Permission extends React.Component<IPermissionProps, IPermissionStates> {
  constructor(props: any) {
    super(props)
    this.onNodeAdd = this.onNodeAdd.bind(this)
    this.onNodeEdit = this.onNodeEdit.bind(this)
    this.onNodeDelete = this.onNodeDelete.bind(this)
    this.getPermTreeList = this.getPermTreeList.bind(this)
    this.onPermEdit = this.onPermEdit.bind(this)
  }

  componentDidMount() {
    this.getPermTreeList()
  }
  state = {
    expandedKeys: [],
    treeData: [],
    searchValue: '',
    autoExpandParent: false,
    dataList: [],
    newPermName: '',
  }

  onExpand = (expandedKeys: any) => {
    this.setState({
      expandedKeys,
    })
  }

  onChange = (e: any) => {
    const { value } = e.target
    const expandedKeys = dataList
      .map((item) => {
        if (item.text.indexOf(value) > -1) {
          return getParentKey(item.id, this.state.treeData)
        }
        return null
      })
      .filter((item, i, self) => item && self.indexOf(item) === i)
    this.setState({
      expandedKeys,
      searchValue: value,
    })
  }

  getPermTreeList = async () => {
    const res = await permTree('')
    let data: any = []
    if (res.data.length == 0) {
      data.push({
        text: '新增根节点',
        id: '_all',
        edit: true,
      })
    } else {
      data = res.data.map((item: any) => {
        return {
          code: item.code,
          funcJson: item.funcJson,
          id: item.id.toString(),
          parentId: item.parentId.toString(),
          parentPermName: item.parentPermName,
          permType: item.permType,
          text: item.text,
          children: item.children,
        }
      })
    }

    const parent = data[0]
    this.setState({
      treeData: data,
      autoExpandParent: true,
      expandedKeys: [parent.id],
    })
  }

  onNodeAdd = (node: any) => {
    node.children = node.children || []
    node.children.push({
      text: '',
      id: '',
      edit: true,
      parentId: node.id,
    })

    const expandedKeys = [...this.state.expandedKeys, node.id]
    this.setState({
      dataList: dataList,
      expandedKeys: expandedKeys,
    })
  }
  onNodeEdit = (node: any) => {
    node.edit = true
    this.setState({ treeData: this.state.treeData })
  }
  onNodeDelete = async (node: any) => {
    if (!node) {
      return
    }
    if (node.id) {
      const res = await permDel([node.id])
      if (res.success) {
        this.getPermTreeList()
      }
    } else {
      node.edit = false
      this.setState({
        treeData: this.state.treeData,
      })
    }
  }

  onPermEdit = async (value: any, item: any, type: any) => {
    const params = {
      id: item.id == '_all' ? '' : item.id,
      permType: type,
      parentId: item.parentId,
      level: item.level || 0,
      text: value,
    }
    const res = await permSave(params)
    if (res.success) {
      item.edit = false
      this.setState({
        treeData: this.state.treeData,
      })
      this.getPermTreeList()
    }
  }

  onPermCodeEdit = async (id: any, code: string) => {
    const params = {
      id: id == '_all' ? '' : id,
      showCode: code,
    }
    const res = await permSave(params)
    if (res.success) {
      this.setState({
        treeData: this.state.treeData,
      })
      this.getPermTreeList()
    }
  }

  onTagAdded = (value: string, parent: any) => {
    const node = {
      ...parent,
      parentId: parent.id,
      id: '',
    }
    this.onPermEdit(value, node, 2)
  }

  onTagRemoved = (removedTag: any, parent: any) => {
    const func = parent.funcJson.find((item: any) => item.id == removedTag.id)
    this.onNodeDelete(func)
  }

  onTagCodeModed = (code: any, tag: any) => {
    this.onPermCodeEdit(tag.id, code)
  }

  titleRender = (item: any) => {
    const { searchValue } = this.state
    const index = item.text ? item.text.indexOf(searchValue) : ''
    const beforeStr = item.text ? item.text.substr(0, index) : ''
    const afterStr = item.text ? item.text.substr(index + searchValue.length) : ''
    const title = (
      <span>
        {!item.edit && (
          <span className="flex-vcenter">
            {index > -1 || (searchValue && searchValue.length > 0) ? (
              <span>
                {beforeStr}
                <span style={{ color: '#f50' }}>{searchValue}</span>
                {afterStr}
              </span>
            ) : (
              <span>{item.text}</span>
            )}
            <ButtonGroup className="ml-m">
              {hasPermits(PERM.setting_perm_func_add) && (
                <Button
                  type="primary"
                  size="small"
                  icon="plus"
                  onClick={() => this.onNodeAdd(item)}
                ></Button>
              )}
              {hasPermits(PERM.setting_perm_func_mod) && (
                <Button
                  type="primary"
                  size="small"
                  icon="form"
                  onClick={() => this.onNodeEdit(item)}
                ></Button>
              )}
              {hasPermits(PERM.setting_perm_func_del) && (
                <Button
                  type="primary"
                  size="small"
                  icon="delete"
                  onClick={() => this.onNodeDelete(item)}
                ></Button>
              )}
              {hasPermits(PERM.setting_perm_func_modcode) && (
                <Popconfirm
                  title={
                    <Input
                      placeholder="填写code"
                      allowClear
                      defaultValue={item.code}
                      onChange={(e) => {
                        item.code = e.currentTarget.value
                      }}
                    />
                  }
                  onConfirm={() => {
                    this.onPermCodeEdit(item.id, item.code)
                  }}
                  onCancel={() => {}}
                  okText="确定"
                  cancelText="取消"
                >
                  <Button type="primary" size="small" icon="code"></Button>
                </Popconfirm>
              )}
            </ButtonGroup>
            <EditableTagGroup
              tags={item.funcJson && item.funcJson.map((func: any) => func)}
              parent={item}
              editable={true}
              hasPermit={hasPermits(PERM.setting_perm_func_delfunc)}
              handleTagAdded={this.onTagAdded}
              handleTagRemoved={this.onTagRemoved}
              handleTagCodeModed={this.onTagCodeModed}
            />
          </span>
        )}
        {item.edit && (
          <span>
            <Input
              placeholder="编辑权限"
              value={item.text}
              size="small"
              onChange={(e) => {
                item.text = e.currentTarget.value
                this.setState({
                  treeData: this.state.treeData,
                })
              }}
              onBlur={(e) => this.onPermEdit(e.currentTarget.value, item, 1)}
              onPressEnter={(e) => this.onPermEdit(e.currentTarget.value, item, 1)}
            />
            <Button
              type="primary"
              size="small"
              icon="delete"
              onClick={() => this.onNodeDelete(item)}
            ></Button>
          </span>
        )}
      </span>
    )
    return title
  }
  render() {
    const { expandedKeys, autoExpandParent } = this.state
    const loop = (data: any) =>
      data.map((item: any) => {
        if (item.children) {
          return (
            <TreeNode key={item.id} title={this.titleRender(item)}>
              {loop(item.children)}
            </TreeNode>
          )
        }
        return <TreeNode key={item.id} title={this.titleRender(item)} />
      })

    return (
      <div>
        <BreadcrumbCustom first="系统设置" second="权限管理" />
        <Card style={{ width: '50%' }}>
          <Search style={{ marginBottom: 8 }} placeholder="搜索" onChange={this.onChange} />
          <Tree
            onExpand={this.onExpand}
            autoExpandParent={autoExpandParent}
            expandedKeys={expandedKeys}
            defaultExpandParent={autoExpandParent}
          >
            {loop(this.state.treeData)}
          </Tree>
        </Card>
      </div>
    )
  }
}

import React from 'react'
import { Button, Tabs } from 'antd'
import { Link } from 'react-router-dom'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import PaperStuTesting from './PaperStuTesting'
import PaperStuTested from './PaperStuTested'
import PaperStuUntest from './PaperStuUntest'
const { TabPane } = Tabs

export interface IPaperStatDetailProps {}
interface IPaperStatDetailStates {
  editPaperStatDetailId: any
  paperId: any
  paperTitle: string
}

export default class PaperStatDetail extends React.Component<
  IPaperStatDetailProps,
  IPaperStatDetailStates
> {
  constructor(props: any) {
    super(props)
    this.showTotal = this.showTotal.bind(this)
    this.state = {
      editPaperStatDetailId: '',
      paperId: props.query.paperId,
      paperTitle: props.query.title || '',
    }
  }
  componentDidMount() {}

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  public render() {
    return (
      <div className="w-full h-full">
        <BreadcrumbCustom
          first={<Link to={'/app/stat/paper'}>统计管理</Link>}
          second={<Link to={'/app/stat/paper'}>试卷统计</Link>}
          third={this.state.paperTitle}
        />
        <Main
          contentTitle={this.state.paperTitle}
          centerContent={
            <div>
              <Tabs>
                <TabPane tab="正在考试名单" key="1">
                  <PaperStuTesting paperId={this.state.paperId} />
                </TabPane>
                <TabPane tab="已考名单" key="2">
                  <PaperStuTested paperId={this.state.paperId} />
                </TabPane>
                <TabPane tab="未考名单" key="3">
                  <PaperStuUntest paperId={this.state.paperId} />
                </TabPane>
              </Tabs>
            </div>
          }
        />
      </div>
    )
  }
}
